import { observer } from 'mobx-react';
import React, { useEffect, useState, useRef } from 'react';
import { formatter as CurrencyFormatter } from 'utils/CurrencyFormatter';
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Card from '../common/Card';
import { Alert } from '@material-ui/lab';
import { useParams, useHistory } from 'react-router-dom';
import { getLinkByParams } from '../../utils/utils';
import RouteConsts from '../../constants/RoutesConst';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import CloseIcon from '@material-ui/icons/Close';
import { useStore } from 'store/storeUtils';
import CookiesUtils from 'utils/cookieUtils';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Client from 'client/Client';
import { buildQuote } from '../../services/quoteService';
import { isNessunBox, isUserLogged } from 'utils/utils';
import CalculateUrl from 'utils/CalculateUrl';
import Loader from 'components/common/Loader';
import { getPromoPrice } from 'utils/utilsPayments';
import ImageDialog from './ImageDialog';
import CancelIcon from '@material-ui/icons/Cancel';
import ScrollArea from 'react-scrollbar';
import imageModal from 'images/assets/immagine-modal.jpeg';
import imageModal2 from 'images/assets/immagine-modal.jpeg';
const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    paddingBlock: 10,
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export const SummaryDetails = observer(props => {
  const { selectedBox, selectedAppartment, showPageLoader, typology } = props;
  const classes = useStyles();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [openPopup, setOpenPopup] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [openVideocall, setOpenVideocall] = useState(false);
  const [openShowroom, setOpenShowroom] = useState(false);
  const [disabledConfermaBtn, setDisabledConfermaBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialog, setDialog] = useState({
    title: '',
    message: '',
    buttonLabel: '',
    onButtonClick: () => {},
  });
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const [saveQuote] = useMutation(Client.CREATE_QUOTE_MUTATION);
  const [setClientInterest] = useMutation(Client.SET_CLIENT_INTEREST);
  const vendorId =
    (store.getRole() === 'vendor' && store.loggedUser && store.loggedUser.id) ||
    process.env.REACT_APP_VENDOR_ID;
  const [queryClientCall, queryClient] = useLazyQuery(Client.GET_CLIENT);

  const [appartmentCall, appartments] = useLazyQuery(
    Client.GET_ALL_APPARTMENTS,
    {
      ...Client.GET_ALL_APPARTMENTS_DEFAULT_OPTIONS(store.projectId),
      fetchPolicy: 'network-only',
    }
  );
  const scrollAreaRef = useRef();

  const [pageHeight, setPageHeight] = useState(0);

  const updatePageHeight = () => {
    const newPageHeight = document.body.getBoundingClientRect().height;
    if (newPageHeight !== pageHeight) {
      setPageHeight(newPageHeight);
    }
  };

  useEffect(() => {
    setPageHeight(document.body.getBoundingClientRect().height);
    window.addEventListener('resize', updatePageHeight);
    return () => {
      window.removeEventListener('resize', updatePageHeight);
    };
  }, []);

  useEffect(() => {
    if (scrollAreaRef?.current?.scrollArea?.scrollTop)
      scrollAreaRef.current.scrollArea.scrollTop();
  }, [pageHeight]);

  const [featuresCall, features] = useLazyQuery(Client.GET_FEATURES, {
    ...Client.GET_FEATURES_OPTIONS(store.projectId),
    fetchPolicy: 'network-only',
  });

  const [loading, setLoading] = React.useState(false);

  let totalPrice = getPromoPrice(
    selectedAppartment.price,
    selectedAppartment.standardQuote.promos
  );

  const openSnackbar = (type, msg) => {
    setSnackbarMsg(msg);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  const quote =
    selectedAppartment &&
    selectedBox &&
    buildQuote(
      store.getRole() === 'client'
        ? store.loggedUser.client
        : store.getRole() === 'vendor'
        ? store.vendorClient
        : undefined,
      vendorId,
      selectedAppartment,
      selectedBox
    );

  const handleSuccessSnackbarClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    closeSnackbar();
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMsg('');
  };

  const returnToStep = n => {
    history.push(
      getLinkByParams(params, RouteConsts.map(r => r.navigation)[n])
    );
  };

  const getCollocazioneImgURL = side =>
    `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/collocazione/collocazione-${side}.jpg`;

  const clientId =
    store.getRole() === 'client' ? store.loggedUser.client : store.vendorClient;
  const handleCall = async () => {
    queryClientCall(
      Client.GET_CLIENT_DEFAULT_OPTIONS(store.projectId, clientId)
    );
    appartmentCall();
    featuresCall();
    setLoading(true);
  };

  const handleConferma = async () => {
    if (store.getRole() === 'vendor') {
      store.setSigninVendor(true);
    } else {
      handleCall();
    }
  };

  const handleClickOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
    setOpenBox(false);
    setOpenPlan(false);
  };

  const handleClickOpenPlan = () => {
    setOpenPlan(true);
  };

  const handleClickOpenBox = () => {
    setOpenBox(true);
  };

  const handleClickOpenMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  const handleBackTypology = () => {
    history.push(
      getLinkByParams(params, RouteConsts.map(r => r.navigation)[0])
    );
  };

  const handleBackPlan = () => {
    history.push(
      getLinkByParams(params, RouteConsts.map(r => r.navigation)[1])
    );
  };

  const handleBackBox = () => {
    history.push(
      getLinkByParams(params, RouteConsts.map(r => r.navigation)[2])
    );
  };

  showPageLoader(appartments.loading || features.loading);

  useEffect(() => {
    if (store.autoConfirm) {
      handleCall();
    }
  }, [store.autoConfirm]);

  if (
    loading &&
    appartments &&
    !appartments.loading &&
    appartments.called &&
    appartments.data &&
    appartments.data.getAllAppartmentsPublic &&
    features &&
    !features.loading &&
    features.called &&
    features.data &&
    features.data.getFeaturesPublic &&
    queryClient &&
    !queryClient.loading &&
    queryClient.called
  ) {
    (async () => {
      store.setAutoConfirm(false);
      store.setAllAppartments([...appartments.data.getAllAppartmentsPublic]);
      store.setAllFeatures([...features.data.getFeaturesPublic]);
      setLoading(false);
      if (
        !store.allAppartments.filter(app => app.id === selectedAppartment.id)[0]
          .available
      ) {
        setDialog({
          title: 'Si è verificato un errore',
          message: 'Il piano selezionato non è più disponibile',
          buttonLabel: 'Scegli un nuovo piano',
          onButtonClick: () => returnToStep(1),
        });
        setDialogOpen(true);
        setDisabledConfermaBtn(true);
        return;
      }

      if (
        !isNessunBox(selectedBox) &&
        !store.allParkingSpaceCars.filter(ps => ps.id === selectedBox.id)[0]
          .available
      ) {
        setDialog({
          title: 'SI è verificato un errore',
          message: 'Il posto auto selezionato non è più disponibile',
          buttonLabel: 'Scegli un nuovo posto auto',
          onButtonClick: () => returnToStep(2),
        });
        setDialogOpen(true);
        setDisabledConfermaBtn(true);
        return;
      }

      if (!isUserLogged()) {
        store.setSummaryLocation(history.location.pathname);
        history.push('/login');
      } else {
        try {
          const saveQuoteResult = await saveQuote(
            Client.CREATE_QUOTE_OPTIONS(store.projectId, quote)
          );

          if (
            !saveQuoteResult ||
            !saveQuoteResult.data ||
            !saveQuoteResult.data.createQuotes
          ) {
            openSnackbar(
              'error',
              'Errore durante il salvataggio del preventivo'
            );
            return;
          } else if (saveQuoteResult.data.createQuotes.errorMessage) {
            openSnackbar(
              'error',
              saveQuoteResult.data.createQuotes.errorMessage
            );
            return;
          }
          if (queryClient && queryClient.data && queryClient.data.getClient) {
            const isInterested =
              queryClient.data.getClient &&
              queryClient.data.getClient.interested_appartments &&
              typeof queryClient.data.getClient.interested_appartments.find(
                a => a.appartment === selectedAppartment.id
              ) !== 'undefined';
            const isStatusSet =
              queryClient.data.getClient &&
              queryClient.data.getClient.selected_appartments &&
              queryClient.data.getClient.selected_appartments.filter(
                a => a.appartment === selectedAppartment.id
              ).length;

            if (!isStatusSet && !isInterested) {
              try {
                await setClientInterest(
                  Client.SET_CLIENT_INTEREST_OPTIONS(
                    store.projectId,
                    clientId,
                    selectedAppartment.id,
                    vendorId
                  )
                );
              } catch (e) {
                console.log("Errore durante l'aggiunta interesse", e);
              }
            }
          }
          openSnackbar('success', 'il preventivo è stato salvato con successo');
          setDisabledConfermaBtn(true);
          if (store.getRole() === 'vendor') {
            CookiesUtils.removeClientId();
          } else {
            await new Promise(resolve => setTimeout(resolve, 2000));
            window.location.href =
              window.location.protocol +
              '//' +
              CalculateUrl('salesonline', 'myhome');
          }
        } catch (e) {
          openSnackbar(
            'error',
            'si è verificato un errore durante il salvataggio del preventivo'
          );
          console.log('Errore durante il salvataggio del preventivo', e);
        }
      }
    })();
  }

  if (!quote) {
    return <Loader z loading />;
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!!dialog.title && (
          <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialog.onButtonClick} color="primary" autofocus>
            {dialog.buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12} lg={5} className="summary-details-grid-item">
        <Card className="summary-container-details">
          <Typography className="summary-title" variant="h4">
            <b>Riepilogo</b>
          </Typography>
          {/* CARD TIPOLOGIA */}
          <div className="summary-box-container">
            <img src="/icon/icon-tipologia-grigio.svg" />
            <div className="summary-box-title">
              <div className="summary-box-title-1">Tipologia</div>
              <b>
                {typology.typology.toUpperCase()}{' '}
                {selectedAppartment._label.toUpperCase()}
              </b>{' '}
              con cantina inclusa
            </div>
            <IconButton
              className="summary-icon-button"
              onClick={handleClickOpen}
            >
              <img src="/icon/icon-modifica.svg" />
            </IconButton>
            <Dialog
              fullWidth={true}
              maxWidth="xs"
              open={openPopup}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div>
                <img
                  className="icon-popup"
                  src="/icon/Ellisse 114.svg"
                  alt=""
                />
                <img
                  className="icon-popup"
                  src="/icon/Icon feather-bell.svg"
                  alt=""
                />
              </div>
              <DialogTitle id="alert-dialog-title">
                <Typography
                  variant="caption"
                  style={{ fontWeight: 600, color: '#481635' }}
                >
                  Attenzione!
                </Typography>
                <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                  Hai scelto di modificare le tue scelte
                </Typography>
              </DialogTitle>
              <Divider variant="middle" />
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="subtitle2">
                    Se confermi di modificare le tue scelte, tutte quelle
                    compiute dopo questa fase verranno annullate.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="dialog-content-question"
                  >
                    Vuoi annullare le tue scelte successive?
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ flexDirection: 'column' }}>
                <Button
                  className="button-popup-no"
                  variant="outlined"
                  onClick={handleClose}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#FFFFFF', fontSize: 12 }}
                  >
                    <b>No</b>, mantengo le mie scelte
                  </Typography>
                </Button>
                <Button
                  className="button-popup"
                  variant="outlined"
                  style={{ marginBottom: '10px', textTransform: 'none' }}
                  onClick={handleBackTypology}
                  autoFocus
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#F5942E', fontSize: 10 }}
                  >
                    <b>Sì</b>, voglio cambiare le mie scelte
                  </Typography>
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/* CARD DETTAGLI */}
          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              className="summary-details"
              style={{ border: '1px solid #9F9E9D', paddingTop: 25 }}
              avatar={
                !matchesPhone ? (
                  <div style={{ display: 'flex' }}>
                    <img
                      style={{ marginRight: 16 }}
                      src="/icon/icon-planimetria-grigio.svg"
                    />
                    <div
                      style={{
                        paddingTop: '5px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 600, color: '#1C1C1C' }}
                      >
                        Planimetria
                      </Typography>
                      <Typography
                        style={{ color: '#1C1C1C' }}
                        variant="caption"
                      >
                        <b>DETTAGLI</b>
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex' }}>
                    <img
                      style={{ marginRight: 16 }}
                      className="avatar-card-mobile"
                      src="/icon/icon-planimetria-grigio.svg"
                    />
                    <div
                      style={{
                        paddingTop: '5px',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        className="title-card-mobile"
                      >
                        Planimetria
                      </Typography>
                      <Typography
                        className="subtitle-card-mobile"
                        variant="body1"
                      >
                        <b>DETTAGLI</b>
                      </Typography>
                    </div>
                    <Button
                      className="icona-modifica-riepilogo"
                      onClick={handleClickOpenPlan}
                    >
                      <img
                        style={{ width: 30 }}
                        src="/icon/icon-modifica.svg"
                      />
                    </Button>
                    <Dialog
                      fullWidth={true}
                      maxWidth="xs"
                      open={openPlan}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <div>
                        <img
                          className="icon-popup"
                          src="/icon/Ellisse 114.svg"
                          alt=""
                        />
                        <img
                          className="icon-popup"
                          src="/icon/Icon feather-bell.svg"
                          alt=""
                        />
                      </div>
                      <DialogTitle id="alert-dialog-title">
                        <Typography
                          variant="caption"
                          style={{ fontWeight: 600, color: '#481635' }}
                        >
                          Attenzione!
                        </Typography>
                        <Typography
                          style={{ fontWeight: 600 }}
                          variant="subtitle2"
                        >
                          Hai scelto di modificare le tue scelte
                        </Typography>
                      </DialogTitle>
                      <Divider variant="middle" />
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <Typography variant="subtitle2">
                            Se confermi di modificare le tue scelte, tutte
                            quelle compiute dopo questa fase verranno annullate.
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className="dialog-content-question"
                          >
                            Vuoi annullare le tue scelte successive?
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ flexDirection: 'column' }}>
                        <Button
                          className="button-popup-no"
                          variant="outlined"
                          onClick={handleClose}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ color: '#FFFFFF', fontSize: 12 }}
                          >
                            <b>No</b>, mantengo le mie scelte
                          </Typography>
                        </Button>
                        <Button
                          className="button-popup"
                          variant="outlined"
                          style={{
                            marginBottom: '10px',
                            textTransform: 'none',
                          }}
                          onClick={handleBackPlan}
                          autoFocus
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ color: '#F5942E', fontSize: 10 }}
                          >
                            <b>Sì</b>, voglio cambiare le mie scelte
                          </Typography>
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )
              }
              subheader={
                !matchesPhone ? (
                  <Grid
                    container
                    style={{ paddingTop: 'unset' }}
                    className={classes.root}
                  >
                    <Divider
                      className="hidden-divider"
                      orientation="vertical"
                      flexItem
                    />
                    <Grid item>
                      <Typography
                        style={{ paddingBottom: '8px', color: '#9F9E9D' }}
                        variant="subtitle2"
                      >
                        superficie
                      </Typography>
                      <Typography
                        style={{ fontSize: 18, fontWeight: 600 }}
                        variant="caption"
                      >
                        {selectedAppartment.plan.SuperficieAppartamento.replace(
                          '.',
                          ','
                        )}{' '}
                        <thin style={{ fontSize: 'x-small' }}>
                          m<sup>2</sup>
                        </thin>
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item>
                      <Typography
                        style={{ paddingBottom: '8px', color: '#9F9E9D' }}
                        variant="subtitle2"
                      >
                        di cui loggia
                      </Typography>
                      <Typography
                        style={{ fontSize: 18, fontWeight: 600 }}
                        variant="caption"
                      >
                        {selectedAppartment.plan.SuperficieLoggia.replace(
                          '.',
                          ','
                        )}{' '}
                        <thin style={{ fontSize: 'x-small' }}>
                          m<sup>2</sup>
                        </thin>
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item style={{ textAlign: 'center' }}>
                      <Typography
                        style={{ paddingBottom: 10 }}
                        variant="body1"
                        component="h2"
                      >
                        <img src="/icon/Icon ionic-ios-water.svg" />
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#000000' }}
                      >
                        <b>
                          {selectedAppartment.plan.bathroom}{' '}
                          {selectedAppartment.plan.bathroom == 1
                            ? 'bagno'
                            : 'bagni'}
                        </b>
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item style={{ textAlign: 'center' }}>
                      <Typography
                        style={{ paddingBottom: 6 }}
                        variant="body1"
                        component="h2"
                      >
                        <img src="/icon/Icon ionic-ios-bed.svg" />
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#000000' }}
                      >
                        <b>
                          {selectedAppartment.plan.bedroom}{' '}
                          {selectedAppartment.plan.bedroom == 1
                            ? 'camera'
                            : 'camere'}
                        </b>
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item style={{ alignSelf: 'center' }}>
                      <Button
                        className="icona-modifica-riepilogo"
                        onClick={handleClickOpenPlan}
                      >
                        <img src="/icon/icon-modifica.svg" />
                      </Button>
                      <Dialog
                        fullWidth={true}
                        maxWidth="xs"
                        open={openPlan}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <div>
                          <img
                            className="icon-popup"
                            src="/icon/Ellisse 114.svg"
                            alt=""
                          />
                          <img
                            className="icon-popup"
                            src="/icon/Icon feather-bell.svg"
                            alt=""
                          />
                        </div>
                        <DialogTitle id="alert-dialog-title">
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 600, color: '#481635' }}
                          >
                            Attenzione!
                          </Typography>
                          <Typography
                            style={{ fontWeight: 600 }}
                            variant="subtitle2"
                          >
                            Hai scelto di modificare le tue scelte
                          </Typography>
                        </DialogTitle>
                        <Divider variant="middle" />
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <Typography variant="subtitle2">
                              Se confermi di modificare le tue scelte, tutte
                              quelle compiute dopo questa fase verranno
                              annullate.
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              className="dialog-content-question"
                            >
                              Vuoi annullare le tue scelte successive?
                            </Typography>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ flexDirection: 'column' }}>
                          <Button
                            className="button-popup-no"
                            variant="outlined"
                            onClick={handleClose}
                          >
                            <Typography
                              variant="subtitle1"
                              style={{ color: '#FFFFFF', fontSize: 12 }}
                            >
                              <b>No</b>, mantengo le mie scelte
                            </Typography>
                          </Button>
                          <Button
                            className="button-popup"
                            variant="outlined"
                            style={{
                              marginBottom: '10px',
                              textTransform: 'none',
                            }}
                            onClick={handleBackPlan}
                            autoFocus
                          >
                            <Typography
                              variant="subtitle1"
                              style={{ color: '#F5942E', fontSize: 10 }}
                            >
                              <b>Sì</b>, voglio cambiare le mie scelte
                            </Typography>
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.root}
                    >
                      <Grid item>
                        <Typography className="info-app" variant="body1">
                          Valore appartamento
                        </Typography>
                        <Typography variant="caption">
                          <b>
                            €{' '}
                            {CurrencyFormatter.format(selectedAppartment.price)}
                          </b>
                        </Typography>
                      </Grid>
                      <Divider
                        style={{ backgroundColor: '#9F9E9D' }}
                        orientation="vertical"
                        flexItem
                      />
                      <Grid item>
                        <Typography className="info-app" variant="body1">
                          superficie
                        </Typography>
                        <Typography variant="caption">
                          <b>
                            {selectedAppartment.plan.SuperficieAppartamento.replace(
                              '.',
                              ','
                            )}
                          </b>{' '}
                          <thin style={{ fontSize: 'x-small' }}>
                            m<sup>2</sup>
                          </thin>
                        </Typography>
                      </Grid>
                      <Divider
                        style={{ backgroundColor: '#9F9E9D' }}
                        orientation="vertical"
                        flexItem
                      />
                      <Grid item>
                        <Typography className="info-app" variant="body1">
                          di cui loggia
                        </Typography>
                        <Typography variant="caption">
                          <b>
                            {selectedAppartment.plan.SuperficieLoggia.replace(
                              '.',
                              ','
                            )}
                          </b>{' '}
                          <thin style={{ fontSize: 'x-small' }}>
                            m<sup>2</sup>
                          </thin>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ backgroundColor: '#9F9E9D' }} />
                    <Grid container style={{ marginTop: 14, marginBottom: 10 }}>
                      <Grid
                        container
                        xs={6}
                        className="bagno-mobile"
                        style={{
                          justifyContent: 'space-between',
                          padding: 10,
                          marginRight: 14,
                          maxWidth: '48%',
                        }}
                      >
                        <Grid item style={{ alignSelf: 'flex-end' }}>
                          <img src="/icon/Icon ionic-ios-water.svg" />
                        </Grid>
                        <Grid item style={{ alignSelf: 'center' }}>
                          <Typography
                            className="label-bagno"
                            variant="subtitle2"
                          >
                            <b>
                              {selectedAppartment.plan.bathroom}{' '}
                              {selectedAppartment.plan.bathroom == 1
                                ? 'bagno'
                                : 'bagni'}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={6}
                        style={{
                          borderLeft: '1px solid #9F9E9D',
                          justifyContent: 'space-between',
                          padding: 10,
                          maxWidth: '48%',
                        }}
                      >
                        <Grid item style={{ alignSelf: 'flex-end' }}>
                          <img src="/icon/Icon ionic-ios-bed.svg" />
                        </Grid>
                        <Grid item style={{ alignSelf: 'center' }}>
                          <Typography
                            className="label-bagno"
                            variant="subtitle2"
                          >
                            <b>
                              {selectedAppartment.plan.bedroom}{' '}
                              {selectedAppartment.plan.bedroom == 1
                                ? 'camera'
                                : 'camere'}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              }
            />
          </Card>

          {/* CARD AFFACCIO */}
          <div className="summary-box-container">
            <img src="/icon/icon-affaccio-grigio.svg" />
            <div className="summary-box-title">
              <div className="summary-box-title-1">Affaccio</div>
              <b>{typology.affaccio}</b>
            </div>
            <IconButton
              className="summary-icon-button"
              onClick={handleClickOpenMap}
            >
              <img src="/icon/Raggruppa 21028.svg" />
            </IconButton>
            <ImageDialog
              open={openMap}
              onClose={handleCloseMap}
              title={<b>{typology.affaccio}</b>}
            >
              {typology.affaccio === 'Via Grosotto' && (
                <img
                  className="full-size-contain"
                  src={getCollocazioneImgURL('B')}
                />
              )}
              {typology.affaccio === 'Via Traiano' && (
                <img
                  className="full-size-contain"
                  src={getCollocazioneImgURL('A')}
                />
              )}
              {typology.affaccio === 'Piazzale Accursio' && (
                <img
                  className="full-size-contain"
                  src={getCollocazioneImgURL('C')}
                />
              )}
            </ImageDialog>
          </div>

          {/* CARD BOX */}
          <div className="summary-box-container">
            {selectedBox.id !== '0' ? (
              <img src="/icon/icon-box-grigio.svg" />
            ) : (
              <img className="icon-no-box" src="/icon/icon-no-box.svg" />
            )}
            <div className="summary-box-title">
              <div className="summary-box-title-1">Posto auto</div>
              {selectedBox.id !== '0' ? (
                <>
                  <b>
                    {selectedBox._label.toUpperCase()}{' '}
                    <thin style={{ fontWeight: '400' }}>|</thin>
                    &thinsp; PIANO -1
                  </b>{' '}
                  extra costo{' '}
                  <span style={{ whiteSpace: 'nowrap' }}>
                    + € {CurrencyFormatter.format(selectedBox.price)}
                  </span>
                </>
              ) : (
                <b>NESSUNO</b>
              )}
            </div>
            <IconButton
              className="summary-icon-button"
              onClick={handleClickOpenBox}
            >
              <img src="/icon/icon-modifica.svg" />
            </IconButton>
            <Dialog
              fullWidth={true}
              maxWidth="xs"
              open={openBox}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div>
                <img
                  className="icon-popup"
                  src="/icon/Ellisse 114.svg"
                  alt=""
                />
                <img
                  className="icon-popup"
                  src="/icon/Icon feather-bell.svg"
                  alt=""
                />
              </div>
              <DialogTitle id="alert-dialog-title">
                <Typography
                  variant="caption"
                  style={{ fontWeight: 600, color: '#481635' }}
                >
                  Attenzione!
                </Typography>
                <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                  Hai scelto di modificare le tue scelte
                </Typography>
              </DialogTitle>
              <Divider variant="middle" />
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="subtitle2">
                    Se confermi di modificare le tue scelte, tutte quelle
                    compiute dopo questa fase verranno annullate.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="dialog-content-question"
                  >
                    Vuoi annullare le tue scelte successive?
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ flexDirection: 'column' }}>
                <Button
                  className="button-popup-no"
                  variant="outlined"
                  onClick={handleClose}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#FFFFFF', fontSize: 12 }}
                  >
                    <b>No</b>, mantengo le mie scelte
                  </Typography>
                </Button>
                <Button
                  className="button-popup"
                  variant="outlined"
                  style={{ marginBottom: '10px', textTransform: 'none' }}
                  onClick={handleBackBox}
                  autoFocus
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#F5942E', fontSize: 10 }}
                  >
                    <b>Sì</b>, voglio cambiare le mie scelte
                  </Typography>
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/* FOOTER CARD */}
          <Card className="card-summary-wrapper">
            <Typography
              style={
                !matchesPhone
                  ? { color: '#FFFFFF' }
                  : {
                      color: '#FFFFFF',
                      fontSize: 14,
                    }
              }
              variant="caption"
            >
              Il valore del tuo appartamento:{' '}
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  position: 'relative',
                  whiteSpace: 'nowrap',
                }}
              >
                € {CurrencyFormatter.format(selectedAppartment.price)}
                <div className="barra-prezzo-summary"></div>
              </span>
            </Typography>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 3,
                backgroundColor: '#FFFFFF',
              }}
            />
            <div style={{ position: 'relative' }}>
              <img
                className="icon-promo-summary"
                src="/icon/Special-price-label.svg"
              />
            </div>
            <Typography
              style={
                !matchesPhone
                  ? {
                      letterSpacing: 0.32,
                      textTransform: 'uppercase',
                      opacity: 0.7,
                      marginRight: 100,
                    }
                  : {
                      fontSize: 9,
                      textTransform: 'uppercase',
                      opacity: 0.7,
                      marginRight: 100,
                    }
              }
              variant="h2"
            >
              <b>Con lo Special Price del -8%</b>
            </Typography>
            <Typography
              style={{ color: 'white' }}
              variant={matchesPhone ? 'h4' : 'h3'}
            >
              <b>€ {CurrencyFormatter.format(totalPrice)}</b>
            </Typography>
            {selectedBox.id !== '0' ? (
              <Typography
                style={
                  !matchesPhone
                    ? { color: '#FFFFFF', opacity: 0.7 }
                    : { color: '#FFFFFF', opacity: 0.7, fontSize: 10 }
                }
                variant="caption"
              >
                +{' '}
                <b>
                  € {CurrencyFormatter.format(selectedBox.price)} Posto auto
                </b>
              </Typography>
            ) : (
              ''
            )}
            <Divider style={{ marginBlock: 10, backgroundColor: '#FFFFFF' }} />
            {store.loggedUser && store.loggedUser.logged ? (
              <Button
                className="button-plan-next"
                style={{ height: 50 }}
                onClick={handleConferma}
                disabled={disabledConfermaBtn}
              >
                <Typography
                  style={
                    !matchesPhone
                      ? {
                          letterSpacing: 2.21,
                          fontSize: 14,
                          color: '#F8DEC4',
                          fontWeight: 600,
                        }
                      : {
                          letterSpacing: 0.6,
                          fontSize: 12,
                          color: '#F8DEC4',
                          fontWeight: 600,
                        }
                  }
                  variant="subtitle1"
                >
                  <b>salva la configurazione</b>
                </Typography>
              </Button>
            ) : (
              <div className="salta-la-coda-container">
                <div>
                  <Typography
                    style={{
                      letterSpacing: 2.21,
                      fontSize: 14,
                      color: 'white',
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                    variant="subtitle1"
                  >
                    SALTA LA CODA - PRENOTA SUBITO IL TUO APPUNTAMENTO
                  </Typography>
                  <Typography
                    style={{
                      letterSpacing: 0.6,
                      fontSize: 12,
                      color: 'white',
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                    variant="subtitle1"
                  >
                    nella modalit&agrave;, nel giorno e nell&apos;ora che
                    preferisci
                  </Typography>
                </div>
                <Button
                  startIcon={
                    <img
                      className="salta-la-coda-icon"
                      src={'/icon/computer-hover.svg'}
                    />
                  }
                  className="salta-la-coda-button"
                  onClick={() => setOpenVideocall(true)}
                  disabled={disabledConfermaBtn}
                >
                  <Typography
                    style={
                      !matchesPhone
                        ? {
                            letterSpacing: 2.21,
                            fontSize: 14,
                            color: '#F8DEC4',
                            fontWeight: 600,
                          }
                        : {
                            letterSpacing: 0.6,
                            fontSize: 12,
                            color: '#F8DEC4',
                            fontWeight: 600,
                          }
                    }
                    variant="subtitle1"
                  >
                    Videocall
                  </Typography>
                </Button>
                <Dialog
                  open={openVideocall}
                  PaperProps={{
                    className: 'salta-la-coda-modal-paper',
                    style: {
                      width: '80vw',
                      maxWidth: 1150,
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      height: '90%',
                      maxHeight: '90%',
                      paddingBottom: '5%',
                      paddingTop: '5%',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ padding: '0px' }}
                  >
                    <IconButton
                      onClick={() => {
                        setOpenVideocall(false);
                      }}
                      aria-label="close"
                      style={{ height: '50px', padding: '0px' }}
                    >
                      <CancelIcon
                        style={{
                          fill: '#F5942E',
                          width: '1.5em',
                          height: '1.5em',
                        }}
                      />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    style={{
                      padding: '0px',
                      height: 'fit-content',
                      flex: '0 1 auto',
                      alignSelf: 'center',
                    }}
                  >
                    <ScrollArea
                      speed={0.4}
                      className="area"
                      contentClassName="content"
                      horizontal={false}
                      ref={scrollAreaRef}
                    >
                      <Grid
                        container
                        className="salta-la-coda-modal-grid-container"
                      >
                        <Grid
                          item
                          xs={12}
                          md={7}
                          className="salta-la-coda-modal-image-panel"
                          style={{ backgroundImage: `url('${imageModal}')` }}
                        >
                          <Grid
                            container
                            className="salta-la-coda-modal-image-panel-text-container"
                          >
                            <Grid
                              item
                              xs={12}
                              md={7}
                              className="salta-la-coda-modal-image-panel-text-discover"
                            >
                              Scopri il <b>mondo</b> di
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={5}
                              className="salta-la-coda-modal-image-panel-text-theunits"
                            >
                              <img src="/icon/Icon-TheUnits.svg" />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className="salta-la-coda-modal-image-panel-text-from-home"
                            >
                              Comodamente da casa
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          className="salta-la-coda-modal-text-panel"
                        >
                          <div className="salta-la-coda-modal-text-top-icon">
                            <img
                              style={{ height: '22px', width: '22px' }}
                              src="/icon/Icon-computer.svg"
                            />
                          </div>
                          <p className="salta-la-coda-modal-text-title">
                            IL TUO APPUNTAMENTO IN VIDEOCALL IN POCHI SEMPLICI
                            PASSI!
                          </p>
                          <div className="salta-la-coda-modal-text-explain-container">
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-top-left">
                                <div className="salta-la-coda-modal-text-explain-icon">
                                  <img
                                    style={{ height: '50%', width: '50%' }}
                                    src="/icon/Icon-email.svg"
                                  />
                                </div>
                              </div>
                              <div className="salta-la-coda-modal-text-explain-top-right">
                                RICEVERAI UNA E-MAIL DI CONFERMA CON LE
                                INFORMAZIONI PASSO PASSO PER FARE UN VIDEO
                                APPUNTAMENTO CON NOI SENZA USCIRE DI CASA
                              </div>
                            </div>
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-bottom-left" />
                              <div className="salta-la-coda-modal-text-explain-bottom-right">
                                con il nostro personale dedicato per scoprire
                                tutto su <span>THE UNITS</span>.
                              </div>
                            </div>
                          </div>
                          <div className="salta-la-coda-modal-text-explain-container">
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-top-left">
                                <div className="salta-la-coda-modal-text-explain-icon">
                                  <img
                                    style={{
                                      height: '65%',
                                      width: '65%',
                                    }}
                                    src="/icon/Icon-sito.svg"
                                  />
                                </div>
                              </div>
                              <div className="salta-la-coda-modal-text-explain-top-right">
                                GRAZIE ALLA NOSTRA INNOVATIVA PIATTAFORMA
                                TECNOLOGICA AVRAI ACCESSO A TUTTI I CONTENUTI
                              </div>
                            </div>
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-bottom-left" />
                              <div className="salta-la-coda-modal-text-explain-bottom-right">
                                per scoprire come sarà la{' '}
                                <span>TUA NUOVA CASA</span>.
                              </div>
                            </div>
                          </div>
                          <Button
                            startIcon={
                              <img
                                className="salta-la-coda-icon"
                                src={'/icon/showroom-hover.svg'}
                              />
                            }
                            className="salta-la-coda-modal-link-button"
                            onClick={() => {
                              window.location.href =
                                'https://theunits.it/salta-la-coda/online';
                            }}
                          >
                            <Typography
                              style={{
                                letterSpacing: 0.6,
                                fontSize: 12,
                                color: '#F8DEC4',
                              }}
                              variant="subtitle1"
                            >
                              <b>
                                Registrati e scegli la data e l&apos;ora che
                                preferisci
                              </b>
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </ScrollArea>
                  </DialogContent>
                </Dialog>
                <Button
                  startIcon={
                    <img
                      className="salta-la-coda-icon"
                      src={'/icon/showroom-hover.svg'}
                    />
                  }
                  className="salta-la-coda-button"
                  onClick={() => {
                    setOpenShowroom(true);
                  }}
                  disabled={disabledConfermaBtn}
                >
                  <Typography
                    style={
                      !matchesPhone
                        ? {
                            letterSpacing: 2.21,
                            fontSize: 14,
                            color: '#F8DEC4',
                          }
                        : {
                            letterSpacing: 0.6,
                            fontSize: 12,
                            color: '#F8DEC4',
                          }
                    }
                    variant="subtitle1"
                  >
                    <b>Showroom</b>
                  </Typography>
                </Button>
                <Dialog
                  open={openShowroom}
                  PaperProps={{
                    className: 'salta-la-coda-modal-paper',
                    style: {
                      width: '80vw',
                      maxWidth: 1150,
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      height: '90%',
                      maxHeight: '90%',
                      paddingBottom: '5%',
                      paddingTop: '5%',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ padding: '0px' }}
                  >
                    <IconButton
                      onClick={() => {
                        setOpenShowroom(false);
                      }}
                      aria-label="close"
                      style={{ height: '50px', padding: '0px' }}
                    >
                      <CancelIcon
                        style={{
                          fill: '#F5942E',
                          width: '1.5em',
                          height: '1.5em',
                        }}
                      />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    style={{
                      padding: '0px',
                      height: 'fit-content',
                      flex: '0 1 auto',
                      alignSelf: 'center',
                    }}
                  >
                    <ScrollArea
                      speed={0.4}
                      className="area"
                      contentClassName="content"
                      horizontal={false}
                      ref={scrollAreaRef}
                    >
                      <Grid
                        container
                        className="salta-la-coda-modal-grid-container"
                      >
                        <Grid
                          item
                          xs={12}
                          md={7}
                          className="salta-la-coda-modal-image-panel"
                          style={{ backgroundImage: `url('${imageModal2}')` }}
                        >
                          <Grid
                            container
                            className="salta-la-coda-modal-image-panel-text-container"
                          >
                            <Grid
                              item
                              xs={12}
                              md={7}
                              className="salta-la-coda-modal-image-panel-text-discover"
                            >
                              Entra nel <b>mondo</b> di
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={5}
                              className="salta-la-coda-modal-image-panel-text-theunits"
                            >
                              <img src="/icon/Icon-TheUnits.svg" />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className="salta-la-coda-modal-image-panel-text-from-home"
                            ></Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          className="salta-la-coda-modal-text-panel"
                        >
                          <div className="salta-la-coda-modal-text-top-icon">
                            <img
                              style={{ height: '22px', width: '22px' }}
                              src="/icon/Icon-showroom.svg"
                            />
                          </div>
                          <p className="salta-la-coda-modal-text-title">
                            VIVI L'ESPERIENZA UNICA DI UNA
                            <br />
                            VISITA NEL NOSTRO SHOWROOM
                          </p>
                          <div className="salta-la-coda-modal-text-explain-container">
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-top-left">
                                <div className="salta-la-coda-modal-text-explain-icon">
                                  <img
                                    style={{
                                      height: '75%',
                                      width: '75%',
                                    }}
                                    src="/icon/Icon-worker.svg"
                                  />
                                </div>
                              </div>
                              <div className="salta-la-coda-modal-text-explain-top-right">
                                UN APPUNTAMENTO RISERVATO
                                <br />
                                CON IL NOSTRO PERSONALE DEDICATO
                              </div>
                            </div>
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-bottom-left" />
                              <div className="salta-la-coda-modal-text-explain-bottom-right">
                                per toccare con mano materiali e finiture e
                                scoprire tutto sullo straordinario progetto{' '}
                                <span>THE UNITS</span>.
                              </div>
                            </div>
                          </div>
                          <div className="salta-la-coda-modal-text-explain-container">
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-top-left">
                                <div className="salta-la-coda-modal-text-explain-icon">
                                  <img
                                    style={{
                                      height: '75%',
                                      width: '75%',
                                    }}
                                    src="/icon/Icon-visore.svg"
                                  />
                                </div>
                              </div>
                              <div className="salta-la-coda-modal-text-explain-top-right">
                                POTRAI IMMERGERTI NEL PROGETTO E VIVERE IN
                                ANTEPRIMA GLI SPAZI DELLA TUA NUOVA CASA
                              </div>
                            </div>
                            <div className="salta-la-coda-modal-text-explain">
                              <div className="salta-la-coda-modal-text-explain-bottom-left" />
                              <div className="salta-la-coda-modal-text-explain-bottom-right">
                                grazie a una tecnologia in virtual reality mai
                                vista prima.
                              </div>
                            </div>
                          </div>
                          <Button
                            startIcon={
                              <img
                                className="salta-la-coda-icon"
                                src={'/icon/showroom-hover.svg'}
                              />
                            }
                            className="salta-la-coda-modal-link-button"
                            onClick={() => {
                              window.location.href =
                                'https://theunits.it/salta-la-coda/showroom';
                            }}
                          >
                            <Typography
                              style={{
                                letterSpacing: 0.6,
                                fontSize: 12,
                                color: '#F8DEC4',
                              }}
                              variant="subtitle1"
                            >
                              <b>
                                Registrati e scegli la data e l&apos;ora che
                                preferisci
                              </b>
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </ScrollArea>
                  </DialogContent>
                </Dialog>{' '}
              </div>
            )}
          </Card>
        </Card>
        <Snackbar
          autoHideDuration={matchesPhone ? 3000 : 5000}
          open={snackbarOpen}
          onClose={handleSuccessSnackbarClose}
        >
          <Alert
            severity={snackbarType}
            variant="filled"
            className={`${snackbarType}-alert`}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: matchesPhone ? 'x-small' : 12,
              }}
            >
              <b>{snackbarMsg}</b>
            </Typography>
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
});

export default SummaryDetails;
