const appartmentTypes = [
  {
    id: 0,
    name: 'Bilocale',
    plural: 'bilocali',
    affaccio: 'Via Grosotto',
    typology: 'Bilocale',
    url: 'Bilocale',
    filter: appartment => appartment.plan.typology.name === 'Bilocale',
  },
  {
    id: 1,
    name: 'Trilocale Traiano',
    plural: 'trilocali',
    affaccio: 'Via Traiano',
    typology: 'Trilocale',
    url: 'Trilocale',
    filter: appartment =>
      appartment.plan.typology.name === 'Trilocale' &&
      appartment.sides[0].name === 'ESTERNO',
  },
  {
    id: 2,
    name: 'Trilocale Accursio',
    plural: 'trilocali',
    affaccio: 'Piazzale Accursio',
    typology: 'Trilocale',
    url: 'Trilo-quadrilocale',
    filter: appartment =>
      appartment.plan.typology.name === 'Trilocale' &&
      appartment.sides[0].name === 'INTERNO',
  },
  {
    id: 3,
    name: 'Quadrilocale',
    plural: 'quadrilocali',
    affaccio: 'Piazzale Accursio',
    typology: 'Quadrilocale',
    url: 'Trilo-quadrilocale',
    filter: appartment => appartment.plan.typology.name === 'Quadrilocale',
  },
];

export default appartmentTypes;
