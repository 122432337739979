import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import 'styles/header.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { getLinkByParams } from '../../utils/utils';
import RouteConsts from '../../constants/RoutesConst';
import { useParams, useHistory } from 'react-router-dom';
import CookiesUtils, { logOut } from 'utils/cookieUtils';
import CalculateUrl from 'utils/CalculateUrl';
import LogOut from 'utils/LogOut';
import { useMutation } from '@apollo/client';
import Client from 'client/Client';

const useStyles = makeStyles(theme => ({
  root: {
    '& hr': {
      margin: 7,
      width: 1,
      backgroundColor: '#705466',
      height: 35,
    },
    '& hr:last-of-type': {
      height: 'auto',
      margin: '0 0 2px 0',
    },
  },
}));

/**
 * Page Header
 * based on material-ui BreadCrumbs
 * Unknown props are simply ignored
 * @component
 * @see {@link https://material-ui.com/api/breadcrumbs/  BreadCrumbs API}
 */
const Header = observer(props => {
  const store = useStore();
  function getSteps() {
    return ['TIPOLOGIA', 'PIANO', 'POSTO AUTO', 'RIEPILOGO'];
  }
  const steps = getSteps();
  const params = useParams();
  const history = useHistory();
  const handleStep = step => () => {
    if (store.step !== step) {
      history.push(
        getLinkByParams(params, RouteConsts.map(r => r.navigation)[step])
      );
    }
  };
  const [logout] = useMutation(Client.LOGOUT);

  const logoutFunc = store => {
    logout()
      .then(() => {
        LogOut(store);
      })
      .catch(e => {
        store.setSnackbarError(true, 'Errore non previsto durante il logout');
      });
  };

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  if (!store.loggedUser.language) {
    store.setLoggedUserLang('it');
  }

  const classes = useStyles();

  const handleClickLogin = () => {
    history.push('/login');
  };

  const handleClickMyHome = () => {
    window.location.href =
      window.location.protocol + '//' + CalculateUrl('salesonline', 'myhome');
  };

  const websiteLink =
    store.getRole() === 'vendor'
      ? window.location.protocol +
        '//' +
        CalculateUrl('salesonline', 'neurosales')
      : `https://${store.hostKey}`;

  const handleClickTornaAlSito = () => {
    window.open(websiteLink, '_self');
  };

  const handleGoBack = () => {
    if (!store.step && store.step !== 0) {
      history.goBack();
    } else if (store.signinVendor) {
      store.setSigninVendor(false);
    } else {
      history.push(
        getLinkByParams(
          params,
          RouteConsts.map(r => r.navigation)[store.step - 1]
        ),
        params
      );
    }
  };

  const role = store.getRole();

  return (
    <>
      {!matchesPhone ? (
        <>
          <Grid container className="header-wrapper">
            <a href={websiteLink}>
              <img src="/icon/Raggruppa 22253.svg" alt="" />
            </a>
          </Grid>
          <div className="header-stepper">
            <div>
              {(store.step || store.step === 0) && (
                <>
                  <img
                    className="logo-header-stepper"
                    src="/icon/logo.svg"
                    alt="logo"
                  />
                  <Divider orientation="vertical" className="header-divider" />
                  <Stepper
                    className="stepper"
                    activeStep={store.step}
                    alternativeLabel
                  >
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepButton onClick={handleStep(index)}>
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </>
              )}
            </div>
            <div>
              <Grid container className={classes.root}>
                {!(
                  props.navigation &&
                  props.navigation.location &&
                  props.navigation.location.pathname === '/'
                ) && (
                  <>
                    <Grid item>
                      <Button
                        className="header-button"
                        startIcon={
                          <img src="/icon/icon-freccia-indietro.svg" />
                        }
                        onClick={handleGoBack}
                      >
                        <Typography variant="subtitle2">
                          <b>INDIETRO</b>
                        </Typography>
                      </Button>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                  </>
                )}
                <Grid item>
                  <Button
                    className="header-button"
                    startIcon={<img src={'/icon/icon-logo.svg'} />}
                    onClick={handleClickTornaAlSito}
                  >
                    <Typography variant="subtitle2">
                      <b>Torna alla home</b>
                    </Typography>
                  </Button>
                </Grid>
                {!(
                  props.navigation &&
                  props.navigation.location &&
                  props.navigation.location.pathname === '/login'
                ) && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <Grid item>
                      {role === 'vendor' ? (
                        <Button
                          onClick={() => logoutFunc(store)}
                          className="header-button private-area-button"
                          startIcon={<img src="/icon/icon-lock.svg" />}
                        >
                          <Typography variant="subtitle2">
                            <b>Logout</b>
                          </Typography>
                        </Button>
                      ) : role === 'client' ? (
                        <Button
                          onClick={handleClickMyHome}
                          className="header-button private-area-button"
                          startIcon={<img src="/icon/icon-private-area.svg" />}
                        >
                          <Typography variant="subtitle2">
                            <b>Area riservata</b>
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          onClick={handleClickLogin}
                          className="header-button private-area-button"
                          startIcon={<img src="/icon/icon-private-area.svg" />}
                        >
                          <Typography variant="subtitle2">
                            <b>Area riservata</b>
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </div>
        </>
      ) : (
        <>
          <Grid container className="header-wrapper">
            <a href={websiteLink}>
              <img src="/icon/Raggruppa 22253.svg" alt="" />
            </a>
          </Grid>
          <div className="header-mobile">
            {(store.step || store.step === 0) && (
              <div>
                <Stepper
                  style={{ paddingTop: 14, paddingLeft: 10 }}
                  activeStep={store.step}
                  alternativeLabel
                  connector={false}
                >
                  {steps.map((label, index) => (
                    <Step className="mobile" key={label}>
                      <StepButton onClick={handleStep(index)}></StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            )}
            <Grid
              container
              style={{ justifyContent: 'flex-end', alignContent: 'center' }}
            >
              {!(
                props.navigation &&
                props.navigation.location &&
                props.navigation.location.pathname === '/'
              ) && (
                <>
                  <Grid item>
                    <IconButton
                      className="header-button"
                      onClick={handleGoBack}
                    >
                      <img src="/icon/icon-freccia-indietro.svg" />
                    </IconButton>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    className="divider-cta-mobile"
                    flexItem
                  />
                </>
              )}
              <Grid item>
                <IconButton
                  className="header-button"
                  onClick={handleClickTornaAlSito}
                >
                  <img src={'/icon/icon-logo.svg'} />
                </IconButton>
              </Grid>
              {!(
                props.navigation &&
                props.navigation.location &&
                props.navigation.location.pathname === '/login'
              ) && (
                <>
                  <Divider
                    orientation="vertical"
                    className="divider-cta-mobile"
                    flexItem
                  />
                  <Grid item>
                    {role === 'vendor' ? (
                      <IconButton
                        onClick={() => logoutFunc(store)}
                        className="header-button"
                      >
                        <img src="/icon/icon-lock.svg" />
                      </IconButton>
                    ) : role === 'client' ? (
                      <IconButton
                        className="header-button"
                        onClick={handleClickMyHome}
                      >
                        <img src="/icon/icon-private-area.svg" />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="header-button"
                        onClick={handleClickLogin}
                      >
                        <img src="/icon/icon-private-area.svg" />
                      </IconButton>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      )}
    </>
  );
});

Header.propTypes = {
  /** title: page title */
  title: PropTypes.string,
  /** goBack: boolean. If true, current page is a subPath. TODO: change this prop name */
  goBack: PropTypes.bool,
  setLanguage: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  goBack: false,
};

export default Header;
