import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  withRouter,
} from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import RouteConsts from './routes/Routes.jsx';
import { useStore } from 'store/storeUtils.js';
import { useLazyQuery, useQuery } from '@apollo/client';
import Client from 'client/Client';
import DetectUrl from 'utils/DetectUrl';
import { IntlProvider, FormattedMessage } from 'react-intl';
import langLocal from 'translations/languages.json';
import moment from 'moment';
import Loader from 'components/common/Loader';
import { autorun } from 'mobx';
import ServiceWorkerWrapper from 'components/common/ServiceWorkerWrapper';
import TuningCountdown from 'components/common/TuningCountdown';
import Cookies from 'js-cookie';
import { toJS } from 'mobx';
import PageTemplate from 'pages/PageTemplate';
import Login from 'components/salesOnline/Login';
import CookiesUtils from 'utils/cookieUtils.jsx';
import TagManager from 'react-gtm-module';
import useHotjar from 'react-use-hotjar';
import queryString from 'query-string';

function loadHTMLElement(filename, filetype) {
  let fileref;
  if (filetype === 'js') {
    fileref = document.createElement('script');
    fileref.setAttribute('type', 'text/javascript');
    fileref.setAttribute('src', filename);
  } else if (filetype === 'css') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('type', 'text/css');
    fileref.setAttribute('href', filename);
  } else if (filetype.includes('icon')) {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', filetype);
    fileref.setAttribute('href', filename);
  } else if (filetype === 'manifest') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'manifest');
    fileref.setAttribute('href', filename);
  }
  if (typeof fileref !== 'undefined') {
    document.getElementsByTagName('head')[0].appendChild(fileref);
  }
}

const App = () => {
  const store = useStore();
  const [loading, setLoading] = React.useState(true);

  //obtain browser language
  var lang = navigator.language.split(/[-_]/)[0];
  const defLanguage = 'en';

  if (store.loggedUser && store.loggedUser.language) {
    lang = store.loggedUser.language;
  }
  if (lang !== defLanguage && lang !== 'it') {
    lang = defLanguage;
  }

  const [locale, setLocale] = React.useState(defLanguage);

  let mess = {};
  mess[defLanguage] = langLocal[defLanguage];

  const [message, setMessage] = React.useState(mess);

  const UrlQueryStrings = window.location.search;
  const { goBack } = queryString.parse(UrlQueryStrings);

  const myCustomLogger = console.info;
  const { initHotjar, readyState } = useHotjar();
  if (goBack) {
    CookiesUtils.setGoBack(goBack);
  }

  fetch(
    process.env.REACT_APP_BUCKET_BASEURL +
      process.env.REACT_APP_TRANSLATION_URL,
    { cache: 'no-cache' }
  )
    //fetch('src/translations/languages.json')
    .then(response => response.json())
    .then(jsonData => {
      mess = message;
      mess[lang] = jsonData[lang];
      mess[defLanguage] = jsonData[defLanguage];
      setMessage(mess);
      setLocale(lang);
      moment.locale(lang);
      store.setSystemLanguage(lang);
      setLoading(false);
    })
    .catch(error => {
      // console.error(error);
      mess = message;
      mess[defLanguage] = langLocal[defLanguage];
      setMessage(mess);
      setLocale(defLanguage);
      moment.locale(defLanguage);
      store.setSystemLanguage(defLanguage);
      setLoading(false);
    });

  React.useEffect(() =>
    autorun(() => {
      if (
        (store.loggedUser &&
          store.loggedUser.language &&
          store.loggedUser.language === store.systemLanguage) ||
        !store.loggedUser ||
        !store.loggedUser.language
      ) {
        return;
      }

      setLoading(true);
      lang = store.loggedUser.language;
      if (lang !== defLanguage && lang !== 'it') {
        lang = defLanguage;
      }

      if (!mess[lang]) {
        fetch(
          process.env.REACT_APP_BUCKET_BASEURL +
            process.env.REACT_APP_TRANSLATION_URL,
          {
            cache: 'no-cache',
          }
        )
          //fetch('/translations/languages.json')
          .then(response => response.json())
          .then(jsonData => {
            mess = message;
            mess[lang] = jsonData[lang];
            mess[defLanguage] = jsonData[defLanguage];
            setMessage(mess);
            setLocale(lang);
            moment.locale(lang);
            store.setSystemLanguage(lang);
            setLoading(false);
          })
          .catch(error => {
            // console.error(error);
            mess = message;
            mess[defLanguage] = langLocal[defLanguage];
            setMessage(mess);
            setLocale(defLanguage);
            moment.locale(defLanguage);
            store.setSystemLanguage(defLanguage);
            setLoading(false);
          });
      } else {
        setLocale(lang);
        moment.locale(lang);
        store.setSystemLanguage(lang);
        setLoading(false);
      }
    })
  );

  React.useEffect(() => {
    initHotjar(2483831, 6, false, myCustomLogger);
  }, [initHotjar]);

  const handleErrorTranslation = err => {
    if (err.code === 'MISSING_TRANSLATION') {
      return;
    } else if (err.code === 'FORMAT_ERROR') {
      console.warn(err);
      return;
    }
    console.error(err);
    return;
  };

  return (
    <>
      <IntlProvider
        messages={message[locale]}
        locale={locale}
        key={locale}
        defaultLocale="en"
        onError={err => handleErrorTranslation}
      >
        {/* <ServiceWorkerWrapper /> */}

        <Loader
          customClass="fixedPosition"
          loading={loading}
          hasBackdrop={false}
          z
        />
        <Login />
        <Router>
          <Switch>
            <Route path="/:path?" component={() => <LayoutRoot />} />
          </Switch>
        </Router>
        {/* {CookiesUtils.getToken() && toJS(store.loggedUser) && (
					<TuningCountdown threshold={process.env.REACT_APP_TIMEOUT_TOKEN} />
				)} */}
      </IntlProvider>
    </>
  );
};

export const LayoutRoot = () => {
  const store = useStore();

  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl())
  );

  const [userInfoCall, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  React.useEffect(() => {
    if (
      prjInfo &&
      !prjInfo.loading &&
      prjInfo.called &&
      prjInfo.data &&
      prjInfo.data.getProjectInfoByHost
    ) {
      store.setAssetsByObject({ ...prjInfo.data.getProjectInfoByHost });
      if (store.floorPlanningConfig && store.floorPlanningConfig.gtmId) {
        TagManager.initialize({
          gtmId: store.floorPlanningConfig.gtmId,
        });
      }

      loadHTMLElement(
        `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${encodeURI(
          store.loginProjectName
        )}/global/img/favicon.ico`,
        'icon'
      );
      loadHTMLElement(
        `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${encodeURI(
          store.loginProjectName
        )}/global/img/logo192.png`,
        'apple-touch-icon'
      );
      // set page title according to DB info (salesOnline key)
      document.title = store.pageTitles.floorPlanning;
      userInfoCall(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId));
    }
  }, [
    prjInfo &&
      !prjInfo.loading &&
      prjInfo.called &&
      prjInfo.data &&
      prjInfo.data.getProjectInfoByHost,
  ]);

  React.useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
      store.setLoggedUser({
        ...userInfo.data.getUserByJWT,
        loading: false,
        logged: true,
      });
      const user = userInfo.data.getUserByJWT;
      if (store.getRole() === 'client') {
        CookiesUtils.setClientId(user.client);
      }
    } else {
      if (!userInfo.called || userInfo.loading) {
        store.setLoggedUser({
          ...store.loggedUser,
          loading: true,
          logged: false,
        });
      } else {
        store.setLoggedUser({
          ...store.loggedUser,
          loading: true,
          logged: false,
        });
      }
    }
  }, [userInfo && !userInfo.loading && userInfo.called && userInfo.data]);

  RouteConsts.map(el => {
    el.navbar = store.displaySection.includes(el.id);
  });

  if (
    !prjInfo.called ||
    prjInfo.loading ||
    !userInfo.called ||
    userInfo.loading
  ) {
    return <Loader customClass="fixedPosition" loading hasBackdrop={false} z />;
  }

  return (
    <Router basename={'/'}>
      <Switch>
        {/* TUTTE LE ROUTE, ORDINATE PER ORDER */}
        {RouteConsts.sort((a, b) => a.order - b.order).map(el => {
          return el.private ? (
            <PrivateRoute
              exact={el.exact}
              key={el.order}
              path={el.to}
              ToRender={el.component}
              title={el.title}
              goBack={el.goBack}
              navbar={store.displaySection.includes(el.id)}
            />
          ) : (
            <Route
              exact={el.exact}
              key={el.order}
              path={el.to}
              render={withRouter(nav => {
                if (el.noPageTemplate)
                  return <el.component {...el} navigation={nav}></el.component>;
                return (
                  <PageTemplate
                    BodyComponent={el.component}
                    {...el}
                    navigation={nav}
                  ></PageTemplate>
                );
              })}
              title={el.title}
              goBack={el.goBack}
              navbar={store.displaySection.includes(el.id)}
            />
          );
        })}
      </Switch>
    </Router>
  );
};

export default App;
