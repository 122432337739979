import { makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  disclaimerText: {
    [theme.breakpoints.down('600')]: {
      fontSize: 'xx-small',
      lineHeight: '1.3',
    },
  },
}));

const ImagesDisclaimer = ({ className }) => {
  const classes = useStyles();
  return (
    <Alert
      iconMapping={{ info: <img src="/icon/Raggruppa 21191.svg" /> }}
      severity="info"
      className={className}
    >
      <Typography variant="subtitle2" className={classes.disclaimerText}>
        Tutte <b>le immagini inserite sono a scopo illustrativo</b> e non fanno
        riferimento all'appartamento selezionato
      </Typography>
    </Alert>
  );
};

ImagesDisclaimer.propTypes = {
  className: PropTypes.string,
};

export default ImagesDisclaimer;
