import React, { useState } from 'react';
import { observer } from 'mobx-react';
import 'styles/login.scss';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useStore } from 'store/storeUtils';
import SummarySelect from './SummarySelect';
import SummaryDetails from './SummaryDetails';
import Loader from 'components/common/Loader';
import { useHistory } from 'react-router-dom';
import SigninVendor from './SigninVendor';
import appartmentTypes from 'constants/AppartmentTypes';

const Summary = observer(props => {
  const { navigation, icons } = props;
  const history = useHistory();
  const store = useStore();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  //appartamento
  const plan = navigation.match.params.plan;
  //box
  const box = navigation.match.params.box;

  //logica stepper
  if (store.step !== 3) {
    store.setStep(3);
  }

  const selectedAppartment = store.availableAppartments.find(
    item => item._label === plan
  );
  const selectedBox = store.allParkingSpaceCars.find(
    item => item._label === box
  );

  const typology = appartmentTypes.find(
    item =>
      item.name.toLowerCase() === navigation.match.params.typology.toLowerCase()
  );

  return (
    <Grid container style={{ background: '#FCFCFC' }}>
      <Loader z loading={loading} />
      <SigninVendor />
      <SummarySelect
        matchesPhone={matchesPhone}
        boxes={store.allParkingSpaceCars}
        selectedBox={selectedBox}
        selectedAppartment={selectedAppartment}
      />
      <SummaryDetails
        selectedBox={selectedBox}
        selectedAppartment={selectedAppartment}
        icons={icons}
        showPageLoader={value => setLoading(value)}
        typology={typology}
      />
    </Grid>
  );
});

export default Summary;
