import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@material-ui/core';
import 'styles/button.scss';

/**
 * Default button
 * based on material-ui Button
 * @component
 * @see {@link https://material-ui.com/api/button/|material-ui Button API}
 */
const Button = props => {
  const { children, type, extraClass, onClick, color, autoFocus, ...rest } =
    props;

  return (
    <MuiButton
      type={type}
      onClick={onClick}
      className={`${extraClass}`}
      variant="contained"
      color={color}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

/** prop types */
Button.propTypes = {
  /** children: required content (usually text) */
  children: PropTypes.node.isRequired,
  /** onclick: required, action to perform */
  type: PropTypes.func.isRequired,
  /** extraClass: additional className */
  extraClass: PropTypes.string,
  /** color: default, primary or secondary */
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
};

Button.defaultProps = {
  autoFocus: false,
  onClick: () => {},
  extraClass: '',
  color: 'primary',
  type: '',
};

export default Button;
