import React from 'react';
import Cookies from 'js-cookie';
import psl from 'psl';
import { useStore } from '../../store/storeUtils';
import { useHistory } from 'react-router-dom';
import DetectUrl from '../../utils/DetectUrl';
import { HomeRoute } from 'routes/Routes';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

const ClientComponent = observer(() => {
  const store = useStore();
  const history = useHistory();

  React.useEffect(() =>
    autorun(() => {
      let domain = 'localhost';
      if (window.location.hostname !== 'localhost') {
        const pslUrl = psl.parse(window.location.hostname);
        domain = pslUrl.domain;
      }
      // Se atterro con un url che contiene il parametro "hostname", cancello i cookie
      const landingUrl = window.document.location.href;
      if (landingUrl.includes('hostname')) {
        try {
          // Elimino i cookie JWT
          Cookies.remove('jwt', { domain: domain });
          Cookies.remove('refreshToken', { domain: domain });
          Cookies.remove('expiresIn', { domain: domain });
          Cookies.remove('vendorId', { domain: domain });
        } catch (e) {
          console.log(e);
        }
      }

      if (!store.loggedUser || store.loggedUser.loading) {
        return;
      }

      if (store.getRole() === 'vendor') {
        history.replace(HomeRoute.to);
      } else {
        // Se non esiste il JWT l'utente non è loggato e lo rimando alla login
        // Creo l'url di reindirizzamento in base all'ambiente in cui ci troviamo
        const newTool = 'businessplatform';

        const hostKey =
          localStorage.getItem('floorplanning_dev_hostname') || DetectUrl();

        let finalHostName = '';
        let hostname = window.location.hostname.replace('www.', '');
        if (hostname === hostKey.replace('www.', '')) {
          finalHostName =
            hostname.replace('floorplanning', newTool) + '/login?';
        } else {
          if (hostname.indexOf('localhost') >= 0) {
            // devo puntare a biz-tecma-dev1 con relativo hostname
            finalHostName = `${newTool}-biz-tecma-dev1.tecmasolutions.com/login?hostname=${hostKey}&`;
          } else if (
            hostname.indexOf('biz-tecma') >= 0 ||
            hostname.indexOf('-demo.tecmasolutions.com') >= 0
          ) {
            const newHostName = hostname.replace('salesonline', newTool); // devo puntare a biz-tecma-dev1 con relativo hostname
            finalHostName = `${newHostName}/login?hostname=${hostKey}&`;
          } else {
            finalHostName = `${hostKey.replace('www', newTool)}/login?`;
          }
        }

        const backToUrl = new URL(window.location.href);
        const params = new URLSearchParams(backToUrl.search);
        params.delete('hostname');
        let backTo = `${window.location.origin}${window.location.pathname}?${params}`;
        backTo = backTo.replace('/login?', '');

        window.location.href = `https://${finalHostName}backTo=${encodeURIComponent(
          backTo
        )}`;
      }
    }, [store.projectId, store.loggedUser])
  );

  return <></>;
});

export default ClientComponent;
