import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactSVG from 'components/common/SvgLoader.jsx';
import { Alert } from '@material-ui/lab';
import { useStore } from 'store/storeUtils';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 'max-content',
  },
  button: {
    overflowX: 'auto',
    display: 'flex',
  },
  section1: {
    paddingBottom: 88,
  },
  section3: {
    marginBottom: 10,
  },
}));

const PlanSelect = observer(props => {
  const {
    matchesPhone,
    soldAppartments,
    appartamentiFiltrati,
    appartamentiDisponibili,
    selectedAppartment,
    typology,
    handleClick,
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(soldAppartments > 0);

  const handleClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const classes = useStyles();
  const store = useStore();

  const getSvgProspetto = side =>
    `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/svg/prospetto-${side}.svg`;

  const noTFilter = arr => arr.filter(a => !a.name.endsWith('T'));

  return (
    <Grid item xs={12} className="plan-grid-select container-with-drawer">
      {!matchesPhone ? (
        <>
          <div className="prospect-select">
            <div className={classes.root}>
              <div className={classes.section1}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="h4">
                      <b>2</b>&nbsp;{' '}
                      <thin style={{ fontWeight: '300' }}>|</thin>
                      &thinsp; Scegli il <b>piano</b>
                    </Typography>
                    <Typography style={{ marginTop: 12 }} variant="subtitle1">
                      <b>I {typology.plural} disponibili</b> sono allocati ai
                      seguenti piani
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.section2}>
                <Typography style={{ marginBottom: 10 }} variant="subtitle2">
                  {typology.plural.toUpperCase()} DISPONIBILI
                </Typography>
                <Divider style={{ marginRight: 45 }} />
                <div style={{ marginTop: 15 }} className={classes.button}>
                  {appartamentiDisponibili.map((item, index) => (
                    <>
                      <Button
                        style={{ width: 160, minWidth: 'fit-content' }}
                        className={
                          'appartment-button' +
                          (selectedAppartment &&
                          selectedAppartment.id === item.id
                            ? ' selected-appartment'
                            : '')
                        }
                        onClick={() => handleClick(item)}
                        variant="outlined"
                        key={index}
                      >
                        <b>{item._label}</b>
                        &emsp;
                        <Divider
                          className={
                            'divider-button' +
                            (selectedAppartment &&
                            selectedAppartment.id === item.id
                              ? ' selected-appartment'
                              : '')
                          }
                          orientation="vertical"
                          flexItem
                        />
                        &emsp;
                        <light style={{ fontWeight: 300 }}>
                          {item.floor}° PIANO
                        </light>
                      </Button>
                    </>
                  ))}
                </div>
              </div>
              <div className={classes.section3}>
                <Typography style={{ fontSize: '13px' }} variant="subtitle2">
                  <img src="/icon/icon-clicca.svg" />{' '}
                  <b id="pulsa">Clicca sull&apos;appartamento</b> che preferisci
                  e visualizzalo sul prospetto.
                </Typography>
              </div>
            </div>
          </div>
          <Snackbar
            className="svg-alert"
            autoHideDuration={5000}
            open={snackbarOpen}
            onClose={handleClose}
          >
            <Alert
              iconMapping={{ error: <img src="icon/Icon-metro-warning.svg" /> }}
              severity="error"
              variant="filled"
              style={{
                height: 50,
                width: 480,
                flex: 'unset',
                alignSelf: 'center',
                fontSize: 'small',
              }}
              action={
                <IconButton onClick={handleClose}>
                  <img src="/icon/icon-chiudi-warming.svg" />
                </IconButton>
              }
            >
              {soldAppartments > 1 ? (
                <>
                  Sono già stati venduti {soldAppartments} appartamenti su{' '}
                  {noTFilter(store.allAppartments).length} disponibili. <br />
                  In grigio vedi quelli venduti
                </>
              ) : (
                <>
                  &Egrave; già stato venduto un appartamento su{' '}
                  {noTFilter(store.allAppartments).length} disponibili. <br />
                  In grigio vedi quello venduto
                </>
              )}
            </Alert>
          </Snackbar>
          {typology.name !== 'Trilocale Traiano' ? (
            <ReactSVG
              className="prospect-svg-container"
              src={getSvgProspetto('B')}
              renumerateIRIElements={false}
              beforeInjection={actualSvg => {
                if (actualSvg) {
                  appartamentiFiltrati.forEach(app => {
                    if (app.available) {
                      let seleziona = actualSvg.querySelector(
                        `g[id="_Seleziona_${app._label
                          .replaceAll('T', '')
                          .replaceAll('.', '')}_" i]`
                      );
                      if (
                        selectedAppartment &&
                        selectedAppartment.id === app.id
                      ) {
                        seleziona = actualSvg.querySelector(
                          `g[id="_Selezionato_${app._label
                            .replaceAll('T', '')
                            .replaceAll('.', '')}_" i]`
                        );
                      }
                      if (seleziona) {
                        seleziona.style.display = 'block';
                        seleziona.style.cursor = 'pointer';
                        seleziona.addEventListener('click', () =>
                          handleClick(app)
                        );
                      }
                    } else {
                      const venduto = actualSvg.querySelector(
                        `g[id="_Venduto_${app._label
                          .replaceAll('T', '')
                          .replaceAll('.', '')}_" i]`
                      );
                      if (venduto) {
                        venduto.style.display = 'block';
                      }
                    }
                  });
                  actualSvg.setAttribute(
                    'preserveAspectRatio',
                    'xMidYMin slice'
                  );
                }
              }}
              wrapper="div"
            />
          ) : (
            <ReactSVG
              className="prospect-svg-container"
              src={getSvgProspetto('A')}
              renumerateIRIElements={false}
              beforeInjection={actualSvg => {
                if (actualSvg) {
                  appartamentiFiltrati.forEach(app => {
                    if (app.available) {
                      let seleziona = actualSvg.querySelector(
                        `g[id="_Seleziona_${app._label
                          .replaceAll('T', '')
                          .replaceAll('.', '')}_" i]`
                      );
                      if (
                        selectedAppartment &&
                        selectedAppartment.id === app.id
                      ) {
                        seleziona = actualSvg.querySelector(
                          `g[id="_Selezionato_${app._label
                            .replaceAll('T', '')
                            .replaceAll('.', '')}_" i]`
                        );
                      }
                      if (seleziona) {
                        seleziona.style.display = 'block';
                        seleziona.style.cursor = 'pointer';
                        seleziona.addEventListener('click', () =>
                          handleClick(app)
                        );
                      }
                    } else {
                      const venduto = actualSvg.querySelector(
                        `g[id="_Venduto_${app._label
                          .replaceAll('T', '')
                          .replaceAll('.', '')}_" i]`
                      );
                      if (venduto) {
                        venduto.style.display = 'block';
                      }
                    }
                  });
                  actualSvg.setAttribute(
                    'preserveAspectRatio',
                    'xMidYMin slice'
                  );
                }
              }}
              wrapper="div"
            />
          )}
        </>
      ) : (
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              paddingLeft: 18,
              paddingTop: 30,
              zIndex: 1,
              backgroundColor: 'white',
              position: 'relative',
            }}
          >
            <Typography style={{ color: '#481635' }} variant="caption">
              <b>2</b> | Scegli il <b>piano</b>
            </Typography>
            <Typography
              style={{ color: '#705466', fontSize: 10 }}
              variant="subtitle1"
            >
              <b>I {typology.plural} disponibili</b> sono allocati ai seguenti
              piani
            </Typography>
            <div className={classes.button}>
              {appartamentiDisponibili.map((item, index) => (
                <>
                  <Button
                    style={{
                      minWidth: 120,
                      height: 25,
                      marginBlock: 30,
                      fontSize: 10,
                    }}
                    className={
                      'appartment-button' +
                      (selectedAppartment && selectedAppartment.id === item.id
                        ? ' selected-appartment'
                        : '')
                    }
                    onClick={() => handleClick(item)}
                    variant="outlined"
                    key={index}
                  >
                    <b>{item._label}</b>
                    &emsp;
                    <Divider
                      orientation="vertical"
                      className={
                        'divider-button' +
                        (selectedAppartment && selectedAppartment.id === item.id
                          ? ' selected-appartment'
                          : '')
                      }
                      flexItem
                    />
                    &emsp;
                    <light style={{ fontWeight: 300 }}>
                      {item.floor}° PIANO
                    </light>
                  </Button>
                </>
              ))}
            </div>
          </div>
          <Snackbar
            autoHideDuration={3000}
            open={snackbarOpen}
            onClose={handleClose}
          >
            <Alert
              iconMapping={{ error: <img src="icon/Icon-metro-warning.svg" /> }}
              severity="error"
              variant="filled"
              style={{
                height: 50,
                width: 480,
                flex: 'unset',
                alignSelf: 'center',
                fontSize: 8,
              }}
              action={
                <IconButton onClick={handleClose}>
                  <img src="/icon/icon-chiudi-warming.svg" />
                </IconButton>
              }
            >
              {soldAppartments > 1 ? (
                <>
                  Sono già stati venduti {soldAppartments} appartamenti su{' '}
                  {noTFilter(store.allAppartments).length} disponibili. <br />
                  In grigio vedi quelli venduti
                </>
              ) : (
                <>
                  &Egrave; già stato venduto un appartamento su{' '}
                  {noTFilter(store.allAppartments).length} disponibili. <br />
                  In grigio vedi quello venduto
                </>
              )}
            </Alert>
          </Snackbar>
          {typology.name !== 'Trilocale Traiano' ? (
            <ReactSVG
              className="mobile-prospect-svg-container"
              src={getSvgProspetto('B')}
              renumerateIRIElements={false}
              beforeInjection={actualSvg => {
                if (actualSvg) {
                  appartamentiFiltrati.forEach(app => {
                    if (app.available) {
                      let seleziona = actualSvg.querySelector(
                        `g[id="_Seleziona_${app._label.replaceAll(
                          '.',
                          ''
                        )}_" i]`
                      );
                      if (
                        selectedAppartment &&
                        selectedAppartment.id === app.id
                      ) {
                        seleziona = actualSvg.querySelector(
                          `g[id="_Selezionato_${app._label.replaceAll(
                            '.',
                            ''
                          )}_" i]`
                        );
                      }
                      if (seleziona) {
                        seleziona.style.display = 'block';
                        seleziona.style.cursor = 'pointer';
                        seleziona.addEventListener('click', () =>
                          handleClick(app)
                        );
                      }
                    } else {
                      const venduto = actualSvg.querySelector(
                        `g[id="_Venduto_${app._label.replaceAll('.', '')}_" i]`
                      );
                      if (venduto) {
                        venduto.style.display = 'block';
                      }
                    }
                  });
                  actualSvg.setAttribute(
                    'preserveAspectRatio',
                    'xMidYMin slice'
                  );
                }
              }}
              wrapper="div"
            />
          ) : (
            <ReactSVG
              className="mobile-prospect-svg-container"
              src={getSvgProspetto('A')}
              renumerateIRIElements={false}
              beforeInjection={actualSvg => {
                if (actualSvg) {
                  appartamentiFiltrati.forEach(app => {
                    if (app.available) {
                      let seleziona = actualSvg.querySelector(
                        `g[id="_Seleziona_${app._label.replaceAll(
                          '.',
                          ''
                        )}_" i]`
                      );
                      if (
                        selectedAppartment &&
                        selectedAppartment.id === app.id
                      ) {
                        seleziona = actualSvg.querySelector(
                          `g[id="_Selezionato_${app._label.replaceAll(
                            '.',
                            ''
                          )}_" i]`
                        );
                      }
                      if (seleziona) {
                        seleziona.style.display = 'block';
                        seleziona.style.cursor = 'pointer';
                        seleziona.addEventListener('click', () =>
                          handleClick(app)
                        );
                      }
                    } else {
                      const venduto = actualSvg.querySelector(
                        `g[id="_Venduto_${app._label.replaceAll('.', '')}_" i]`
                      );
                      if (venduto) {
                        venduto.style.display = 'block';
                      }
                    }
                  });
                  actualSvg.setAttribute(
                    'preserveAspectRatio',
                    'xMidYMin slice'
                  );
                }
              }}
              wrapper="div"
            />
          )}
        </div>
      )}
    </Grid>
  );
});

export default PlanSelect;
