import React from 'react';
import Capitalize from 'utils/Capitalize';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';

const googleAnalyticsViewFormat = response => {
  const googleMap = {};
  const pieGraphDevice = [];
  const pieGraphUsers = [];
  var barGraphUsr = [];
  const lineGraphDailyUsr = [];
  const tableData = [];
  googleMap['totalUsr'] = {
    text: <FormattedMessage id="google.totalUsers" defaultMessage="TODO def" />,
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  googleMap['sessions'] = {
    text: (
      <FormattedMessage id="google.totalSession" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  googleMap['avgSession'] = {
    text: (
      <FormattedMessage
        id="google.averageSessionTime"
        defaultMessage="TODO def"
      />
    ),
    dataNumber: 0,
    type: 'time',
    period: '',
  };
  googleMap['pageView'] = {
    text: <FormattedMessage id="google.pageView" defaultMessage="TODO def" />,
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  googleMap['avgTimePage'] = {
    text: (
      <FormattedMessage id="google.averagePageTime" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'time',
    period: '',
  };
  googleMap['pageviewsPerSession'] = {
    text: (
      <FormattedMessage id="google.pagesPerSession" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  googleMap['bounceRate'] = {
    text: <FormattedMessage id="google.bounceRate" defaultMessage="TODO def" />,
    dataNumber: 0,
    type: 'percent',
    period: '',
  };
  googleMap['percentNewSessions'] = {
    text: (
      <FormattedMessage id="google.newSessions" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'percent',
    period: '',
  };
  googleMap['usrSessions'] = {
    text: (
      <FormattedMessage id="google.sessionsPerUser" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  if (response && response.length > 0) {
    const dataParams = response[0].data.rows;
    const dataDailyUsers = response[0].data.rows;
    const dataUsersType = response[1].data.rows;
    const dataUsersDevice = response[2].data.rows;
    const dataUsersSource = response[3].data.rows;
    const device = {};
    const typeUsr = {};
    const source = {};
    const landing = {};
    if (dataUsersDevice && dataUsersDevice.length > 0)
      dataUsersDevice.map(elem => {
        device[elem.dimensions[1]] =
          device[elem.dimensions[1]] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(device[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);
      });
    if (dataUsersType && dataUsersType.length > 0)
      dataUsersType.map(elem => {
        typeUsr[elem.dimensions[1]] =
          typeUsr[elem.dimensions[1]] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(typeUsr[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);
      });
    if (dataUsersSource && dataUsersSource.length > 0)
      dataUsersSource.map(elem => {
        source[elem.dimensions[1]] =
          source[elem.dimensions[1]] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(source[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);
      });
    if (dataDailyUsers && dataDailyUsers.length > 0)
      dataDailyUsers.map(elem => {
        //line graph dayly users
        lineGraphDailyUsr.push({
          name: formatDate(elem.dimensions[0]),
          utenti: parseInt(elem.metrics[0].values[0]),
        });
      });

    orderDate(lineGraphDailyUsr);

    lineGraphDailyUsr.map(el => {
      const arrDate = el.name.split('-');
      el.name = arrDate[0] + '-' + arrDate[1];
    });

    if (dataParams && dataParams.length > 0) {
      dataParams.map(elem => {
        googleMap.totalUsr.dataNumber += parseInt(elem.metrics[0].values[0]);
        googleMap.sessions.dataNumber += parseInt(elem.metrics[0].values[1]);
        googleMap.avgSession.dataNumber += parseInt(elem.metrics[0].values[2]);
        googleMap.pageView.dataNumber += parseInt(elem.metrics[0].values[3]);
        googleMap.avgTimePage.dataNumber += parseInt(elem.metrics[0].values[4]);
        googleMap.bounceRate.dataNumber += parseInt(elem.metrics[0].values[6]);
        googleMap.percentNewSessions.dataNumber += parseInt(
          elem.metrics[0].values[7]
        );
      });
      googleMap.usrSessions.dataNumber =
        googleMap.sessions.dataNumber / googleMap.totalUsr.dataNumber;
      googleMap.pageviewsPerSession.dataNumber =
        googleMap.pageView.dataNumber / googleMap.sessions.dataNumber;
      googleMap.avgSession.dataNumber =
        parseInt(googleMap.avgSession.dataNumber) / parseInt(dataParams.length);
      googleMap.avgTimePage.dataNumber =
        parseInt(googleMap.avgTimePage.dataNumber) /
        parseInt(dataParams.length);
      googleMap.bounceRate.dataNumber =
        parseInt(googleMap.bounceRate.dataNumber) / parseInt(dataParams.length);
      googleMap.percentNewSessions.dataNumber =
        parseInt(googleMap.percentNewSessions.dataNumber) /
        parseInt(dataParams.length);
    }
    for (const [key, val] of Object.entries(device))
      pieGraphDevice.push({ data: val, label: key });

    for (const [key, val] of Object.entries(typeUsr))
      pieGraphUsers.push({ data: val, label: key });

    for (const [key, val] of Object.entries(source))
      barGraphUsr.push([{ data: val, label: key }]);

    for (const [key, val] of Object.entries(landing)) {
      tableData.push({
        landing: Capitalize(
          key.replace('/', '').replace('.html', '').replace('-', ' ')
        ),
        usrs: val[0],
        bounce: Math.round(val[1] * 100) / 100,
        view: key,
      });
    }
  }
  googleMap['daviceChart'] = pieGraphDevice;
  googleMap['userChart'] = pieGraphUsers;
  googleMap['sourceChart'] = barGraphUsr;
  googleMap['dailyChartChart'] = lineGraphDailyUsr;
  googleMap['landingTable'] = tableData;

  return googleMap;
};

const googleAnalyticsFormat = response => {
  const googleMap = {};
  const pieGraphDevice = [];
  const pieGraphUsers = [];
  const barGraphUsr = [];
  const lineGraphDailyUsr = [];
  const tableData = [];
  googleMap['sessions'] = {
    text: (
      <FormattedMessage id="google.totalSession" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  googleMap['avgSession'] = {
    text: (
      <FormattedMessage
        id="google.averageSessionTime"
        defaultMessage="TODO def"
      />
    ),
    dataNumber: 0,
    type: 'time',
    period: '',
  };
  googleMap['pageView'] = {
    text: <FormattedMessage id="google.pageView" defaultMessage="TODO def" />,
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  googleMap['avgTimePage'] = {
    text: (
      <FormattedMessage id="google.averagePageTime" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'time',
    period: '',
  };
  googleMap['pageviewsPerSession'] = {
    text: (
      <FormattedMessage id="google.pagesPerSession" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  googleMap['bounceRate'] = {
    text: <FormattedMessage id="google.bounceRate" defaultMessage="TODO def" />,
    dataNumber: 0,
    type: 'percent',
    period: '',
  };
  googleMap['percentNewSessions'] = {
    text: (
      <FormattedMessage id="google.newSessions" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'percent',
    period: '',
  };
  googleMap['usrSessions'] = {
    text: (
      <FormattedMessage id="google.sessionsPerUser" defaultMessage="TODO def" />
    ),
    dataNumber: 0,
    type: 'number',
    period: '',
  };
  if (response) {
    const dataUsers = response[0].data.rows;
    const dataParams = response[1].data.rows;
    const dataDailyUsers = response[2].data.rows;
    const device = {};
    const typeUsr = {};
    const source = {};
    const landing = {};
    const baseUrl =
      response[2].data.rows && response[2].data.rows.length > 0
        ? response[2].data.rows[0].dimensions[2].replace('www.', 'https://')
        : 'https://localhost/';
    var usrNumber = 0;
    var userSession = 0;
    if (dataUsers && dataUsers.length > 0)
      dataUsers.map(elem => {
        device[elem.dimensions[1]] =
          device[elem.dimensions[1]] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(device[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);

        typeUsr[elem.dimensions[2]] =
          typeUsr[elem.dimensions[2]] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(typeUsr[elem.dimensions[2]]) +
              parseInt(elem.metrics[0].values[0]);

        source[elem.dimensions[3]] =
          source[elem.dimensions[3]] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(source[elem.dimensions[3]]) +
              parseInt(elem.metrics[0].values[0]);
      });
    if (dataDailyUsers && dataDailyUsers.length > 0)
      dataDailyUsers.map(elem => {
        usrNumber += parseInt(elem.metrics[0].values[0]);
        //filtrando utenti per data
        lineGraphDailyUsr.push({
          name: formatDate(elem.dimensions[0]),
          utenti: parseInt(elem.metrics[0].values[0]),
          landing: elem.dimensions[1],
        });
      });
    orderDate(lineGraphDailyUsr);

    lineGraphDailyUsr.map(el => {
      const arrDate = el.name.split('-');
      el.name = arrDate[0] + '-' + arrDate[1];
      return el;
    });

    var landingVal = {};
    if (dataParams && dataParams.length > 0) {
      dataParams.map(elem => {
        googleMap.sessions.dataNumber += parseInt(elem.metrics[0].values[0]);
        googleMap.avgSession.dataNumber += parseInt(elem.metrics[0].values[1]);
        googleMap.pageView.dataNumber += parseInt(elem.metrics[0].values[2]);
        googleMap.avgTimePage.dataNumber += parseInt(elem.metrics[0].values[3]);
        googleMap.bounceRate.dataNumber += parseInt(elem.metrics[0].values[5]);
        googleMap.percentNewSessions.dataNumber += parseInt(
          elem.metrics[0].values[6]
        );

        if (response[1].columnHeader.dimensions[1].includes('pagePath')) {
          if (!landingVal[elem.dimensions[1]])
            landingVal[elem.dimensions[1]] = 0;
          if (!landing[elem.dimensions[1]])
            landing[elem.dimensions[1]] = [0, 0];
          landing[elem.dimensions[1]][0] += parseInt(elem.metrics[0].values[7]);
          landing[elem.dimensions[1]][1] += parseInt(elem.metrics[0].values[5]);
          landingVal[elem.dimensions[1]]++;
        }
      });
      googleMap.pageviewsPerSession.dataNumber =
        googleMap.pageView.dataNumber / googleMap.sessions.dataNumber;
      googleMap.avgSession.dataNumber =
        parseInt(googleMap.avgSession.dataNumber) / parseInt(dataParams.length);
      googleMap.avgTimePage.dataNumber =
        parseInt(googleMap.avgTimePage.dataNumber) /
        parseInt(dataParams.length);
      googleMap.bounceRate.dataNumber =
        parseInt(googleMap.bounceRate.dataNumber) / parseInt(dataParams.length);
      googleMap.percentNewSessions.dataNumber =
        parseInt(googleMap.percentNewSessions.dataNumber) /
        parseInt(dataParams.length);
      userSession = googleMap.sessions.dataNumber / usrNumber;
    }

    for (const [key, val] of Object.entries(device))
      pieGraphDevice.push({ data: val, label: key });

    for (const [key, val] of Object.entries(typeUsr))
      pieGraphUsers.push({ data: val, label: key });

    for (const [key, val] of Object.entries(source))
      barGraphUsr.push([{ data: val, label: key }]);

    for (const [key, val] of Object.entries(landing)) {
      const bounce = val[1] / landingVal[key];
      tableData.push({
        landing: Capitalize(
          key.replace('/', '').replace('.html', '').replace('-', ' ')
        ),
        usrs: val[0],
        bounce: Math.round(bounce * 100) / 100,
        view: baseUrl + key,
      });
    }
    googleMap.usrSessions.dataNumber = userSession;
  }
  googleMap['totalUsr'] = {
    text: <FormattedMessage id="google.totalUsers" defaultMessage="TODO def" />,
    dataNumber: usrNumber,
    type: 'number',
    period: '',
  };
  googleMap['daviceChart'] = pieGraphDevice;
  googleMap['userChart'] = pieGraphUsers;
  googleMap['sourceChart'] = barGraphUsr;
  googleMap['dailyChartChart'] = lineGraphDailyUsr;
  googleMap['landingTable'] = tableData;

  return googleMap;
};

const googleAdsFormat = response => {
  const googleAdsMap = {};
  if (response) {
    const dataAds = response[4].data.rows;
    const impression = {};
    const click = {};
    const clickTime = {};
    const pieGraphAds = [];
    const barGraphAds = [];
    const lineGraphAds = [];
    var totalClick = 0;
    var totalImpresison = 0;
    if (dataAds && dataAds.length > 0)
      dataAds.map(elem => {
        impression[elem.dimensions[1]] =
          impression[elem.dimensions[1]] === undefined
            ? elem.metrics[0].values[1]
            : parseInt(impression[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[1]);

        click[elem.dimensions[1]] =
          click[elem.dimensions[1]] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(click[elem.dimensions[1]]) +
              parseInt(elem.metrics[0].values[0]);

        clickTime[formatDate(elem.dimensions[0])] =
          clickTime[formatDate(elem.dimensions[0])] === undefined
            ? elem.metrics[0].values[0]
            : parseInt(clickTime[formatDate(elem.dimensions[0])]) +
              parseInt(elem.metrics[0].values[0]);

        totalClick += parseInt(elem.metrics[0].values[0]);
        totalImpresison += parseInt(elem.metrics[0].values[1]);
      });
    for (const [key, val] of Object.entries(impression))
      pieGraphAds.push({ data: val, label: key });

    for (const [key, val] of Object.entries(clickTime))
      lineGraphAds.push({ name: key, click: val });

    for (const [key, val] of Object.entries(click))
      barGraphAds.push([{ data: val, label: key }]);

    orderDate(lineGraphAds);

    lineGraphAds.map(el => {
      const arrDate = el.name.split('-');
      el.name = arrDate[0] + '-' + arrDate[1];
    });

    googleAdsMap['deviceImpressions'] = pieGraphAds;
    googleAdsMap['deviceClicks'] = barGraphAds;
    googleAdsMap['deviceClickDate'] = lineGraphAds;
    googleAdsMap['allClick'] = totalClick;
    googleAdsMap['allImpression'] = totalImpresison;
  }
  return googleAdsMap;
};

const appartamentTypeFormat = appType => {
  var verticalGraphData = [{ label: 'none', locali: 0 }];
  if (appType && appType.data) {
    verticalGraphData = [];
    const type = appType.data.getAllTypologies;
    type
      .filter(locale => locale.visits > 2)
      .map(elem => {
        verticalGraphData.push({
          label: elem.name,
          locali: parseInt(elem.visits),
        });
      });
  }
  return verticalGraphData;
};

const appartamentPlanFormat = appPlan => {
  const tableData = [];
  if (appPlan && appPlan.data) {
    const plan = appPlan.data.getAllPlans;
    var incr = 0;
    var orderPlan = plan.slice().sort((a, b) => b.visits - a.visits);
    orderPlan.map(elem => {
      incr++;
      tableData.push({
        position: incr,
        unit: elem.name,
        taglio: elem.typology ? elem.typology.name : '',
        visit: parseInt(elem.visits),
      });
    });
  }
  return tableData;
};

const facebookAdsFormat = response => {
  const facebookMap = {};
  const lineGraphAds = [];
  const barGraphAds = [];
  const barAgeGraph = [];
  const pieChart = [];
  if (response) {
    const date = {};
    const deviceClk = {};
    const age = {};
    const deviceImp = {};
    var totalClick = 0;
    var totalImpression = 0;
    if (response.impressionsDataAge && response.impressionsDataDevice) {
      const impressionArrayAge = response.impressionsDataAge;
      impressionArrayAge.map(elem => {
        age[elem.age] =
          age[elem.age] === undefined
            ? parseInt(elem.impressions)
            : parseInt(age[elem.age]) + parseInt(elem.impressions);
      });
      const impressionArrayDevice = response.impressionsDataDevice;
      impressionArrayDevice.map(elem => {
        deviceImp[elem.device_platform] =
          deviceImp[elem.device_platform] === undefined
            ? parseInt(elem.impressions)
            : parseInt(deviceImp[elem.device_platform]) +
              parseInt(elem.impressions);
      });
      impressionArrayDevice.map(elem => {
        totalImpression =
          parseInt(totalImpression) + parseInt(elem.impressions);
      });
      for (const [key, val] of Object.entries(age))
        barAgeGraph.push({ label: key, age: val });

      for (const [key, val] of Object.entries(deviceImp))
        pieChart.push({ data: val, label: key });
    } else if (response.length) {
      const clickArray = response;
      clickArray.map(elem => {
        deviceClk[elem.device_platform] =
          deviceClk[elem.device_platform] === undefined
            ? parseInt(elem.clicks)
            : parseInt(deviceClk[elem.device_platform]) + parseInt(elem.clicks);

        date[formatDate(elem.date_start)] =
          date[formatDate(elem.date_start)] === undefined
            ? parseInt(elem.clicks)
            : parseInt(date[formatDate(elem.date_start)]) +
              parseInt(elem.clicks);

        totalClick = parseInt(totalClick) + parseInt(elem.clicks);
      });
      for (const [key, val] of Object.entries(date))
        lineGraphAds.push({ name: key, click: val });

      for (const [key, val] of Object.entries(deviceClk))
        barGraphAds.push([{ data: val, label: key }]);
    }

    orderDate(lineGraphAds);

    lineGraphAds.map(el => {
      const arrDate = el.name.split('-');
      el.name = arrDate[0] + '-' + arrDate[1];
    });

    facebookMap['deviceImpressions'] = pieChart;
    facebookMap['ageImpression'] = barAgeGraph;
    facebookMap['deviceClicks'] = barGraphAds;
    facebookMap['lineDataClick'] = lineGraphAds;
    facebookMap['allClick'] = totalClick;
    facebookMap['allImpression'] = totalImpression;
  }
  return facebookMap;
};

function formatDate(data) {
  var newDate = '';
  if (!data.includes('-'))
    newDate = data.replace(/(\d{4})(\d{2})(\d{2})/, '$3-$2-$1');
  else newDate = data.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3-$2-$1');
  return newDate;
}

function orderDate(listArray) {
  listArray.sort(function (first, second) {
    const a_number =
      second.name.split('-')[2] +
      second.name.split('-')[1] +
      second.name.split('-')[0];
    const b_number =
      first.name.split('-')[2] +
      first.name.split('-')[1] +
      first.name.split('-')[0];
    return (parseInt(a_number) - parseInt(b_number)) * -1;
  });
}

function filterDates(data, type, start, end) {
  var newRawData = [];
  //end define pivot period
  const format = 'YYYY-MM-DD';
  end = end.endOf('day');
  start = start.startOf('day');
  //filter google data response
  if (data && type === 'g') {
    data.map(element => {
      var arrayVal = [];
      const newElement = {
        data: { rows: [] },
        columnHeader: { dimensions: [] },
      };
      if (element.data.rows)
        arrayVal = element.data.rows.filter(
          period =>
            moment(
              period.dimensions[0].replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'),
              format
            ) <= end &&
            start <=
              moment(
                period.dimensions[0].replace(
                  /(\d{4})(\d{2})(\d{2})/,
                  '$1-$2-$3'
                ),
                format
              )
        );
      newElement.columnHeader.dimensions = Object.values({
        ...element.columnHeader.dimensions,
      });
      newElement.data.rows = Object.values({ ...arrayVal });
      newRawData.push(newElement);
    });
    //filter facebook data response
  } else if (type === 'fb') {
    if (data.impressionsDataAge && data.impressionsDataDevice) {
      var arrayValAge = data.impressionsDataAge.filter(
        filterPeriod =>
          moment(filterPeriod.date_start, format) <= end &&
          start <= moment(filterPeriod.date_start, format)
      );
      newRawData.impressionsDataAge = Object.values({ ...arrayValAge });

      var arrayValDevice = data.impressionsDataDevice.filter(
        filterPeriod =>
          moment(filterPeriod.date_start, format) <= end &&
          start <= moment(filterPeriod.date_start, format)
      );
      newRawData.impressionsDataDevice = Object.values({ ...arrayValDevice });
    } else {
      if (data && data.length > 0) {
        var arrayVal = data.filter(
          filterPeriod =>
            moment(filterPeriod.date_start, format) <= end &&
            start <= moment(filterPeriod.date_start, format)
        );
        newRawData = Object.values({ ...arrayVal });
      }
    }
  }
  return newRawData;
}

export {
  googleAnalyticsFormat,
  googleAnalyticsViewFormat,
  appartamentTypeFormat,
  appartamentPlanFormat,
  facebookAdsFormat,
  googleAdsFormat,
  filterDates,
};
