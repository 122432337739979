import Typology from '../components/salesOnline/Typology';
import Plan from '../components/salesOnline/Plan';
import Summary from '../components/salesOnline/Summary';
import Box from '../components/salesOnline/Box';

const routesConst = [
  { component: Typology, navigation: '/' },
  { component: Plan, navigation: '/:typology' },
  { component: Box, navigation: '/:typology/:plan' },
  { component: Summary, navigation: '/:typology/:plan/:box' },
];

export default routesConst;
