import {
  getPaymentPrice,
  getPaymentPercentage,
  getSaldoARogito,
  getPromoPrice,
} from 'utils/utilsPayments';
import moment from 'utils/MyMoment';
import {
  roundToNextHundred,
  roundToFirstDecimal,
  calculateInstallment,
  percentage,
  isNessunBox,
} from 'utils/utils';
import { cloneDeep, pick } from 'lodash';

const installment = {
  percentage: 0.8,
  durationInYear: 30,
  taxRate: 0.89,
};

const preparePayments = (quote, totalPrice) => {
  const payments = [];
  quote.payments.forEach((payment, idx) => {
    if (typeof payment !== 'undefined')
      payments.push({
        name: payment.name.toString(),
        price: parseFloat(
          getPaymentPrice(payment, quote.payments, idx, totalPrice)
        ),
        endDate:
          payment.endDate !== null
            ? new Date(payment.endDate).toISOString('it-IT')
            : payment.daysForPayment
            ? new Date(
                moment().add(payment.daysForPayment, 'd').format('YYYY-MM-DD')
              ).toISOString('it-IT')
            : undefined,
        percentage: parseFloat(
          getPaymentPercentage(payment, quote.payments, idx, totalPrice)
        ),
        daysForPayment: payment.daysForPayment
          ? payment.daysForPayment
          : payment.endDate
          ? 1 + moment(payment.endDate, 'YYYY-MM-DD').diff(moment(), 'days')
          : payment.daysForPayment,
        notes: payment.notes,
        type: payment.type ? payment.type : null,
      });
  });
  return payments;
};

const prepareExpenses = (quote, totalPrice) => {
  const expenses = [];
  quote.expenses.forEach(expense => {
    if (typeof expense !== 'undefined')
      expenses.push({
        name: expense.name,
        price: expense.percentage
          ? roundToNextHundred(
              parseFloat(percentage(expense.percentage, totalPrice))
            )
          : roundToNextHundred(expense.price)
          ? roundToNextHundred(parseFloat(expense.price))
          : 0,
        percentage: roundToFirstDecimal(expense.percentage)
          ? roundToFirstDecimal(parseFloat(expense.percentage))
          : 0,
        emptyLabel: expense.emptyLabel,
        type: expense.type ? expense.type : '',
      });
  });

  return expenses;
};

const buildCustomQuote = ({
  parkingSpace_cars,
  basements,
  payments,
  expenses,
  quote,
  selectedAppartment,
  features,
  totalPrice,
}) => {
  const { fixedRate, variableRate, daysofexpired } = quote;
  return {
    boxes: [],
    basements: basements,
    payments,
    parkingSpace_bykes: [],
    parkingSpace_cars: parkingSpace_cars,
    expenses,
    daysofexpired,
    promos: quote.promos.map(item => ({
      name: item.name,
      promo: item.promo ? item.promo : 'SCONTO',
      info: item.info ? item.info : item.name,
      percentage: roundToFirstDecimal(item.percentage)
        ? roundToFirstDecimal(parseFloat(item.percentage))
        : 0,
      price: item.price
        ? roundToNextHundred(item.price)
        : item.percentage
        ? roundToNextHundred(
            parseFloat(percentage(item.percentage, selectedAppartment.price))
          )
        : 0,
      type: item.type,
      class: item.class,
      endDate: item.endDate,
      notes: item.notes ? item.notes : '',
    })),
    fixedRate:
      fixedRate && fixedRate.percentage
        ? {
            value: calculateInstallment(
              quote.payments.filter(obj => obj.name === 'Saldo a Rogito') &&
                quote.payments.filter(obj => obj.name === 'Saldo a Rogito')
                  .length !== 0 &&
                quote.payments.filter(obj => obj.name === 'Saldo a Rogito')[0]
                  .price,
              fixedRate.percentage,
              installment.durationInYear,
              1,
              true
            ),
            percentage: fixedRate.percentage,
          }
        : {},
    variableRate:
      variableRate && variableRate.percentage
        ? {
            value: calculateInstallment(
              quote.payments.filter(obj => obj.name === 'Saldo a Rogito') &&
                quote.payments.filter(obj => obj.name === 'Saldo a Rogito')
                  .length !== 0 &&
                quote.payments.filter(obj => obj.name === 'Saldo a Rogito')[0]
                  .price,
              variableRate.percentage,
              installment.durationInYear,
              1,
              true
            ),
            percentage: variableRate.percentage,
          }
        : {},
    rate: 0,
    promoPrice: getPromoPrice(selectedAppartment.price, quote.promos),
    totalPrice: totalPrice,
    features: features,
  };
};

export const buildQuote = (client, vendor, selectedAppartment, selectedBox) => {
  const caparraPreliminarePostoAuto = 3000;
  const standardQuote = cloneDeep(selectedAppartment.standardQuote);
  const parkingSpace_cars = [];
  const basements = [];
  const features = [];
  let totalPrice = getPromoPrice(
    selectedAppartment.price,
    standardQuote.promos
  );

  if (!isNessunBox(selectedBox)) {
    features.push(selectedBox.id);
    // eslint-disable-next-line no-unused-vars
    const parkingSpaceToSave = pick(selectedBox, ['price', 'floor', 'meters']);
    parkingSpaceToSave.meters = parkingSpaceToSave.meters
      ? parkingSpaceToSave.meters
      : '0';
    parkingSpaceToSave.parkingSpace_car = selectedBox.code;
    parkingSpace_cars.push(parkingSpaceToSave);
    totalPrice += selectedBox.price;

    try {
      const caparraPreliminare = standardQuote.payments.filter(
        payment => payment.name === 'Caparra preliminare/Compromesso'
      )[0];
      if (caparraPreliminare.price !== 0) {
        caparraPreliminare.price += caparraPreliminarePostoAuto;
      }
    } catch (e) {
      console.warn('Nessuna caparra preliminare/compromesso');
    }
  }

  if (standardQuote.features.length !== 0) {
    const basement = standardQuote.features[0];
    features.push(basement.id);
    const basementToSave = pick(basement, ['price', 'floor', 'meters']);
    basementToSave.basement = basement.code;
    basements.push(basementToSave);
    totalPrice += basement.price;
  }

  standardQuote.payments.push(getSaldoARogito(standardQuote, totalPrice));

  const payments = preparePayments(standardQuote, totalPrice);
  const expenses = prepareExpenses(standardQuote, totalPrice);
  const customQuote = buildCustomQuote({
    parkingSpace_cars,
    basements,
    payments,
    expenses,
    quote: standardQuote,
    selectedAppartment,
    features,
    totalPrice,
  });

  return {
    vendor: vendor,
    client: client,
    appartment: selectedAppartment.id,
    customQuote,
  };
};
