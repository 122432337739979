import { observer } from 'mobx-react';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
const Slider = observer(({ imgs, imgClassName }) => {
  return (
    <>
      <Carousel
        indicators={false}
        autoPlay={false}
        navButtonsAlwaysVisible={true}
        animation="slide"
        NextIcon={<img src="/icon/Raggruppa 21031.svg" />}
        PrevIcon={<img src="/icon/Raggruppa 21030.svg" />}
        navButtonsProps={{
          style: {
            backgroundColor: 'unset',
            borderRadius: 0,
          },
        }}
        navButtonsAlwaysInvisible={!(imgs && imgs.length > 1)}
        className="carousel-full-height"
      >
        {imgs.map((item, i) => (
          <img
            className={imgClassName}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            key={i}
            src={item}
          />
        ))}
      </Carousel>
    </>
  );
});

export default Slider;
