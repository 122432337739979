import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';

/**
 * A default loader
 * based on material-ui Circular Progress
 * @component
 * @see {@link https://material-ui.com/api/circular-progress/ Circular Progress API}
 */
const Loader = props => {
  const { loading, hasBackdrop, z, transparency } = props;

  const backdropColorClass = transparency
    ? 'backdrop-transparent'
    : 'backdrop-color';

  return (
    <>
      {loading &&
        (hasBackdrop ? (
          <Portal>
            <Backdrop
              open={loading}
              classes={{
                root: z ? 'more-z ' + backdropColorClass : backdropColorClass,
              }}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          </Portal>
        ) : (
          <div
            className={
              z
                ? 'more-z class-wrapper absolute-position ' + backdropColorClass
                : ' class-wrapper absolute-position ' + backdropColorClass
            }
          >
            <CircularProgress
              color="primary"
              className="absolute-position-relative-content-loader"
            />
          </div>
        ))}
    </>
  );
};

Loader.defaultProps = {
  hasBackdrop: true,
  z: true,
  transparency: false,
};

/** prop types */
Loader.propTypes = {
  /** loading: required boolean */
  loading: PropTypes.bool.isRequired,
  /** hasBackdrop: boolean, whenever loader is at center of a backdrop or not.
   * False means it will be display relatively to its container */
  hasBackdrop: PropTypes.bool,
  /** z: boolean, controls extra z index for backdrop */
  z: PropTypes.bool,
  /** transparency: background is transparent */
  transparency: PropTypes.bool,
};

export default Loader;
