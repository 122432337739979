import React from 'react';
import Home from 'pages/Home';
import Login from 'pages/Login';
import LoginVendor from 'pages/LoginVendor';
import ConfirmUser from 'pages/ConfirmUser';
import ChangePassword from 'pages/ChangePassword';
import Analytics from 'images/assets/analytics.svg';
import { FormattedMessage } from 'react-intl';

export const HomeRoute = {
  id: 'web',
  order: 1000,
  to: '/',
  title: (
    <FormattedMessage id="dashboard.webAnalytics" defaultMessage="TODO def" />
  ),
  navbarTitle: (
    <FormattedMessage id="dashboard.webAnalytics" defaultMessage="TODO def" />
  ),
  icon: Analytics,
  navbar: true,
  goBack: false,
  component: Home,
  private: false,
  noPageTemplate: true,
};

export const LoginRoute = {
  id: 'login',
  order: 0,
  exact: true,
  to: '/login',
  title: <FormattedMessage id="dashboard.login" defaultMessage="TODO def" />,
  navbarTitle: (
    <FormattedMessage id="dashboard.login" defaultMessage="TODO def" />
  ),
  icon: Analytics,
  navbar: true,
  goBack: true,
  component: Login,
  private: false,
  noPageTemplate: false,
};

export const LoginVendorRoute = {
  id: 'loginVendor',
  order: 0,
  exact: true,
  to: '/loginVendor',
  title: (
    <FormattedMessage id="dashboard.loginVendor" defaultMessage="TODO def" />
  ),
  navbarTitle: (
    <FormattedMessage id="dashboard.loginVendor" defaultMessage="TODO def" />
  ),
  icon: Analytics,
  navbar: false,
  goBack: false,
  component: LoginVendor,
  private: false,
  noPageTemplate: false,
};

export const ConfirmUserRoute = {
  id: 'confirm',
  order: 1,
  exact: true,
  to: '/confirm',
  title: (
    <FormattedMessage id="dashboard.confirmUser" defaultMessage="TODO def" />
  ),
  navbarTitle: (
    <FormattedMessage id="dashboard.confirmUser" defaultMessage="TODO def" />
  ),
  icon: Analytics,
  navbar: true,
  goBack: true,
  component: ConfirmUser,
  private: false,
  noPageTemplate: false,
};

export const ChangePasswordRoute = {
  id: 'changePassword',
  order: 2,
  exact: true,
  to: '/changePassword',
  title: (
    <FormattedMessage id="dashboard.changePassword" defaultMessage="TODO def" />
  ),
  navbarTitle: (
    <FormattedMessage id="dashboard.changePassword" defaultMessage="TODO def" />
  ),
  icon: Analytics,
  navbar: true,
  goBack: true,
  component: ChangePassword,
  private: false,
  noPageTemplate: false,
};

const RouteConsts = [
  HomeRoute,
  LoginRoute,
  LoginVendorRoute,
  ConfirmUserRoute,
  ChangePasswordRoute,
];

export default RouteConsts;
