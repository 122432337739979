import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * react suspense wrapper with default fallback
 * @component
 */
const TuningSuspense = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div>
          <FormattedMessage id="tuning.loading" defaultMessage="TODO def" />
        </div>
      }
    >
      {children}
    </React.Suspense>
  );
};

/** prop types */
TuningSuspense.propTypes = {
  /** children: required */
  children: PropTypes.node.isRequired,
};

export default TuningSuspense;
