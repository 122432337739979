import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import 'styles/main.scss';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';

const ImageDialog = ({ title, open, onClose, children }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="image-dialog"
    >
      <DialogTitle id="alert-dialog-title">
        <IconButton
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            height: 'auto',
          }}
          onClick={onClose}
          aria-label="close"
        >
          <CancelIcon style={{ fill: '#F5942E' }} />
        </IconButton>
        <Typography style={{ color: '#1C1C1C' }} variant="subtitle1">
          <b>{title}</b>
        </Typography>
      </DialogTitle>
      <DialogContent title={title}>{children}</DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
