import React from 'react';
import ReactDOM from 'react-dom';
const myCalledSvgs = [];

const cachedFetch = (src, setSvg) => {
  if (src) {
    if (myCalledSvgs[src]) {
      setSvg(myCalledSvgs[src].svg);
    }
    if (!myCalledSvgs[src]) {
      myCalledSvgs[src] = {};
      fetch(src, { cache: 'no-cache' })
        .then(res => res.text())
        .then(svg => {
          myCalledSvgs[src] = { svg };
          setSvg(svg);
        })
        .catch(() => delete myCalledSvgs[src])
        .catch(() => delete myCalledSvgs[src]);
    }
  }
};

class SvgLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { svg: null, src: false, forFetch: () => ({}) };
  }
  mounted = true;
  afterFetch(svg) {
    if (this.mounted) {
      this.setState(state => ({ ...state, svg }));
    }
  }
  componentDidUpdate() {
    if (this.mounted && ReactDOM.findDOMNode(this).firstElementChild) {
      ReactDOM.findDOMNode(this).firstElementChild.outerHTML = this.state.svg;
      this.props.beforeInjection(ReactDOM.findDOMNode(this).firstElementChild);
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
    const { src, className, style = {} } = this.props;
    let svg = this.state.svg;
    const setSvg = svg => {
      this.afterFetch(svg);
    };
    if (this.state.src !== src) {
      svg = null;
      cachedFetch(src, setSvg);
      this.setState(state => ({ ...state, src }));
    }
    return (
      <div
        className={className}
        style={style}
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    );
  }
}

export default SvgLoader;
