import { gql } from '@apollo/client';
import { pollInterval } from 'client/ClientConstants';
import { GET_FEATURES, GET_FEATURES_OPTIONS } from './modules/Feature';
import { CREATE_QUOTE_MUTATION, CREATE_QUOTE_OPTIONS } from './modules/Quote';

const APPARTAMENT_TYPE = `
    id
    name
    floor
    sides {
      name
    }
    building {
      name
    }
    code
    order
    plan {
      name
      bathroom
      bedroom
      SuperficieAppartamento
      SuperficieLoggia
      typology{
        name
        rooms
      }
    }
    price
    discounted_price
    enabled_web
    enabled_desk
    available
    active
    interests
    status
    updatedOn
    visits
    forRent
    standardQuote {
      daysofexpired
      features {
        id
        available
        code
        floor
        length
        meters
        name
        notes
        price
        status
        type
        visible
        width
      }
      boxes {
        box
        price
        meters
        floor
      }
      basements {
        basement
        price
        meters
        floor
      }
      parkingSpace_bykes {
        parkingSpace_byke
        price
        meters
        floor
      }
      parkingSpace_cars {
        parkingSpace_car
        price
        meters
        floor
      }
      promos {
        promo
        percentage
        name
        info
        price
        endDate
        notes
        class
      }
      payments {
        name
        endDate
        price
        percentage
        daysForPayment
        notes
        type
      }
      expenses {
        name
        price
        percentage
        emptyLabel
        type
      }
      iva {
        name
        percentage
      }
      
      promoPrice
      fixedRate {
        percentage
        value
      }
      variableRate {
        percentage
        value
      }
      rate
    }
`;

const APPARTAMENT_TYPE_TO_RETRIVE = `
    id
    name
    visits
    rooms
    __typename
`;

const APPARTAMENT_PLAN_TO_RETRIVE = `
    id
    name
    visits
    typology {
      id
      name
    }
`;
const VENDOR_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      title
      avatar
      project_names
      session_id
`;
const PROJECT_DATA_TO_RETRIEVE = `
      id
      name
      logo
      vendorLogo
      assetLogin
      hostKey
      assetKey
      feVendorKey
      pdf
      assetsUrl
      displayName
      enabled_analytics
      pageTitles {
        floorPlanning
      }
      myhomeConfig
      floorPlanningConfig
`;
const USER_DATA_TO_RETRIEVE = `
  id
  firstName
  lastName
  email
  project_ids
  language
  role
  createdOn
  client
`;
const CLIENT_DATA_TO_RETRIEVE = `
    id
    firstName
    lastName
    email
    user
    interested_appartments {
      appartment
    }
    selected_appartments {
      appartment
      status
    }
`;
const GET_APPARTMENTS_PLAN = gql`
  query getAllPlans ($project_id :ID!) {
    getAllPlans (project_id :$project_id) {
      ${APPARTAMENT_PLAN_TO_RETRIVE}
    }
  }`;

const GET_APPARTMENTS_TYPE = gql`
  query getAllTypologies ($project_id :ID!) {
    getAllTypologies (project_id :$project_id) {
      ${APPARTAMENT_TYPE_TO_RETRIVE}
    }
  }`;

const LOGIN = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProject(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
      }
      user {
        ${USER_DATA_TO_RETRIEVE}
      }
    }
  }
`;

const LOGIN_DEFAULT_OPTIONS = (email, password, project_id) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
    },
  };
};

const LOGIN_VENDOR_AS_CLIENT = gql`
  query loginVendorAsClient($project_id: ID!, $email: String!, $vendorId: ID!) {
    loginVendorAsClient(project_id: $project_id, email: $email, vendorId: $vendorId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const LOGIN_VENDOR_AS_CLIENT_DEFAULT_OPTIONS = (
  project_id,
  email,
  vendorId
) => {
  return {
    variables: { project_id, email, vendorId },
  };
};

const CONFIRM_USER_PASSWORD = gql`
  mutation confirmUserAndChangePassword(
    $project_id: ID!
    $token: String!
    $password: String!
    $newPassword: String!
  ) {
    confirmUserAndChangePassword(
      project_id: $project_id
      token: $token
      password: $password
      newPassword: $newPassword
    )
  }
`;

const CONFIRM_USER_PASSWORD_DEFAULT_OPTIONS = (
  project_id,
  token,
  password,
  newPassword
) => {
  return {
    variables: {
      token,
      password,
      newPassword,
      project_id,
    },
  };
};

const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      tokenType
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

const REFRESH_TOKEN_DEFAULT_OPTIONS = (refreshToken, email) => {
  return {
    variables: {
      input: {
        refreshToken,
        email,
      },
    },
  };
};

const CHECK_CLIENT_INFO = gql`
  mutation checkClientInfo($project_id: ID!, $email: String!) {
    checkClientInfo(project_id: $project_id, email: $email) {
      firstName
      status
    }
  }
`;

const CHECK_CLIENT_INFO_DEFAULT_OPTIONS = (project_id, email) => {
  return {
    variables: {
      project_id,
      email,
    },
  };
};

const ASK_CHANGE_PASSWORD = gql`
  mutation askChangePassword(
    $project_id: ID!
    $email: String!
    $template_type: String
  ) {
    askChangePassword(
      project_id: $project_id
      email: $email
      template_type: $template_type
    )
  }
`;

const ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS = (project_id, email) => {
  return {
    variables: {
      email,
      project_id,
      template_type: 'changePasswordSalesOnline',
    },
  };
};

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $project_id: ID!
    $token: String!
    $password: String!
  ) {
    changePassword(project_id: $project_id, token: $token, password: $password)
  }
`;

const CHANGE_PASSWORD_DEFAULT_OPTIONS = (project_id, token, password) => {
  return {
    variables: {
      token,
      password,
      project_id,
    },
  };
};

const CREATE_CLIENT_AND_USER = gql`
  mutation createClientAndUser($project_id: ID!, $input: ClientUserInput!) {
    createClientAndUser(project_id: $project_id, input: $input)
  }
`;

const CREATE_CLIENT_AND_USER_DEFAULT_OPTIONS = (
  project_id,
  email,
  firstName,
  lastName,
  tel,
  trattamento,
  profilazione,
  marketing
) => {
  return {
    variables: {
      project_id,
      input: {
        firstName,
        lastName,
        tel,
        email,
        trattamento,
        profilazione,
        marketing,
      },
    },
  };
};

const GET_VENDOR = gql`
  query getVendorByEmail($email :String!) {
    getVendorByEmail(email: $email) {
      ${VENDOR_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host :String!) {
    getProjectInfoByHost (host :$host) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = hostname => {
  return {
    variables: {
      host: hostname,
    },
  };
};

const GET_ALL_APPARTMENTS = gql`
query getAllAppartmentsPublic ($project_id: ID!) {
  getAllAppartmentsPublic (project_id :$project_id) {
    ${APPARTAMENT_TYPE}
  }
}`;

const GET_ALL_APPARTMENTS_DEFAULT_OPTIONS = pId => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
  };
};

const UPDATE_USER = gql`
  mutation updateUsers( $id: ID!, $input: UserInput!) {
    updateUsers( id: $id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_USER_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};

const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = pId => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

export const GET_CLIENT = gql`
    query getClient($project_id :ID!, $id: ID!) {
        getClient(project_id: $project_id, id: $id) {
        ${CLIENT_DATA_TO_RETRIEVE}
        }
    }
`;

export const GET_CLIENT_DEFAULT_OPTIONS = (project_id, id) => {
  return {
    variables: {
      project_id,
      id,
    },
    fetchPolicy: 'network-only',
  };
};

export const SET_CLIENT_INTEREST = gql`
  mutation setClientInterest(
    $project_id: ID!
    $clientId: ID!
    $appartmentId: ID!
    $vendorId: ID!
  ) {
    setClientInterest(
      project_id: $project_id
      clientId: $clientId
      appartmentId: $appartmentId
      vendorId: $vendorId
    ) {
      interested_appartments {
        appartment
      }
    }
  }
`;

export const SET_CLIENT_INTEREST_OPTIONS = (
  project_id,
  clientId,
  appartmentId,
  vendorId
) => {
  return {
    variables: {
      project_id,
      clientId,
      appartmentId,
      vendorId,
    },
  };
};

const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const Client = {
  GET_APPARTMENTS_PLAN,
  GET_APPARTMENTS_TYPE,
  GET_PROJECT_INFO,
  GET_ALL_APPARTMENTS,
  GET_ALL_APPARTMENTS_DEFAULT_OPTIONS,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  LOGIN,
  LOGIN_DEFAULT_OPTIONS,
  LOGIN_VENDOR_AS_CLIENT,
  LOGIN_VENDOR_AS_CLIENT_DEFAULT_OPTIONS,
  LOGOUT,
  CONFIRM_USER_PASSWORD,
  CONFIRM_USER_PASSWORD_DEFAULT_OPTIONS,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  CHECK_CLIENT_INFO,
  CHECK_CLIENT_INFO_DEFAULT_OPTIONS,
  ASK_CHANGE_PASSWORD,
  ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_DEFAULT_OPTIONS,
  CREATE_CLIENT_AND_USER,
  CREATE_CLIENT_AND_USER_DEFAULT_OPTIONS,
  GET_FEATURES,
  GET_FEATURES_OPTIONS,
  CREATE_QUOTE_MUTATION,
  CREATE_QUOTE_OPTIONS,
  GET_CLIENT,
  GET_CLIENT_DEFAULT_OPTIONS,
  SET_CLIENT_INTEREST,
  SET_CLIENT_INTEREST_OPTIONS,
};

export default Client;
