import { gql } from '@apollo/client';
const QUOTE_DATA_TO_RETRIEVE = `
  id  
  errorMessage
  client {
    id
  }
  vendor
  appartment {
    name
    id
    floor
    price
    discounted_price
    available
    active
    code
    enabled_web
    enabled_desk
    status
    extraInfo
    selectedBy
    {
      id
      client {
        id
      }
    }
    plan
    {
    name
    id
    extraInfo
    model {
        id
        name
        visits
    }
    typology {
        id
        name
        visits
        rooms
        formulas {
          id
          increase
          name: label
          shortDescription
          description: longDescription
        }
    }
    SuperficieGiardino
    SuperficieTotale
    SuperficieTerrazzo
    SuperficieAppartamento
    visits
    image
    }
    sides {
      id
      name
      building {
        id
        name
        floors
      }
    }
    standardQuote {
      features {
        id
        available
        code
        floor
        length
        meters
        name
        notes
        price
        status
        type
        visible
        width
      }
      boxes {
        box
        price
        meters
        floor
      }
      basements {
        basement
        price
        meters
        floor
      }
      parkingSpace_bykes {
        parkingSpace_byke
        price
        meters
        floor
      }
      parkingSpace_cars {
        parkingSpace_car
        price
        meters
        floor
      }
      promos {
        promo
        percentage
        name
        info
        class
        price
        endDate
        notes
      }
      payments {
        name
        endDate
        price
        percentage
        daysForPayment
        notes
      }
      expenses {
        name
        price
        percentage
        emptyLabel
        type
      }

      iva {
        name
        percentage
      }
      
      promoPrice
      fixedRate {
        percentage
        value
      }
      variableRate {
        percentage
        value
      }
      rate
    }
  }
  reserved
  customQuote 
  {
    features {
      id
      available
      code
      floor
      length
      meters
      name
      notes
      price
      status
      type
      visible
      width
    }
    capitolato {
      name
      price
    }
    boxes {
      box
      price
      meters
      floor
    }
    basements {
      basement
      price
      meters
      floor
    }
    parkingSpace_bykes {
      parkingSpace_byke
      price
      meters
      floor
    }
    parkingSpace_cars {
      parkingSpace_car
      price
      meters
      floor
    }
    promos {
      promo
      percentage
      name
      info
      class
      price
      endDate
      notes
    }
    payments {
      name
      endDate
      price
      percentage
      daysForPayment
      notes
      type
    }
    expenses {
      name
      price
      percentage
      emptyLabel
      type
    }
    
    promoPrice
    fixedRate {
      percentage
      value
    }
    variableRate {
      percentage
      value
    }
    rate
    freeNotes
    buildingName
  }
`;
export const GET_QUOTE_BY_CLIENT = gql`
  query getQuotesByClient($project_id :ID!, $client: ID!) {
      getQuotesByClient(project_id: $project_id, client: $client) {
      ${QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;
export const GET_QUOTE = gql`
  query getQuote($project_id :ID!, $id: ID!) {
    getQuote(project_id: $project_id, id: $id) {
      ${QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;
export const CREATE_QUOTE_MUTATION = gql`
mutation createQuotes($project_id :ID!, $quoteInput: QuoteInput!) {
  createQuotes(project_id: $project_id, input: $quoteInput) {
        ${QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;
export const UPDATE_QUOTE_MUTATION = gql`
mutation updateQuotes($project_id :ID!, $quoteId: ID!, $quoteInput: QuoteInput!) {
  updateQuotes(project_id: $project_id, id: $quoteId, input: $quoteInput) {
        ${QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;
export const SEND_QUOTE_MUTATION = gql`
  mutation sendQuote($project_id: ID!, $quoteInput: QuoteInput!) {
    sendQuote(project_id: $project_id, input: $quoteInput)
  }
`;
export const GET_QUOTE_BY_CLIENT_OPTIONS = (project_id, client) => {
  return {
    variables: {
      project_id,
      client,
    },
    fetchPolicy: 'network-only',
  };
};
export const GET_QUOTE_OPTIONS = (project_id, id) => {
  return {
    variables: {
      project_id,
      id,
    },
    fetchPolicy: 'network-only',
  };
};
export const CREATE_QUOTE_OPTIONS = (project_id, quoteInput) => {
  return {
    variables: {
      project_id,
      quoteInput,
    },
    fetchPolicy: 'no-cache',
  };
};
export const UPDATE_QUOTE_OPTIONS = (project_id, quoteId, quoteInput) => {
  return {
    variables: {
      project_id,
      quoteId,
      quoteInput,
    },
    fetchPolicy: 'no-cache',
  };
};
