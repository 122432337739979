import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import viste from '../../constants/Viste';
import { useStore } from 'store/storeUtils';
import Slider from './Slider';
import ReactSVG from 'components/common/SvgLoader.jsx';
import ImageDialog from './ImageDialog';
import ScrollArea from 'react-scrollbar';
import ImagesDisclaimer from './ImagesDisclaimer';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 'max-content',
  },
  button: {
    display: 'flex',
    marginTop: 15,
  },
  section1: {
    paddingBottom: 80,
  },
  section3: {
    marginBottom: 10,
  },
}));

const SummarySelect = observer(props => {
  const { matchesPhone, boxes, selectedAppartment, selectedBox } = props;

  const classes = useStyles();
  const [selectedVista, setSelectedVista] = useState({
    id: '0',
    name: 'GALLERY',
  });
  const [openGallery, setOpenGallery] = useState(false);
  const [openPlanimetria, setOpenPlanimetria] = useState(false);
  const [openBox, setOpenBox] = useState(false);

  const store = useStore();

  const immaginiGallery = [];

  for (let i = 1; i < 5; i++) {
    immaginiGallery.push(
      `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/gallery/${i}.jpg`
    );
  }

  const svgPostoAuto = `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/svg/posto_auto.svg`;

  const handleClickGallery = () => {
    setOpenGallery(true);
  };

  const handleCloseGallery = () => {
    setOpenGallery(false);
  };

  const handleClickPlanimetria = () => {
    setOpenPlanimetria(true);
  };

  const handleClosePlanimetria = () => {
    setOpenPlanimetria(false);
  };

  const handleClickBox = () => {
    setOpenBox(true);
  };

  const handleCloseBox = () => {
    setOpenBox(false);
  };

  const handleClickZoom = vista => {
    if (vista === '0') {
      handleClickGallery();
    } else if (vista === '1') {
      handleClickPlanimetria();
    } else {
      handleClickBox();
    }
  };

  const handleClick = vista => {
    setSelectedVista(viste.find(item => item.id === vista.id));
  };

  if (!selectedAppartment || !selectedBox) {
    return <></>;
  }

  return (
    <>
      {!matchesPhone ? (
        <Grid
          item
          container
          xs={12}
          lg={7}
          className="summary-select-grid-item"
        >
          <Grid
            item
            xs={12}
            style={{
              marginLeft: 85,
              marginTop: 20,
              marginRight: 30,
              width: 'calc(100vw - 115px)',
            }}
          >
            <div className={classes.root}>
              <div className={classes.section1}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="h4">
                      <b>4</b>&nbsp;{' '}
                      <thin style={{ fontWeight: '300' }}>|</thin>
                      &thinsp; La tua <b>scelta</b>
                    </Typography>
                    <Typography style={{ marginTop: 12 }} variant="subtitle1">
                      L'appartamento che hai <b>configurato</b>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.section2}>
                <Typography style={{ marginBottom: 10 }} variant="subtitle2">
                  VISUALIZZA LE IMMAGINI DELLA TUA SCELTA
                </Typography>
                <Divider style={{ marginRight: 45 }} />
                <ScrollArea className="scroll-orizzontale-riepilogo">
                  <div className={classes.button}>
                    {viste.map(item => (
                      <Button
                        style={{ minWidth: 200 }}
                        className={
                          'appartment-button' +
                          (selectedVista && selectedVista.id === item.id
                            ? ' selected-appartment'
                            : '')
                        }
                        onClick={() => handleClick(item)}
                        variant="outlined"
                        key={item.id}
                      >
                        <b>{item.name}</b>
                      </Button>
                    ))}
                  </div>
                </ScrollArea>
              </div>
              <div className={classes.section3}>
                <Typography style={{ color: '#000000' }} variant="subtitle2">
                  <img src="/icon/icon-clicca.svg" /> <b id="pulsa">Clicca</b>{' '}
                  quello che vuoi vedere
                </Typography>
              </div>
            </div>
            <div className="zoom-container">
              <IconButton
                onClick={() => handleClickZoom(selectedVista.id)}
                className="icon-zoom-summary"
              >
                <img src="/icon/Raggruppa 21026.svg" />
              </IconButton>
              {/* GALLERY */}
              <ImageDialog
                open={openGallery}
                onClose={handleCloseGallery}
                title="GALLERY"
              >
                <Slider imgs={immaginiGallery} />
                <div style={{ position: 'relative' }}>
                  <ImagesDisclaimer className="modal-image-disclaimer" />
                </div>
              </ImageDialog>
              {/* PLANIMETRIA */}
              <ImageDialog
                open={openPlanimetria}
                onClose={handleClosePlanimetria}
                title="PLANIMETRIA"
              >
                <Slider
                  imgClassName="fit-contain"
                  imgs={[
                    `${
                      process.env.REACT_APP_BUCKET_BASEURL
                    }initiatives/${encodeURI(
                      store.loginProjectName
                    )}/floorplanning/img/planimetrie_cartigliate/${selectedAppartment.name.replaceAll(
                      ' ',
                      '.'
                    )}.jpg`,
                  ]}
                />
              </ImageDialog>
              {/* BOX */}
              <ImageDialog
                open={openBox}
                onClose={handleCloseBox}
                title="POSTO AUTO E CANTINA"
              >
                <ReactSVG
                  className="box-svg-container"
                  style={{ width: '100%', height: '100%' }}
                  src={svgPostoAuto}
                  renumerateIRIElements={false}
                  beforeInjection={actualSvg => {
                    if (actualSvg) {
                      boxes.forEach(box => {
                        if (box.available) {
                          let seleziona = actualSvg.querySelector(
                            `g[id="_Seleziona-${box._label.replaceAll(
                              ' ',
                              ''
                            )}_" i]`
                          );
                          if (selectedBox && selectedBox.id === box.id) {
                            seleziona = actualSvg.querySelector(
                              `g[id="_Selezionato-${box._label.replaceAll(
                                ' ',
                                ''
                              )}_" i]`
                            );
                          }
                          if (seleziona) {
                            seleziona.style.display = 'block';
                          }
                        }
                      });
                      actualSvg.setAttribute(
                        'preserveAspectRatio',
                        'xMidYMid slice'
                      );
                    }
                  }}
                  wrapper="div"
                />
              </ImageDialog>
            </div>
            <Card className="vista-container">
              {selectedVista.id === '0' && (
                <>
                  <Slider imgs={immaginiGallery} />
                  <div className="summary-image-disclaimer-container">
                    <ImagesDisclaimer className="summary-image-disclaimer" />
                  </div>
                </>
              )}
              {selectedVista.id === '1' && (
                <img
                  className="selected-appartment-plan-image"
                  src={`${
                    process.env.REACT_APP_BUCKET_BASEURL
                  }initiatives/${encodeURI(
                    store.loginProjectName
                  )}/floorplanning/img/planimetrie/${
                    selectedAppartment.plan.name
                  }.png`}
                />
              )}
              {selectedVista.id === '2' && (
                <ReactSVG
                  className="box-svg-container"
                  style={{ width: '100%', height: '100%' }}
                  src={svgPostoAuto}
                  renumerateIRIElements={false}
                  beforeInjection={actualSvg => {
                    if (actualSvg) {
                      boxes.forEach(box => {
                        if (box.available) {
                          let seleziona = actualSvg.querySelector(
                            `g[id="_Seleziona-${box._label.replaceAll(
                              ' ',
                              ''
                            )}_" i]`
                          );
                          if (selectedBox && selectedBox.id === box.id) {
                            seleziona = actualSvg.querySelector(
                              `g[id="_Selezionato-${box._label.replaceAll(
                                ' ',
                                ''
                              )}_" i]`
                            );
                          }
                          if (seleziona) {
                            seleziona.style.display = 'block';
                          }
                        }
                      });
                      actualSvg.setAttribute(
                        'preserveAspectRatio',
                        'xMidYMid slice'
                      );
                    }
                  }}
                  wrapper="div"
                />
              )}
            </Card>
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            paddingTop: 30,
            width: '100%',
          }}
          className="summary-select-grid-item"
        >
          <div style={{ paddingLeft: 18 }}>
            <Typography style={{ color: '#481635' }} variant="caption">
              <b>4</b> | La tua <b>scelta</b>
            </Typography>
            <Typography
              style={{ color: '#705466', fontSize: 10 }}
              variant="subtitle1"
            >
              L'appartamento che hai <b>configurato</b>
            </Typography>
            <ScrollArea className="scroll-orizzontale-riepilogo">
              <div className={classes.button}>
                {viste.map(item => (
                  <Button
                    style={{
                      minWidth: 160,
                      height: 25,
                      marginBlock: 30,
                      fontSize: 10,
                    }}
                    className={
                      'appartment-button' +
                      (selectedVista && selectedVista.id === item.id
                        ? ' selected-appartment'
                        : '')
                    }
                    onClick={() => handleClick(item)}
                    variant="outlined"
                    key={item.id}
                  >
                    <b>{item.name}</b>
                  </Button>
                ))}
              </div>
            </ScrollArea>
          </div>
          <div className="zoom-container">
            <IconButton
              onClick={() => handleClickZoom(selectedVista.id)}
              className="icon-zoom-summary"
            >
              <img src="/icon/Raggruppa 21026.svg" />
            </IconButton>
            {/* GALLERY */}
            <ImageDialog
              open={openGallery}
              onClose={handleCloseGallery}
              title="GALLERY"
            >
              <Slider imgs={immaginiGallery} />
              <div style={{ position: 'relative' }}>
                <ImagesDisclaimer className="modal-image-disclaimer" />
              </div>
            </ImageDialog>
            {/* PLANIMETRIA */}
            <ImageDialog
              open={openPlanimetria}
              onClose={handleClosePlanimetria}
              title="PLANIMETRIA"
            >
              <Slider
                imgClassName="fit-contain"
                imgs={[
                  `${
                    process.env.REACT_APP_BUCKET_BASEURL
                  }initiatives/${encodeURI(
                    store.loginProjectName
                  )}/floorplanning/img/planimetrie_cartigliate/${selectedAppartment.name.replaceAll(
                    ' ',
                    '.'
                  )}.jpg`,
                ]}
              />
            </ImageDialog>
            {/* BOX */}
            <ImageDialog
              open={openBox}
              onClose={handleCloseBox}
              title="POSTO AUTO E CANTINA"
            >
              <ReactSVG
                className="box-svg-container"
                style={{ width: '100%', height: '100%' }}
                src={svgPostoAuto}
                renumerateIRIElements={false}
                beforeInjection={actualSvg => {
                  if (actualSvg) {
                    boxes.forEach(box => {
                      if (box.available) {
                        let seleziona = actualSvg.querySelector(
                          `g[id="_Seleziona-${box._label.replaceAll(
                            ' ',
                            ''
                          )}_" i]`
                        );
                        if (selectedBox && selectedBox.id === box.id) {
                          seleziona = actualSvg.querySelector(
                            `g[id="_Selezionato-${box._label.replaceAll(
                              ' ',
                              ''
                            )}_" i]`
                          );
                        }
                        if (seleziona) {
                          seleziona.style.display = 'block';
                        }
                      }
                    });
                    actualSvg.setAttribute(
                      'preserveAspectRatio',
                      'xMidYMid slice'
                    );
                  }
                }}
                wrapper="div"
              />
            </ImageDialog>
          </div>
          <Card className="vista-container">
            {selectedVista.id === '0' && (
              <>
                <Slider title={'GALLERY'} imgs={immaginiGallery} />
                <div className="summary-image-disclaimer-container">
                  <ImagesDisclaimer className="summary-image-disclaimer" />
                </div>
              </>
            )}
            {selectedVista.id === '1' && (
              <img
                className="selected-appartment-plan-image"
                src={`${
                  process.env.REACT_APP_BUCKET_BASEURL
                }initiatives/${encodeURI(
                  store.loginProjectName
                )}/floorplanning/img/planimetrie/${
                  selectedAppartment.plan.name
                }.png`}
              />
            )}
            {selectedVista.id === '2' && (
              <ReactSVG
                className="box-svg-container"
                style={{ width: '100%', height: '100%' }}
                src={svgPostoAuto}
                renumerateIRIElements={false}
                beforeInjection={actualSvg => {
                  if (actualSvg) {
                    boxes.forEach(box => {
                      const svgBoxId = box._label.replace(' ', '');
                      if (box.available) {
                        let seleziona = actualSvg.querySelector(
                          `g[id="_Seleziona-${svgBoxId}_" i]`
                        );
                        if (selectedBox && selectedBox.id === box.id) {
                          seleziona = actualSvg.querySelector(
                            `g[id="_Selezionato-${svgBoxId}_" i]`
                          );
                        }
                        if (seleziona) {
                          seleziona.style.display = 'block';
                        }
                      }
                    });
                    actualSvg.setAttribute(
                      'preserveAspectRatio',
                      'xMidYMid slice'
                    );
                  }
                }}
                wrapper="div"
              />
            )}
          </Card>
        </div>
      )}
    </>
  );
});

export default SummarySelect;
