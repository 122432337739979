import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PageTemplate from 'pages/PageTemplate';
import PropTypes from 'prop-types';
import TuningSnackbarFeedback from 'components/common/TuningSnackbarFeedback';
import TuningSnackbarError from 'components/common/TuningSnackbarError';
import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';
import Client from 'client/Client';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Login from 'components/salesOnline/Login';

const PrivateRoute = observer(props => {
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const intl = useIntl();

  const store = useStore();

  const setLanguage = lang => {
    const updateData = { ...store.loggedUser };
    delete updateData.id;
    delete updateData.createdOn;
    delete updateData.__typename;
    updateUser(
      Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser.id, updateData)
    )
      .then(() =>
        store.setSnackbar(
          true,
          intl.formatMessage({
            id: 'snackbar.successOperation',
            defaultMessage: 'Operation succesfully completed',
          })
        )
      )
      .catch(() =>
        store.setSnackbarError(
          true,
          intl.formatMessage({
            id: 'snackbar.failedOperation',
            defaultMessage: 'Operation failed',
          })
        )
      );
    store.setLoggedUserLang(lang);
  };

  const { exact, path, ToRender, title, goBack, navbar } = props;

  return (
    <>
      <TuningSnackbarFeedback />
      <TuningSnackbarError />
      <Route
        exact={exact}
        path={path}
        navbar={navbar}
        render={props => {
          return store.loggedToken ? (
            <>
              <PageTemplate
                BodyComponent={ToRender}
                title={title}
                goBack={goBack}
                setLanguage={setLanguage}
                {...props}
              ></PageTemplate>
            </>
          ) : (
            <Login open={true} />
          );
        }}
      />
    </>
  );
});

PrivateRoute.propTypes = {
  id: PropTypes.string,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  ToRender: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  goBack: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  exact: false,
  goBack: false,
};

export default PrivateRoute;
