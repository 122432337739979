import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import Store from 'store/store.js';
import { StoreProvider } from 'store/storeUtils.js';
import { create } from 'mobx-persist';
import 'styles/main.scss';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CrmMUITheme } from 'styles/crmMUITheme';
import { ApolloProvider } from '@apollo/client';
import API from 'client/GraphqlClient';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { IntlProvider, FormattedMessage } from 'react-intl';

const theme = createMuiTheme(CrmMUITheme);

const hydrate = create({});

const store = new Store();

hydrate('store_salesonline', store)
  .catch(e => {
    console.log(e);
    delete localStorage['store_salesonline'];
    const newStore = new Store();
    hydrate('store_salesonline', newStore);
  })
  .finally(() => {
    document.title =
      store && store.pageTitles && store.pageTitles.salesonline
        ? store.pageTitles.salesonline
        : 'Sales Online';

    ReactDOM.render(
      <IntlProvider defaultLocale="it">
        <StoreProvider value={store}>
          <ApolloProvider client={API(store)}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <App />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </ApolloProvider>
        </StoreProvider>
      </IntlProvider>,
      document.getElementById('root')
    );
  });
