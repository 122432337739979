/**
 * a generic function to capitalize strings
 * @param {string} string what must be capitalized
 */
const Capitalize = string => {
  if (!string) return undefined;
  return string.replace(/^\w/, c => c.toUpperCase());
};

export default Capitalize;
