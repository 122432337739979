import CookiesUtils from './cookieUtils';

const LogOut = (store, onlyLogin) => {
  const reload = !!store.loggedUser && store.loggedUser.logged;
  console.log(reload);
  store.logOut(onlyLogin);
  try {
    // Elimino i cookie con JWT e vendorID
    CookiesUtils.logOut();
  } catch (e) {
    console.log(e);
  }
  if (reload) {
    window.location.reload();
  }
};

export default LogOut;
