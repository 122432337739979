import React from 'react';
import TuningSnackbar from 'components/common/TuningSnackbar';
import Button from 'components/common/Button';
import * as serviceWorker from 'serviceWorker';
import { FormattedMessage } from 'react-intl';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = registration => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    delete localStorage['store_salesonline'];
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }

    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <TuningSnackbar
      open={showReload}
      style={{ color: 'white !important' }}
      message={
        <>
          <FormattedMessage
            id="tuning.newVersion"
            defaultMessage="Nuova Versione"
          />
        </>
      }
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button size="small" onClick={reloadPage}>
          <FormattedMessage id="tuning.update" defaultMessage="Aggiorna" />
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
