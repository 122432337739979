import React from 'react';
import TuningSnackbar from 'components/common/TuningSnackbar';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';

const TuningSnackbarError = observer(() => {
  const store = useStore();

  return (
    <TuningSnackbar
      open={store.snackbarError}
      onClose={() => store.setSnackbarError(false)}
    >
      <div className="snackbar-body-error">{store.snackbarMessage}</div>
    </TuningSnackbar>
  );
});

export default TuningSnackbarError;
