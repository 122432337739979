import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import LogOut from 'utils/LogOut';
import CookiesUtils from 'utils/cookieUtils';

const API = store => {
  const errorLink = onError(error => {
    const { networkError } = error;
    const graphqlError =
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.code;
    if (networkError && networkError.statusCode === 401) {
      LogOut(store, true);
    } else if (graphqlError === 'UNAUTHENTICATED') {
      LogOut(store, true);
    }
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      credentials: 'include',
      uri: process.env.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  return new ApolloClient({
    credentials: 'include',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({}),
  });
};

export default API;
