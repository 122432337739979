import React from 'react';
import { useMutation } from '@apollo/client';
import Client from 'client/Client';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Loader from 'components/common/Loader';
import 'styles/login.scss';
import {
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CookiesUtils from 'utils/cookieUtils';
import { useHistory } from 'react-router';
import SSOComponent from '../components/common/SSOComponent';
import MailIcon from '@material-ui/icons/Mail';
import ValidateEmail from 'utils/ValidateEmail';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const mobileBackground = store => (
  <img
    className="login-mobile-svg"
    src={`${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/login_background_mobile.png`}
  />
);

const ErrorSnackbar = props => {
  const { open, onClose, message, matchesPhone } = props;

  return (
    <Snackbar
      autoHideDuration={matchesPhone ? 3000 : 5000}
      className="error-snackbar"
      open={open}
      onClose={onClose}
    >
      <Alert severity="error" variant="filled" onClose={onClose}>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: matchesPhone ? 'x-small' : 12,
          }}
        >
          <b>{message}</b>
        </Typography>
      </Alert>
    </Snackbar>
  );
};

const LoginContainer = observer(props => {
  const { step, matchesPhone } = props;
  const store = useStore();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const [doLogin] = useMutation(Client.LOGIN);

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  if (store.step || store.step === 0) {
    store.setStep(null);
  }

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    setEmailError(undefined);
  };

  const loginSubmit = e => {
    e.preventDefault();
    if (!ValidateEmail(email)) {
      setEmailError('Formato email non valido');
      return;
    }
    setLoading(true);
    doLogin({
      variables: {
        input: {
          email,
          password,
          project_id: store.projectId,
        },
      },
    })
      .then(res => {
        const { user, token } = res.data.loginByProject;
        if (store.getRole(user.role) === 'vendor') {
          CookiesUtils.setToken(token.accessToken);
          CookiesUtils.setRefreshToken(token.refreshToken);
          CookiesUtils.setTokenExpire(token.expiresIn);
          CookiesUtils.setVendorId(user.id);
          store.setLoggedUser({ ...user, logged: true });
          history.replace('/');
        } else {
          setEmailError("L'utenza non può eseguire l'accesso da vendor");
        }
        setLoading(false);
      })
      .catch(e => {
        if (e.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
          setLoading(false);
          setEmailError(true);
        }
      });
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Loader z loading={loading} />
      {step === '' && (
        <Card elevation={6} className="login-container">
          {!matchesPhone ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src="/icon/Raggruppa 20831.svg" alt="" />
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    margin: '6px 36px',
                    width: 2,
                    backgroundColor: '#481635',
                  }}
                />
                {store.summaryLocation ? (
                  <Typography variant="h4">
                    <b>Salva</b> questa <b>configurazione</b>
                  </Typography>
                ) : (
                  <Typography variant="h4">
                    Configura e <b>prenota</b> <br /> la tua casa <b>online.</b>
                  </Typography>
                )}
              </div>
              {store.summaryLocation ? (
                <Typography style={{ marginTop: 28 }} variant="subtitle1">
                  Potrai valutarla successivamente per decidere se prenotare la
                  tua casa online. <br /> Un modo nuovo, facile e sicuro.
                </Typography>
              ) : (
                <Typography style={{ marginTop: 28 }} variant="subtitle1">
                  Un modo nuovo, facile e sicuro per acquistare casa.
                </Typography>
              )}
              <form id="login-form" onSubmit={loginSubmit}>
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    E-mail*
                  </Typography>
                </label>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon material-email.svg" />
                      </InputAdornment>
                    ),
                  }}
                  id="email-login"
                  variant="outlined"
                  placeholder="e-mail"
                  type="email"
                  className="login-input"
                  value={email}
                  form="login"
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  error={emailError}
                />
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    Password*
                  </Typography>
                </label>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon ionic-md-key.svg" alt="" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          style={{ maxHeight: '100%' }}
                          className="visibility-icon-button"
                        >
                          {showPassword ? (
                            <Visibility fontSize="small" />
                          ) : (
                            <VisibilityOff fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="password-login"
                  variant="outlined"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  className="login-input"
                  value={password}
                  form="login"
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                />
                <Button type="submit" className={'login-button'}>
                  <Typography
                    style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                    variant="subtitle1"
                  >
                    accedi
                  </Typography>
                </Button>
              </form>
            </>
          ) : (
            <>
              {store.summaryLocation ? (
                <div style={{ padding: 20 }}>
                  <Typography variant="h5">
                    <b>Salva</b> questa <b>configurazione</b>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Potrai valutarla successivamente per decidere se prenotare
                    la tua casa online. <br /> Un modo nuovo, facile e sicuro.
                    <br />
                  </Typography>
                </div>
              ) : (
                <div style={{ padding: 20 }}>
                  <Typography variant="h5">
                    Configura e <b>prenota</b>
                    <br /> la tua casa <b>online.</b>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Un modo nuovo, facile e sicuro <br /> per acquistare casa.
                    <br />
                  </Typography>
                </div>
              )}
              <form
                id="login-form"
                style={{
                  padding: 20,
                  width: 'initial',
                  marginTop: 'unset',
                  paddingTop: 'unset',
                }}
                onSubmit={loginSubmit}
              >
                <label>
                  <Typography style={{ color: '#707070' }} variant="body1">
                    E-mail*
                  </Typography>
                </label>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon
                          fontSize="small"
                          style={{ color: 'darkgray' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  id="email-login"
                  variant="outlined"
                  placeholder="e-mail"
                  type="email"
                  className="login-input"
                  value={email}
                  form="login"
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  error={emailError}
                />
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    Password*
                  </Typography>
                </label>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon ionic-md-key.svg" alt="" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          style={{ maxHeight: '100%' }}
                          className="visibility-icon-button"
                        >
                          {showPassword ? (
                            <Visibility fontSize="small" />
                          ) : (
                            <VisibilityOff fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="password-login"
                  variant="outlined"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  className="login-input"
                  value={password}
                  form="login"
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                />
                <Button type="submit" className={'login-button'}>
                  <Typography
                    style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                    variant="subtitle1"
                  >
                    accedi
                  </Typography>
                </Button>
              </form>
              {mobileBackground(store)}
            </>
          )}
          <ErrorSnackbar
            matchesPhone={matchesPhone}
            open={emailError}
            onClose={handleSnackbarClose}
            message={"L'email inserita non è valida"}
          />
        </Card>
      )}
    </Grid>
  );
});

/**
 * Pagina di login
 * @component
 */
const Login = observer(props => {
  const sso = true;
  if (sso) {
    return <SSOComponent />;
  }
  /* const store = useStore();
  const theme = useTheme();
  const [step, setStep] = React.useState('');
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const history = useHistory();
  if (CookiesUtils.getVendorId()) {
    history.replace('/');
  }
  return (
    <>
      <div
        className="login-background-container"
        style={{
          backgroundImage: `url('${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/login_background.jpg')`,
        }}
      />
      <LoginContainer
        step={step}
        setStep={setStep}
        matchesPhone={matchesPhone}
        {...props}
      />
    </>
  ); */
});

export default Login;
