import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store/storeUtils.js';
import appartmentTypes from '../../constants/AppartmentTypes';
import { useParams, useHistory } from 'react-router';
import PlanSelect from './PlanSelect';
import PlanDetails from './PlanDetails';
import clsx from 'clsx';
import ScrollArea from 'react-scrollbar';
import CancelIcon from '@material-ui/icons/Cancel';
import FaqList from './FaqList';
import { getLinkByParams, sortByName } from 'utils/utils';
import RouteConsts from '../../constants/RoutesConst';

const Plan = observer(props => {
  const { navigation } = props;
  const history = useHistory();
  const store = useStore();
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const galleryThumbnailURL = `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/gallery_thumbnail.jpg`;
  const [pageHeight, setPageHeight] = useState(0);

  const updatePageHeight = () => {
    const newPageHeight = document.body.getBoundingClientRect().height;
    if (newPageHeight !== pageHeight) {
      setPageHeight(newPageHeight);
    }
  };

  useEffect(() => {
    setPageHeight(document.body.getBoundingClientRect().height);
    window.addEventListener('resize', updatePageHeight);
    return () => {
      window.removeEventListener('resize', updatePageHeight);
    };
  }, []);

  //logica stepper
  if (store.step !== 1) {
    store.setStep(1);
  }

  const params = useParams();

  const typology = appartmentTypes.find(
    item =>
      item.name.toLowerCase() === navigation.match.params.typology.toLowerCase()
  );

  if (!typology) {
    history.push(
      getLinkByParams(
        params,
        RouteConsts.map(r => r.navigation)[store.step - 1]
      ),
      params
    );
    return <></>;
  }

  function checkTypology(app) {
    return typology.filter(app);
  }
  //sono presenti sia i disponibili che i venduti
  const appartamentiFiltrati = store.allAppartments.filter(checkTypology);

  function checkDisponibili(app) {
    return app.available ? app : null;
  }
  //popola i bottoni
  const appartamentiDisponibili = appartamentiFiltrati.filter(checkDisponibili);
  sortByName(appartamentiDisponibili);

  const [selectedAppartment, setSelectedAppartment] = useState(
    history.location.state &&
      history.location.state.plan &&
      appartamentiDisponibili.find(
        item => item.name === history.location.state.plan
      )
  );

  const handleClick = app => {
    setSelectedAppartment(
      appartamentiDisponibili.find(item => item.id === app.id)
    );
    /*history.replace(history.location.pathname, {
      plan: app.name,
      hideSnackbar: !planSelectSnackbarOpen,
    });*/
    if (matchesPhone) {
      setTimeout(() => {
        handleDrawerOpenSelectedApp();
      }, 250);
    } else {
      handleDrawerOpenSelectedApp();
    }
  };

  //pagina successiva ->  box
  const handleNext = name => {
    history.push(window.location.pathname + '/' + encodeURI(name));
    store.setStep(store.step + 1);
  };

  //GESTISCE DRAWER LA TUA SCELTA
  const [open, setOpen] = useState(!!selectedAppartment);

  //GESTISCE DRAWER INFO
  const [info, setInfo] = useState(false);

  // QUANDO VIENE SELEZIONATO UN APPARTAMENTO APRE IL DRAWER
  const handleDrawerOpenSelectedApp = () => {
    setInfo(false);
    setOpen(true);
  };

  // GESTISCE DRAWER LA TUA SCELTA
  const handleDrawer = () => {
    setOpen(!open);
    if (info) setInfo(!info);
  };

  // GESTISCE DRAWER INFO
  const handleDrawerInfo = () => {
    setInfo(!info);
    if (open) setOpen(!open);
  };

  const noTFilter = arr => arr.filter(a => !a.name.endsWith('T'));

  //logica per Alert
  const soldAppartments =
    noTFilter(store.allAppartments).length -
    noTFilter(store.availableAppartments).length;

  return (
    <>
      {/* CONTAINER PER IL PROSPETTO */}
      <Grid
        container
        className={`grid-container-full-height ${
          !matchesPhone && 'grid-container-with-sidebar'
        }`}
      >
        <PlanSelect
          matchesPhone={matchesPhone}
          soldAppartments={soldAppartments}
          appartamentiFiltrati={appartamentiFiltrati}
          appartamentiDisponibili={appartamentiDisponibili}
          selectedAppartment={selectedAppartment}
          typology={typology}
          handleClick={handleClick}
        />
      </Grid>

      {/* DRAWER PER "LA MIA SCELTA" */}
      {matchesPhone ? (
        <Drawer
          elevation={6}
          className={clsx('drawer', {
            ['drawerOpenMobileScelta planDetailsDrawer']: open,
            ['drawerCloseBottom']: !open,
          })}
          variant="persistent"
          anchor="bottom"
          open={open}
          style={{ position: 'absolute' }}
        >
          {open && (
            <PlanDetails
              handleDrawer={handleDrawer}
              selectedAppartment={selectedAppartment}
              appartamentiDisponibili={appartamentiDisponibili}
              typology={typology}
              handleNext={handleNext}
              matchesPhone={matchesPhone}
              pageHeight={pageHeight}
            />
          )}
        </Drawer>
      ) : (
        <Drawer
          className={clsx('drawer', {
            ['drawerOpen planDetailsDrawer']: open,
            ['drawerClose']: !open,
          })}
          variant="persistent"
          anchor="right"
          open={open}
          style={{ position: 'absolute' }}
        >
          {open && (
            <PlanDetails
              handleDrawer={handleDrawer}
              selectedAppartment={selectedAppartment}
              appartamentiDisponibili={appartamentiDisponibili}
              typology={typology}
              handleNext={handleNext}
              matchesPhone={matchesPhone}
              pageHeight={pageHeight}
            />
          )}
        </Drawer>
      )}

      {/* DRAWER PER "COME FUNZIONA" */}
      {matchesPhone ? (
        <Drawer
          className={clsx('drawer', {
            ['drawerOpenMobile planDetailsDrawer']: info,
            ['drawerCloseBottom']: !info,
          })}
          variant="persistent"
          anchor="bottom"
          open={info}
          style={{ position: 'absolute' }}
        >
          {info && (
            <>
              {' '}
              <div className="mobile-shadow-background" />
              <div className="plan-grid-details">
                <ScrollArea
                  speed={0.4}
                  className="area"
                  contentClassName="content"
                  horizontal={false}
                >
                  <Paper className="mobile-shadow">
                    <Grid style={{ justifyContent: 'space-between' }} container>
                      <Grid item style={{ marginLeft: 30, marginBlock: 25 }}>
                        <Typography variant="caption">
                          Come <b>funziona</b>
                        </Typography>
                      </Grid>
                      <Grid item className="close-icon-container">
                        <IconButton
                          className="close-icon"
                          onClick={handleDrawerInfo}
                        >
                          <CancelIcon style={{ fill: '#F5942E' }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <div style={{ marginInline: 30, marginBottom: 35 }}>
                      <img
                        className="selected-appartment-gallery-thumbnail"
                        src={galleryThumbnailURL}
                        alt=""
                      />
                    </div>
                    <Divider />
                    <FaqList
                      faqList={store.myhomeConfig.salesOnlineFaq}
                      style={{ paddingBottom: 150 }}
                    />
                  </Paper>
                </ScrollArea>
              </div>
            </>
          )}
        </Drawer>
      ) : (
        <Drawer
          className={clsx('drawer', {
            ['drawerOpen planDetailsDrawer']: info,
            ['drawerClose']: !info,
          })}
          variant="persistent"
          anchor="right"
          open={info}
          style={{ position: 'absolute' }}
        >
          <ScrollArea
            speed={0.4}
            className="area"
            contentClassName="content"
            horizontal={false}
          >
            {info && (
              <>
                <Grid style={{ justifyContent: 'space-between' }} container>
                  <Grid
                    item
                    style={{ marginTop: 30, marginLeft: 30, marginBottom: 35 }}
                  >
                    <Typography variant="h4">
                      Come <b>funziona</b>
                    </Typography>
                  </Grid>
                </Grid>
                <div style={{ marginInline: 30, marginBottom: 35 }}>
                  <img
                    className="selected-appartment-gallery-thumbnail"
                    src={galleryThumbnailURL}
                    alt=""
                  />
                  <IconButton
                    style={{ position: 'fixed', right: 110, top: 183 }}
                    onClick={handleDrawerInfo}
                  >
                    <img src="/icon/Tracciato 32881.svg" alt="" />
                  </IconButton>
                </div>
                <Divider />
                <FaqList faqList={store.myhomeConfig.salesOnlineFaq} />
              </>
            )}
          </ScrollArea>
        </Drawer>
      )}

      {/* DRAWER FISSO (colonna destra) PER APRIRE E CHIUDERE I DRAWERS */}
      {matchesPhone ? (
        <Drawer
          className={clsx('drawerBottom', {
            ['drawerOpenBottom']: !info && !open,
            ['drawerCloseBottom']: info || open, //si nasconde quando si apre un drawer
          })}
          variant="persistent"
          anchor="bottom"
          open={info || open}
        >
          <IconButton
            style={{ height: 80, width: 100, padding: 'unset' }}
            onClick={handleDrawer}
          >
            <img src="/icon/Raggruppa 21024.svg" />
            <Typography
              style={{ textAlign: 'center', fontSize: 10 }}
              variant="subtitle1"
            >
              LA MIA <b>SCELTA</b>
            </Typography>
          </IconButton>
          <Divider
            style={{ height: 'unset', backgroundColor: 'white', width: 1 }}
          />
          <IconButton
            style={{ height: 80, width: 100, padding: 'unset' }}
            onClick={handleDrawerInfo}
          >
            <img src="/icon/Raggruppa 21025.svg" />
            <Typography
              style={{ textAlign: 'center', fontSize: 10, fontWeight: 'bold' }}
              variant="subtitle1"
            >
              FAQ
            </Typography>
          </IconButton>
        </Drawer>
      ) : (
        <Drawer
          className={clsx('drawer', {
            ['drawerOpenColonna']: !info && !open, //bordo sx arancione
            ['drawerCloseColonna']: info || open, //no bordo
          })}
          variant="persistent"
          anchor="right"
          open={info || open}
        >
          <IconButton className="info" onClick={handleDrawer}>
            <img src="/icon/Raggruppa 21024.svg" />
            <Typography style={{ textAlign: 'center' }} variant="body1">
              LA MIA <b>SCELTA</b>
            </Typography>
          </IconButton>
          <Divider style={{ height: '2px', backgroundColor: 'white' }} />
          <IconButton
            style={{ padding: 'unset' }}
            className="info"
            onClick={handleDrawerInfo}
          >
            <img src="/icon/Raggruppa 21025.svg" />
            <Typography
              style={{ textAlign: 'center', fontWeight: 'bold' }}
              variant="body1"
            >
              FAQ
            </Typography>
          </IconButton>
          <Divider style={{ height: '2px', backgroundColor: 'white' }} />
        </Drawer>
      )}
    </>
  );
});

export default Plan;
