/**
 * a number formatter for € currency
 */
const formatterWithCurrencySymbol = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export const formatter = new Intl.NumberFormat('it-IT', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export default formatterWithCurrencySymbol;
