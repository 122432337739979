import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';

const FaqList = ({ faqList, style }) => {
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const [state, setState] = useState({
    category: faqList.map(category => {
      return { open: false, questionOpen: category.faq.map(() => false) };
    }),
  });

  const handleCategoryClick = categoryIndex => {
    const newState = _.cloneDeep(state);
    newState.category[categoryIndex].open = !state.category[categoryIndex].open;
    setState(newState);
  };

  const handleQuestionClick = (categoryIndex, questionIndex) => {
    const newState = _.cloneDeep(state);
    newState.category[categoryIndex].questionOpen[questionIndex] =
      !state.category[categoryIndex].questionOpen[questionIndex];
    setState(newState);
  };

  return (
    <List style={style}>
      {faqList.map((faqObj, i) => (
        <div key={i}>
          <ListItem button onClick={() => handleCategoryClick(i)} style={{marginBottom: '1px'}}>
            <ListItemText primary={faqObj.category} />
            {state.category[i].open ? (
              <ExpandLess fontSize="large" style={{ color: '#F5942E' }} />
            ) : (
              <ExpandMore fontSize="large" style={{ color: '#F5942E' }} />
            )}
          </ListItem>
          {faqObj.faq.map((faq, j) => {
            return (
              <div key={j}>
                <Collapse
                  in={state.category[i].open}
                  timeout="auto"
                  unmountOnExit
                >
                  <ListItem button onClick={() => handleQuestionClick(i, j)} style={{marginBottom: '1px', background: 'oldlace'}}>
                    <ListItemText primary={faq.question} />
                    {state.category[i].questionOpen[j] ? (
                      <ExpandLess
                        fontSize="large"
                        style={{ color: '#F5942E' }}
                      />
                    ) : (
                      <ExpandMore
                        fontSize="large"
                        style={{ color: '#F5942E' }}
                      />
                    )}
                  </ListItem>
                </Collapse>
                <Collapse
                  in={
                    state.category[i].open && state.category[i].questionOpen[j]
                  }
                  timeout="auto"
                  unmountOnExit
                  style={{ padding: 15 }}
                >
                  <Typography
                    style={{
                      color: '#1C1C1C',
                      fontSize: matchesPhone ? 10 : 12,
                    }}
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                </Collapse>
              </div>
            );
          })}
        </div>
      ))}
    </List>
  );
};

export default FaqList;
