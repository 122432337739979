import CookiesUtils from './cookieUtils';

export const getLinkByParams = (params, path) => {
  path = path.replace(':typology', params.typology);
  path = path.replace(':plan', params.plan);
  return path;
};

export const isUserLogged = () => {
  return !!CookiesUtils.getClientId() || !!CookiesUtils.getVendorId();
};

export const isNessunBox = box => {
  return box.id === '0';
};

export function percentage(percent, total) {
  return percent ? ((percent / 100) * total).toFixed(2) : 0;
}

export function roundToNextHundred(value) {
  return Math.ceil(value / 1) * 1;
}

export function roundToFirstDecimal(value) {
  //return Math.round(value * 10) / 10;
  return value;
}

/**
 * computes €/mq of an appartment
 * @param {number} price
 */
export const calculateInstallment = (
  price,
  taxRate,
  durationInYear,
  percentage,
  round
) => {
  const i = taxRate / 100 / 12;
  const v = Math.pow(1 + i, durationInYear * 12);
  const t = (i * v) / (v - 1);
  return round ? Math.round(t * price * percentage) : t * price * percentage;
};

export function percent(value, total) {
  return ((value * 100) / total).toFixed(6);
}

export const sortByName = array =>
  array.sort((p1, p2) => {
    if (p1.name < p2.name) {
      return -1;
    }
    if (p1.name > p2.name) {
      return 1;
    }
    return 0;
  });
