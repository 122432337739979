/**
 * gets actual host
 * @todo remove this utility
 */
const CalculateUrl = (oldTool, newTool, extra) => {
  try {
    let store = localStorage.getItem('store_salesonline');
    store = JSON.parse(store);
    let finalHostName = '';
    let hostname = window.location.hostname.replace('www.', '');
    if (hostname === store.hostKey.replace('www.', '')) {
      finalHostName = hostname + '/' + newTool + (extra ? extra : '');
    } else {
      if (hostname.indexOf('localhost') >= 0) {
        //devo puntare a biz-tecma-dev1 con relativo hostname
        if (extra) {
          finalHostName = `${newTool}-biz-tecma-dev1.tecmasolutions.com${extra}hostname=${store.hostKey}`;
        } else {
          finalHostName = `${newTool}-biz-tecma-dev1.tecmasolutions.com/?hostname=${store.hostKey}`;
        }
      } else if (
        hostname.indexOf('biz-tecma') >= 0 ||
        hostname.indexOf('-demo.tecmasolutions.com') >= 0
      ) {
        const newHostName = hostname.replace(oldTool, newTool);
        //devo puntare a biz-tecma-dev1 con relativo hostname
        if (extra) {
          finalHostName = `${newHostName}${extra}hostname=${store.hostKey}`;
        } else {
          finalHostName = `${newHostName}/?hostname=${store.hostKey}`;
        }
      } else {
        finalHostName =
          `${store.hostKey.replace('www', newTool)}` + (extra ? extra : '');
      }
    }
    return finalHostName;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export default CalculateUrl;
