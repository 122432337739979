import React from 'react';
import PropTypes from 'prop-types';
import Portal from '@material-ui/core/Portal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialogcontent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/common/Button';
import 'styles/modal.scss';
import { FormattedMessage } from 'react-intl';

/**
 * A default, simple error modal
 * based on material-ui Dialog
 * @component
 * @see {@link https://material-ui.com/api/dialog/ Dialog API}
 */
const SimpleModal = props => {
  const {
    disablePrimary,
    title,
    children,
    onClose,
    dialogClasses,
    extraContentClasses,
    extraTitleClasses,
    actionToDo,
    buttonLabel,
    cancelLabel,
    cancelAction,
    noButtons,
    open,
    maxWidth,
  } = props;

  const action = () => {
    actionToDo();
    onClose();
  };

  const cancel = () => {
    cancelAction();
    onClose();
  };

  return (
    <Portal>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        classes={dialogClasses}
        onClose={onClose}
      >
        <DialogTitle
          classes={{
            root: `modal-title light ${extraTitleClasses}`,
          }}
          disableTypography
        >
          {title}
        </DialogTitle>
        <Dialogcontent
          classes={{
            root: `modal-content ${extraContentClasses}`,
          }}
        >
          {children}
        </Dialogcontent>
        {!noButtons && (
          <DialogActions classes={{ root: 'modal-actions' }}>
            {cancelAction && (
              <Button onClick={cancel} color="primary">
                {cancelLabel}
              </Button>
            )}
            <Button onClick={action} disabled={disablePrimary} color="primary">
              {buttonLabel}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Portal>
  );
};

SimpleModal.defaultProps = {
  extraContentClasses: '',
  maxWidth: 'sm',
  dialogClasses: {},
  title: '',
  disablePrimary: false,
  open: false,
  buttonLabel: <FormattedMessage id="tuning.ok" defaultMessage="TODO def" />,
  cancelLabel: (
    <FormattedMessage id="tuning.goBack" defaultMessage="TODO def" />
  ),
  onClose: () => {},
  actionToDo: () => {},
};

/** prop types */
SimpleModal.propTypes = {
  /** extraContentClasses: addtional classes for the dialog content */
  extraContentClasses: PropTypes.string,
  /** extraTitleClasses: addtional classes for the dialog title */
  extraTitleClasses: PropTypes.string,
  /** maxWidth: sets max-width of container (material-ui prop) */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  /** dialogClasses  classes for the dialog container */
  dialogClasses: PropTypes.object,
  /** disablePrimary: condition to disable primary button */
  disablePrimary: PropTypes.bool,
  /** title: string */
  title: PropTypes.string,
  /** buttonLabel: label for the primary button */
  buttonLabel: PropTypes.string,
  /** cancelLabel: label for the secondary button */
  cancelLabel: PropTypes.string,
  /** open: required boolean */
  open: PropTypes.bool.isRequired,
  /** noButton: remove button */
  noButton: PropTypes.bool,
  /** onClose: required action */
  onClose: PropTypes.func.isRequired,
  /** actionToDo: additional action for the primary button */
  actionToDo: PropTypes.func,
  /** cancelAction: additional action for the secondary button. If set, the button is rendered */
  cancelAction: PropTypes.func,
  /** children: modal content */
  children: PropTypes.node,
};

export default SimpleModal;
