import React from 'react';
import Grid from '@material-ui/core/Grid';
import SimpleModal from '../common/SimpleModal';
//import Input from "../common/Input";
import Button from '../common/Button';
import Loader from 'components/common/Loader';
import ValidateEmail from '../../utils/ValidateEmail';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Client from '../../client/Client';
import 'styles/login.scss';
import { TextField } from '@material-ui/core';

const Login = observer(props => {
  const store = useStore();
  const intl = useIntl();
  const [doLogin, loginData] = useMutation(Client.LOGIN);
  const [retry, setRetry] = React.useState(false);

  const validLoginInput = store.loginEmail && ValidateEmail(store.loginEmail);

  const validSigninInput =
    store.signinEmail && ValidateEmail(store.signinEmail);

  const doneCalling = loginData && loginData.called && !loginData.loading;

  const loggedInStuff = data => {
    store.setLoggedToken(data.token);
    store.setLoggedUser(data.user);
    /*
        store.setRealLocation(HomeRoute.to);
        history.push(HomeRoute.to);*/
  };

  React.useEffect(() => {
    if (doneCalling && loginData.data && loginData.data.loginByProject) {
      loggedInStuff(loginData.data.loginByProject);
    }
  });

  if (
    doneCalling &&
    (!loginData.data || !loginData.data.loginByProject) &&
    !store.errorModal &&
    !retry
  ) {
    store.setErrorModal(true);
  }

  if (loginData && loginData.called && loginData.loading)
    return <Loader z loading />;

  const handleLoginSubmit = e => {
    e.preventDefault();
    alert(
      JSON.stringify({
        variables: {
          input: {
            email: store.loginEmail,
            password: store.loginPassword,
            project_id: store.projectId,
          },
        },
      })
    );
    /*
        setRetry(false);    
        doLogin({
            variables: {
                input: {
                    email: store.loginEmail,
                    password: store.loginPassword,
                    project_id: store.projectId,
                },
            },
        });*/
  };

  const handleSigninSubmit = e => {
    e.preventDefault();
    alert(
      JSON.stringify({
        variables: {
          input: {
            email: store.signinEmail,
            phone: store.phone,
            name: store.name,
            surname: store.surname,
            project_id: store.projectId,
          },
        },
      })
    );
  };

  return (
    <SimpleModal
      noButtons
      open={props.open || store.loginModalOpen}
      dialogClasses={{ paper: 'login-modal' }}
      extraTitleClasses="login-modal-title"
      extraContentClasses="login-modal-content"
    >
      <SimpleModal
        open={store.errorModal}
        title={intl.formatMessage({
          id: 'login.error',
          defaultMessage: 'TODO def',
        })}
        onClose={() => {
          store.setErrorModal(false);
          setRetry(true);
        }}
      >
        <FormattedMessage id="login.emailPassword" defaultMessage="TODO def" />
      </SimpleModal>
      <Grid container className="login-grid-container">
        <Grid item xs={12} sm={5} className="login-grid-login">
          <form onSubmit={handleLoginSubmit} id="login" autoComplete="off">
            <div className="login-hint">
              <FormattedMessage
                id="login.insertEmail"
                defaultMessage="EFFETTUA IL LOGIN"
              />
            </div>
            <TextField
              id="outlined-basic"
              style={{ margin: '10px' }}
              label="Outlined"
              variant="outlined"
              form="login"
              required
              label={intl.formatMessage({
                id: 'login.email',
                defaultMessage: 'E-mail',
              })}
              type="email"
              defaultInput
              value={store.loginEmail}
              error={
                !(store.loginEmail === '' || validLoginInput) &&
                store.loginPassword !== ''
              }
              helperText={
                !(store.loginEmail === '' || validLoginInput) &&
                store.loginPassword !== ''
                  ? intl.formatMessage({
                      id: 'login.nonValidFormat',
                      defaultMessage: 'Invalid e-mail',
                    })
                  : ''
              }
              onChange={e => {
                store.updateLoginEmail(e.target.value);
              }}
            />
            <TextField
              style={{ margin: '10px' }}
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              form="login"
              required
              label={intl.formatMessage({
                id: 'login.password',
                defaultMessage: 'Password',
              })}
              type="password"
              defaultInput
              value={store.loginPassword}
              onChange={e => {
                store.updateLoginPassword(e.target.value);
              }}
            />
            <Button
              form="login"
              disabled={!validLoginInput || store.loginPassword === ''}
              autoFocus={true}
              extraClass="login-button"
              type="submit"
            >
              <FormattedMessage id="login.login" defaultMessage="LOGIN" />
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} sm={5} className="login-grid-register">
          <form onSubmit={handleSigninSubmit} id="signin" autoComplete="off">
            <div className="login-hint">
              <FormattedMessage
                id="login.insertEmail"
                defaultMessage="Non hai ancora un'area riservata? REGISTRATI"
              />
            </div>
            <TextField
              style={{ margin: '10px' }}
              label="Outlined"
              variant="outlined"
              form="signin"
              label="Nome"
              defaultInput
              value={store.name}
              onChange={e => {
                store.updateSigninName(e.target.value);
              }}
            />
            <TextField
              style={{ margin: '10px' }}
              label="Outlined"
              variant="outlined"
              form="signin"
              label="Cognome"
              defaultInput
              value={store.surname}
              onChange={e => {
                store.updateSigninSurname(e.target.value);
              }}
            />
            <TextField
              style={{ margin: '10px' }}
              label="Outlined"
              variant="outlined"
              required
              form="signin"
              label={intl.formatMessage({
                id: 'login.email',
                defaultMessage: 'E-mail',
              })}
              type="email"
              defaultInput
              value={store.signinEmail}
              error={!(store.signinEmail === '' || validSigninInput)}
              helperText={
                !(store.signinEmail === '' || validSigninInput)
                  ? intl.formatMessage({
                      id: 'login.nonValidFormat',
                      defaultMessage: 'Invalid e-mail',
                    })
                  : ''
              }
              onChange={e => {
                store.updateSigninEmail(e.target.value);
              }}
            />
            <TextField
              style={{ margin: '10px' }}
              label="Outlined"
              variant="outlined"
              required
              form="signin"
              label="Telefono"
              defaultInput
              value={store.phone}
              onChange={e => {
                store.updateSigninPhone(e.target.value);
              }}
            />
            <Button
              form="signin"
              disabled={!validSigninInput || store.phone === ''}
              autoFocus={true}
              extraClass="login-button"
              type="submit"
            >
              <FormattedMessage id="login.login" defaultMessage="REGISTRATI" />
            </Button>
          </form>
        </Grid>
        {!props.open && (
          <Grid item xs={12} sm={2} className="login-grid-register">
            <Button
              extraClass="login-button"
              onClick={() => {
                store.setLoginModalOpen(false);
              }}
            >
              <FormattedMessage
                id="login.login"
                defaultMessage="Torna alla configurazione"
              />
            </Button>
          </Grid>
        )}
      </Grid>
    </SimpleModal>
  );
});

export default Login;
