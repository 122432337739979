import { observer } from 'mobx-react';
import {
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ScrollArea from 'react-scrollbar';
import Card from '../common/Card';
import Button from '../common/Button';
import { formatter as CurrencyFormatter } from 'utils/CurrencyFormatter';
import { useStore } from 'store/storeUtils.js';
import { useParams, useHistory } from 'react-router-dom';
import { getLinkByParams } from '../../utils/utils';
import RouteConsts from '../../constants/RoutesConst';
import Slider from './Slider';
import CancelIcon from '@material-ui/icons/Cancel';
import ImageDialog from './ImageDialog';
import ImagesDisclaimer from './ImagesDisclaimer';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    paddingBlock: 14,
    fontSize: 18,
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

const PlanDetails = observer(props => {
  const {
    matchesPhone,
    handleDrawer,
    handleNext,
    selectedAppartment,
    appartamentiDisponibili,
    typology,
    pageHeight,
  } = props;
  const store = useStore();
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  const [openPopup, setOpenPopup] = useState(false);
  const [openProspect, setOpenProspect] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [openZoomPlanimetria, setOpenZoomPlanimetria] = useState(false);
  const scrollAreaRef = useRef();

  const galleryThumbnailURL = `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/gallery_thumbnail.jpg`;

  useEffect(() => {
    scrollAreaRef.current.scrollArea.scrollTop();
  }, [pageHeight]);

  const getCollocazioneImgURL = side =>
    `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/collocazione/collocazione-${side}.jpg`;

  const getProspettoImgURL = side =>
    `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/prospetto/prospetto-${side}.jpg`;

  const immaginiGallery = [];

  for (let i = 1; i < 5; i++) {
    immaginiGallery.push(
      `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/gallery/${i}.jpg`
    );
  }

  const handleClickOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleClickOpenProspect = () => {
    setOpenProspect(true);
  };

  const handleCloseProspect = () => {
    setOpenProspect(false);
  };

  const handleClickOpenMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  const handleClickOpenGallery = () => {
    setOpenGallery(true);
  };

  const handleCloseGallery = () => {
    setOpenGallery(false);
  };

  const handleClickZoomPlanimetria = () => {
    setOpenZoomPlanimetria(true);
  };

  const handleCloseZoomPlanimetria = () => {
    setOpenZoomPlanimetria(false);
  };

  const handleBackTypology = () => {
    history.push(
      getLinkByParams(params, RouteConsts.map(r => r.navigation)[0])
    );
  };

  return (
    <>
      <div className="mobile-shadow-background" />
      <Grid item xs={12} className="plan-grid-details">
        <ScrollArea
          speed={0.4}
          className="area"
          contentClassName="content"
          horizontal={false}
          ref={scrollAreaRef}
        >
          {/* PRIMA CARD APPARTAMENTO SELEZIONATO */}
          {selectedAppartment && (
            <Paper
              style={matchesPhone ? { paddingTop: 40 } : null}
              className="mobile-shadow"
            >
              <Card>
                {matchesPhone && (
                  <Grid className="close-icon-container">
                    <IconButton className="close-icon" onClick={handleDrawer}>
                      <CancelIcon style={{ fill: '#F5942E' }} />
                    </IconButton>
                  </Grid>
                )}
                <div className="zoom-container">
                  <IconButton
                    className="icon-zoom"
                    onClick={handleClickZoomPlanimetria}
                  >
                    <img src="/icon/Raggruppa 21026.svg" />
                  </IconButton>
                  <ImageDialog
                    open={openZoomPlanimetria}
                    onClose={handleCloseZoomPlanimetria}
                    title="PLANIMETRIA"
                  >
                    <Slider
                      imgClassName="fit-contain"
                      imgs={[
                        `${
                          process.env.REACT_APP_BUCKET_BASEURL
                        }initiatives/${encodeURI(
                          store.loginProjectName
                        )}/floorplanning/img/planimetrie_cartigliate/${selectedAppartment.name.replaceAll(
                          ' ',
                          '.'
                        )}.jpg`,
                      ]}
                    />
                  </ImageDialog>
                </div>
                <img
                  className="planimetria"
                  src={`${
                    process.env.REACT_APP_BUCKET_BASEURL
                  }initiatives/${encodeURI(
                    store.loginProjectName
                  )}/floorplanning/img/planimetrie/${
                    selectedAppartment.plan.name
                  }.png`}
                />
                {/* TITOLO APPARTAMENTO SELEZIONATO */}
                <Grid style={{ justifyContent: 'space-between' }} container>
                  <Grid
                    item
                    style={{
                      padding: '18px',
                      paddingLeft: 'unset',
                    }}
                  >
                    {matchesPhone ? (
                      <>
                        <Typography variant="caption">
                          {typology.typology} <b>{selectedAppartment._label}</b>
                        </Typography>
                        <Typography
                          style={{ fontWeight: 600 }}
                          variant="subtitle2"
                        >
                          {selectedAppartment.floor}° Piano | cantina inclusa
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="h4">
                          {typology.typology} <b>{selectedAppartment._label}</b>
                        </Typography>
                        <Typography
                          style={{ fontWeight: 600 }}
                          variant="subtitle1"
                        >
                          {selectedAppartment.floor}° Piano | cantina inclusa
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item>
                    {!matchesPhone && (
                      <IconButton
                        className="app-selezionato-right-icon"
                        style={{
                          paddingRight: 'unset',
                          position: 'fixed',
                          right: 120,
                          top: 82,
                        }}
                        onClick={handleDrawer}
                      >
                        <img src="/icon/Tracciato 32881.svg" alt="" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Grid container alignItems="center" className={classes.root}>
                  <Grid item>
                    <Typography
                      className="info-app"
                      variant="body1"
                      component="h2"
                    >
                      Valore appartamento
                    </Typography>
                    <b>
                      € {CurrencyFormatter.format(selectedAppartment.price)}
                    </b>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item>
                    <Typography
                      className="info-app"
                      variant="body1"
                      component="h2"
                    >
                      superficie
                    </Typography>
                    <b>
                      {selectedAppartment.plan.SuperficieAppartamento.replace(
                        '.',
                        ','
                      )}
                    </b>{' '}
                    <thin style={{ fontSize: 'x-small' }}>
                      m<sup>2</sup>
                    </thin>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item>
                    <Typography
                      className="info-app"
                      variant="body1"
                      component="h2"
                    >
                      di cui loggia
                    </Typography>
                    <b>
                      {selectedAppartment.plan.SuperficieLoggia.replace(
                        '.',
                        ','
                      )}
                    </b>{' '}
                    <thin style={{ fontSize: 'x-small' }}>
                      m<sup>2</sup>
                    </thin>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 14 }}>
                  <Grid
                    container
                    xs={6}
                    className="bagno-mobile"
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      justifyContent: 'space-between',
                      padding: 10,
                      marginRight: 14,
                      maxWidth: '48%',
                    }}
                  >
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                      <img src="/icon/Icon ionic-ios-water.svg" />
                    </Grid>
                    <Grid item style={{ alignSelf: 'center' }}>
                      <Typography className="label-bagno" variant="subtitle2">
                        <b>
                          {selectedAppartment.plan.bathroom}{' '}
                          {selectedAppartment.plan.bathroom == 1
                            ? 'bagno'
                            : 'bagni'}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={6}
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      justifyContent: 'space-between',
                      padding: 10,
                      maxWidth: '48%',
                    }}
                  >
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                      <img src="/icon/Icon ionic-ios-bed.svg" />
                    </Grid>
                    <Grid item style={{ alignSelf: 'center' }}>
                      <Typography className="label-bagno" variant="subtitle2">
                        <b>
                          {selectedAppartment.plan.bedroom}{' '}
                          {selectedAppartment.plan.bedroom == 1
                            ? 'camera'
                            : 'camere'}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>

              {/* IMMAGINE + SFOGLIA LA GALLERY + DIVIDER */}
              <Card style={{ paddingTop: 5 }}>
                <img
                  className="selected-appartment-gallery-thumbnail"
                  src={galleryThumbnailURL}
                  alt=""
                />
                <Typography
                  style={{
                    marginTop: 16,
                    color: '#481635',
                    textAlign: 'center',
                  }}
                  variant="subtitle2"
                >
                  <img src="/icon/Raggruppa 21191.svg" alt="" />
                  <span style={{ marginLeft: 10 }}>
                    Tutte <b>le immagini inserite sono a scopo illustrativo</b>{' '}
                    e non fanno riferimento all'appartamento selezionato.
                  </span>
                </Typography>
                <Button
                  onClick={handleClickOpenGallery}
                  className="gallery-button"
                >
                  <img src="/icon/Icon material-photo-camera.svg" />
                  <Divider
                    orientation="vertical"
                    className="gallery-button-divider"
                    flexItem
                  />
                  <Typography
                    className="gallery-button-text"
                    variant="subtitle1"
                  >
                    <b>SFOGLIA LA GALLERY</b>
                  </Typography>
                </Button>
                <ImageDialog
                  open={openGallery}
                  onClose={handleCloseGallery}
                  title="GALLERY"
                >
                  <Slider imgs={immaginiGallery} />
                  <div style={{ position: 'relative' }}>
                    <ImagesDisclaimer className="modal-image-disclaimer" />
                  </div>
                </ImageDialog>
              </Card>
              <Divider variant="middle" />
            </Paper>
          )}

          {/* TITOLO CARATTERISTICHE */}
          <Paper className={!selectedAppartment && 'mobile-shadow'}>
            <Grid style={{ justifyContent: 'space-between' }} container>
              {matchesPhone ? (
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item className="titolo-caratteristiche">
                    <Typography
                      style={{ color: '#481635', fontWeight: 600 }}
                      variant="caption"
                    >
                      Caratteristiche
                    </Typography>
                  </Grid>
                  <Grid item className="close-icon-container">
                    {!selectedAppartment && (
                      <IconButton className="close-icon" onClick={handleDrawer}>
                        <CancelIcon style={{ fill: '#F5942E' }} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid item className="titolo-caratteristiche">
                  <Typography style={{ fontWeight: 600 }} variant="h4">
                    Caratteristiche
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {!matchesPhone && (
                  <IconButton
                    className="caratteristiche-right-icon"
                    disabled={selectedAppartment}
                    style={{ position: 'fixed', right: 110, top: 82 }}
                    onClick={handleDrawer}
                  >
                    <img src="/icon/Tracciato 32881.svg" alt="" />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            {/* CARD TIPOLOGIA */}
            <Card>
              <CardHeader
                className="card-tipologia"
                title={
                  matchesPhone ? (
                    <Typography
                      variant="subtitle1"
                      className="title-card-mobile"
                    >
                      Tipologia
                    </Typography>
                  ) : (
                    'Tipologia'
                  )
                }
                subheader={
                  matchesPhone ? (
                    <>
                      <Typography
                        className="subtitle-card-mobile"
                        variant="body1"
                      >
                        <b>
                          {typology.typology.toUpperCase()}{' '}
                          {selectedAppartment && selectedAppartment._label}{' '}
                        </b>{' '}
                        con cantina inclusa
                      </Typography>
                      <Typography>
                        <Button
                          startIcon={
                            <img
                              className="start-icon-mobile"
                              src="/icon/icon-modifica.svg"
                            />
                          }
                          onClick={handleClickOpen}
                          className="view-button"
                        >
                          <Typography
                            style={{
                              letterSpacing: 0.68,
                              fontSize: 10,
                              color: '#F5942E',
                            }}
                            variant="subtitle1"
                          >
                            <b>MODIFICA LA TIPOLOGIA</b>
                          </Typography>
                        </Button>
                        <Dialog
                          fullWidth={true}
                          maxWidth="xs"
                          open={openPopup}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <div>
                            <img
                              className="icon-popup"
                              src="/icon/Ellisse 114.svg"
                              alt=""
                            />
                            <img
                              className="icon-popup"
                              src="/icon/Icon feather-bell.svg"
                              alt=""
                            />
                          </div>
                          <DialogTitle id="alert-dialog-title">
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 600, color: '#481635' }}
                            >
                              Attenzione!
                            </Typography>
                            <Typography
                              style={{ fontWeight: 600 }}
                              variant="subtitle2"
                            >
                              Hai scelto di modificare le tue scelte
                            </Typography>
                          </DialogTitle>
                          <Divider variant="middle" />
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <Typography variant="subtitle2">
                                Se confermi di modificare le tue scelte, tutte
                                quelle compiute dopo questa fase verranno
                                annullate.
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                className="dialog-content-question"
                              >
                                Vuoi annullare le tue scelte successive?
                              </Typography>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions style={{ flexDirection: 'column' }}>
                            <Button
                              className="button-popup-no"
                              variant="outlined"
                              onClick={handleClose}
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: '#FFFFFF', fontSize: 12 }}
                              >
                                <b>No</b>, mantengo le mie scelte
                              </Typography>
                            </Button>
                            <Button
                              className="button-popup"
                              variant="outlined"
                              style={{
                                marginBottom: '10px',
                                textTransform: 'none',
                              }}
                              onClick={handleBackTypology}
                              autoFocus
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: '#F5942E', fontSize: 10 }}
                              >
                                <b>Sì</b>, voglio cambiare le mie scelte
                              </Typography>
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="caption">
                        <b>
                          {typology.typology.toUpperCase()}{' '}
                          {selectedAppartment && selectedAppartment._label}{' '}
                        </b>{' '}
                        con cantina inclusa
                      </Typography>
                      <Typography>
                        <Button
                          startIcon={<img src="/icon/icon-modifica.svg" />}
                          onClick={handleClickOpen}
                          className="view-button"
                        >
                          <Typography
                            style={{
                              letterSpacing: 0.68,
                              fontSize: 12,
                              color: '#F5942E',
                            }}
                            variant="subtitle1"
                          >
                            <b>MODIFICA LA TIPOLOGIA</b>
                          </Typography>
                        </Button>
                        <Dialog
                          fullWidth={true}
                          maxWidth="xs"
                          open={openPopup}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <div>
                            <img
                              className="icon-popup"
                              src="/icon/Ellisse 114.svg"
                              alt=""
                            />
                            <img
                              className="icon-popup"
                              src="/icon/Icon feather-bell.svg"
                              alt=""
                            />
                          </div>
                          <DialogTitle id="alert-dialog-title">
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 600, color: '#481635' }}
                            >
                              Attenzione!
                            </Typography>
                            <Typography
                              style={{ fontWeight: 600 }}
                              variant="subtitle2"
                            >
                              Hai scelto di modificare le tue scelte
                            </Typography>
                          </DialogTitle>
                          <Divider variant="middle" />
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <Typography variant="subtitle2">
                                Se confermi di modificare le tue scelte, tutte
                                quelle compiute dopo questa fase verranno
                                annullate.
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                className="dialog-content-question"
                              >
                                Vuoi annullare le tue scelte successive?
                              </Typography>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions style={{ flexDirection: 'column' }}>
                            <Button
                              className="button-popup-no"
                              variant="outlined"
                              onClick={handleClose}
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: '#FFFFFF', fontSize: 12 }}
                              >
                                <b>No</b>, mantengo le mie scelte
                              </Typography>
                            </Button>
                            <Button
                              className="button-popup"
                              variant="outlined"
                              style={{
                                marginBottom: '10px',
                                textTransform: 'none',
                              }}
                              onClick={handleBackTypology}
                              autoFocus
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: '#F5942E', fontSize: 10 }}
                              >
                                <b>Sì</b>, voglio cambiare le mie scelte
                              </Typography>
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Typography>
                    </>
                  )
                }
                avatar={
                  matchesPhone ? (
                    <img
                      className="avatar-card-mobile"
                      src="/icon/icon-planimetria-Bianco.svg"
                    />
                  ) : (
                    <img src="/icon/icon-planimetria-Bianco.svg" />
                  )
                }
              />
            </Card>

            {/* CARD PROSPETTO */}
            <Card>
              <CardHeader
                className="card-prospetto"
                avatar={
                  matchesPhone ? (
                    <img
                      className="avatar-card-mobile"
                      src="/icon/icon-affaccio-Bianco.svg"
                    />
                  ) : (
                    <img src="/icon/icon-affaccio-Bianco.svg" />
                  )
                }
                title={
                  matchesPhone ? (
                    <Typography
                      variant="subtitle1"
                      className="title-card-mobile"
                    >
                      Affaccio
                    </Typography>
                  ) : (
                    'Affaccio'
                  )
                }
                subheader={
                  matchesPhone ? (
                    <>
                      <Typography
                        className="subtitle-card-mobile"
                        variant="body1"
                      >
                        <b>{typology.affaccio}</b>
                      </Typography>
                      <Typography>
                        {typology.affaccio !== 'Piazzale Accursio' && (
                          <Button
                            onClick={handleClickOpenProspect}
                            startIcon={
                              <img
                                className="start-icon-mobile"
                                src="/icon/Raggruppa 21028.svg"
                              />
                            }
                            className="view-button-prospetto"
                          >
                            <Typography
                              style={{
                                letterSpacing: 0.68,
                                marginBlock: 15,
                                fontSize: 10,
                                color: '#F5942E',
                              }}
                              variant="subtitle1"
                            >
                              <b>VISUALIZZA PROSPETTO</b>
                            </Typography>
                          </Button>
                        )}
                        <ImageDialog
                          open={openProspect}
                          onClose={handleCloseProspect}
                          title="PROSPETTO"
                        >
                          <img
                            className="full-size-contain"
                            src={
                              typology.typology !== 'Trilocale'
                                ? getProspettoImgURL('B')
                                : getProspettoImgURL('A')
                            }
                          />
                        </ImageDialog>
                        <ImageDialog
                          open={openMap}
                          onClose={handleCloseMap}
                          title={<b>{typology.affaccio}</b>}
                        >
                          {typology.affaccio === 'Via Grosotto' && (
                            <img
                              className="full-size-contain"
                              src={getCollocazioneImgURL('B')}
                            />
                          )}
                          {typology.affaccio === 'Via Traiano' && (
                            <img
                              className="full-size-contain"
                              src={getCollocazioneImgURL('A')}
                            />
                          )}
                          {typology.affaccio === 'Piazzale Accursio' && (
                            <img
                              className="full-size-contain"
                              src={getCollocazioneImgURL('C')}
                            />
                          )}
                        </ImageDialog>
                      </Typography>
                      {typology.affaccio === 'Via Grosotto' && (
                        <img
                          style={{ width: '100%' }}
                          src={getCollocazioneImgURL('B')}
                          onClick={handleClickOpenMap}
                        />
                      )}
                      {typology.affaccio === 'Via Traiano' && (
                        <img
                          style={{ width: '100%' }}
                          src={getCollocazioneImgURL('A')}
                          onClick={handleClickOpenMap}
                        />
                      )}
                      {typology.affaccio === 'Piazzale Accursio' && (
                        <img
                          style={{ width: '100%', marginTop: 16 }}
                          src={getCollocazioneImgURL('C')}
                          onClick={handleClickOpenMap}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <b>{typology.affaccio}</b>
                      <Typography>
                        {typology.affaccio !== 'Piazzale Accursio' && (
                          <Button
                            onClick={handleClickOpenProspect}
                            startIcon={<img src="/icon/Raggruppa 21028.svg" />}
                            className="view-button"
                          >
                            <Typography
                              style={{
                                letterSpacing: 0.68,
                                marginBlock: 15,
                                fontSize: 12,
                                color: '#F5942E',
                              }}
                              variant="subtitle1"
                            >
                              <b>VISUALIZZA PROSPETTO</b>
                            </Typography>
                          </Button>
                        )}
                        <ImageDialog
                          open={openProspect}
                          onClose={handleCloseProspect}
                          title="PROSPETTO"
                        >
                          <ImageDialog
                            open={openProspect}
                            onClose={handleCloseProspect}
                            title="PROSPETTO"
                          >
                            <img
                              className="full-size-contain"
                              src={
                                typology.typology !== 'Trilocale'
                                  ? getProspettoImgURL('B')
                                  : getProspettoImgURL('A')
                              }
                            />
                          </ImageDialog>
                        </ImageDialog>
                        <ImageDialog
                          open={openMap}
                          onClose={handleCloseMap}
                          title={<b>{typology.affaccio}</b>}
                        >
                          {typology.affaccio === 'Via Grosotto' && (
                            <img
                              className="full-size-contain"
                              src={getCollocazioneImgURL('B')}
                            />
                          )}
                          {typology.affaccio === 'Via Traiano' && (
                            <img
                              className="full-size-contain"
                              src={getCollocazioneImgURL('A')}
                            />
                          )}
                          {typology.affaccio === 'Piazzale Accursio' && (
                            <img
                              className="full-size-contain"
                              src={getCollocazioneImgURL('C')}
                            />
                          )}
                        </ImageDialog>
                      </Typography>
                      {typology.affaccio === 'Via Grosotto' && (
                        <img
                          style={{ width: '100%' }}
                          src={getCollocazioneImgURL('B')}
                        />
                      )}
                      {typology.affaccio === 'Via Traiano' && (
                        <img
                          style={{ width: '100%' }}
                          src={getCollocazioneImgURL('A')}
                        />
                      )}
                      {typology.affaccio === 'Piazzale Accursio' && (
                        <img
                          style={{ width: '100%', marginTop: 16 }}
                          src={getCollocazioneImgURL('C')}
                        />
                      )}
                      <div className="zoom-container">
                        <IconButton
                          className="icon-zoom-map"
                          onClick={handleClickOpenMap}
                        >
                          <img src="/icon/Raggruppa 21026.svg" />
                        </IconButton>
                      </div>
                    </>
                  )
                }
              />
            </Card>
          </Paper>
        </ScrollArea>
        {/* FOOTER CARD */}
        {selectedAppartment && (
          <Card className="footer-price">
            <CardContent
              style={matchesPhone ? { textAlign: 'unset' } : null}
              className="card-price"
            >
              <Typography className="price-title-1" variant="body1">
                Il valore del tuo appartamento
              </Typography>
              <Typography className="prezzo">
                € {CurrencyFormatter.format(selectedAppartment.price)}
              </Typography>
              <div id="price" className="barra-prezzo-plan"></div>
              <Button
                className="button-plan-next"
                onClick={() => handleNext(selectedAppartment._label)}
              >
                <Typography className="label-price" variant="subtitle1">
                  <b>prosegui</b>
                </Typography>
              </Button>
              <Typography className="label-promo" variant="h2">
                Completa la configurazione per scoprire il valore finale
                riservato a te
              </Typography>
            </CardContent>
          </Card>
        )}
      </Grid>
    </>
  );
});

export default PlanDetails;
