/**
 * gets actual host
 * @todo remove this utility
 */
const DetectUrl = () => {
  let hostname =
    window.location.hostname.indexOf('www.') >= 0
      ? window.location.hostname
      : 'www.' + window.location.hostname;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');

  if (
    hostname.indexOf('localhost') >= 0 ||
    hostname.indexOf('.ddns.net') >= 0 ||
    hostname.indexOf('biz-tecma') >= 0 ||
    hostname.indexOf('-demo.tecmasolutions.com') >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '') {
      localStorage.clear();
      delete localStorage['floorplanning_dev_hostname'];
      localStorage.setItem('floorplanning_dev_hostname', hostnameParam);
    }

    let floorplanning_dev_hostname = localStorage.getItem(
      'floorplanning_dev_hostname'
    );

    if (
      floorplanning_dev_hostname &&
      floorplanning_dev_hostname.length > 0 &&
      floorplanning_dev_hostname !== ''
    ) {
      return floorplanning_dev_hostname;
    }

    hostname = process.env.REACT_APP_HOST;
  }

  return hostname;
};

export default DetectUrl;
