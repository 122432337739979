import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils.js';
import 'styles/login.scss';
import Loader from 'components/common/Loader';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Client from 'client/Client';
import { LoginRoute } from 'routes/Routes';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Divider,
  Snackbar,
  TextField,
  Typography,
  InputAdornment,
  Link,
  useTheme,
  useMediaQuery,
  IconButton,
  Grid,
} from '@material-ui/core';
import ReactSVG from 'components/common/SvgLoader.jsx';
import { Alert } from '@material-ui/lab';
import CalculateUrl from 'utils/CalculateUrl';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const UpdatedPassword = observer(props => {
  const { matchesPhone } = props;
  const store = useStore();
  const history = useHistory();

  const backToLogin = () => {
    store.setRealLocation(LoginRoute.to);
    history.push(LoginRoute.to);
  };

  return (
    <Card elevation={6} className="password-update-container">
      {!matchesPhone ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/icon/Componente 98 – 1.svg" alt="" />
            <Divider
              orientation="vertical"
              flexItem
              style={{
                margin: '6px 36px',
                width: 2,
                backgroundColor: '#481635',
              }}
            />
            <Typography variant="h4">
              La password <br /> <b>è stata aggiornata!</b>
            </Typography>
          </div>
          <Typography
            style={{ marginTop: 50, marginBottom: 40 }}
            variant="subtitle1"
          >
            Ora puoi tornare alla tua configurazione e proseguire <br /> con
            l'accesso alla tua area riservata.
          </Typography>
          <Button
            onClick={backToLogin}
            style={{ height: 50 }}
            className={'login-button'}
          >
            <Typography
              style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
              variant="subtitle1"
            >
              torna all'accesso per area riservata
            </Typography>
          </Button>
        </>
      ) : (
        <>
          <div style={{ padding: 20 }}>
            <Typography variant="h5">
              La password <br /> <b>è stata aggiornata!</b>
            </Typography>
            <Typography
              style={{ marginTop: 15 }}
              variant="subtitle2"
              component="h2"
            >
              Ora puoi tornare alla tua configurazione e proseguire <br /> con
              l'accesso alla tua area riservata.
            </Typography>
          </div>
          <Button
            onClick={backToLogin}
            style={{ height: 50, width: '88%', alignSelf: 'center' }}
            className={'login-button'}
          >
            <Typography
              style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
              variant="subtitle1"
            >
              torna all'accesso per area riservata
            </Typography>
          </Button>
        </>
      )}
    </Card>
  );
});

const CambiaPasswordContainer = observer(props => {
  const { matchesPhone } = props;
  const store = useStore();
  const history = useHistory();

  const UrlQueryStrings = props.navigation.location.search;
  const queryValues = queryString.parse(UrlQueryStrings);

  const [secretToken] = React.useState(queryValues.token);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [success, setSuccess] = React.useState(false);

  const [error, setError] = React.useState(null);

  const [changePassword] = useMutation(Client.CHANGE_PASSWORD);

  if (!secretToken) {
    return <Redirect exact to="/login" />;
  }

  if (store.step || store.step === 0) {
    store.setStep(null);
  }

  if (loading) {
    return <Loader z loading />;
  }

  if (!store.projectName) {
    return <></>;
  }

  if (success) {
    return <UpdatedPassword matchesPhone={matchesPhone} />;
  }

  return (
    <Card elevation={6} className="confirm-container">
      {!matchesPhone ? (
        <>
          <Snackbar className="svg-alert" open={!!error}>
            <Alert
              style={{
                flex: 'unset',
                alignSelf: 'center',
                fontSize: 'small',
              }}
              severity="error"
              variant="filled"
              onClose={() => setError(false)}
            >
              <div dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          </Snackbar>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/icon/Raggruppa 21045.svg" alt="" />
            <Divider
              orientation="vertical"
              flexItem
              style={{
                margin: '6px 36px',
                width: 2,
                backgroundColor: '#481635',
              }}
            />
            <Typography variant="h4">
              In questa sezione <br /> <b>inserisci la password</b>
            </Typography>
          </div>
          <Typography style={{ marginTop: 28 }} variant="subtitle1">
            Prosegui inserendo la nuova password da associare <br /> al tuo
            profilo.
          </Typography>
          <form
            id="new-password-form"
            onSubmit={e => {
              e.preventDefault();
              setLoading(true);
              changePassword(
                Client.CHANGE_PASSWORD_DEFAULT_OPTIONS(
                  store.projectId,
                  secretToken,
                  newPassword
                )
              )
                .then(res => {
                  setLoading(false);
                  switch (res.data.changePassword) {
                    case 'success':
                      setSuccess(true);
                      break;
                    case 'expiredToken': {
                      setError(
                        `Token scaduto, richiedi il <a href="${
                          window.location.protocol +
                          '//' +
                          CalculateUrl(
                            'salesonline',
                            'myhome',
                            '/login?changePassword&'
                          )
                        }">cambio password</a>`
                      );
                      break;
                    }
                    case 'wrongPassword':
                    case 'invalidToken': {
                      setError('I dati inseriti non sono validi');
                      break;
                    }
                    default: {
                      setError('Errore durante la chiamata');
                      break;
                    }
                  }
                })
                .catch(() => {
                  setError('Errore durante la chiamata');
                  setLoading(false);
                });
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                marginTop: 10,
                textAlign: 'center',
                color: '#481635',
                fontWeight: 500,
                height: 'unset',
              }}
            >
              NUOVA PASSWORD
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                textAlign: 'center',
                fontStyle: 'italic',
                marginBottom: 35,
              }}
            >
              Deve contenere minimo 6 caratteri
            </Typography>
            <label>
              <Typography style={{ color: '#707070' }} variant="subtitle2">
                Inserisci una nuova password
              </Typography>
            </label>
            <TextField
              inputProps={{
                pattern: '^.{6,}$',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/icon/Icon ionic-md-key.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      style={{ maxHeight: '100%' }}
                      className="visibility-icon-button"
                    >
                      {showNewPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="new-password"
              variant="outlined"
              placeholder="inserisci una nuova password"
              type={showNewPassword ? 'text' : 'password'}
              className="password-input"
              value={newPassword}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              onChange={e => {
                setNewPassword(e.target.value);
                if (e.target.value !== confirmNewPassword) {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity(
                      'Le due nuove password devono combaciare'
                    );
                } else {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity('');
                }
              }}
            />
            <label>
              <Typography style={{ color: '#707070' }} variant="subtitle2">
                Conferma la nuova password
              </Typography>
            </label>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/icon/Icon ionic-md-key.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      style={{ maxHeight: '100%' }}
                      className="visibility-icon-button"
                    >
                      {showConfirmPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="confirm-new-password"
              variant="outlined"
              placeholder="conferma nuova password"
              type={showConfirmPassword ? 'text' : 'password'}
              className="password-input"
              value={confirmNewPassword}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              onChange={e => {
                setConfirmNewPassword(e.target.value);
                if (newPassword !== e.target.value) {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity(
                      'Le due nuove password devono combaciare'
                    );
                } else {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity('');
                }
              }}
            />
            <Button
              style={{ marginTop: 25 }}
              type="submit"
              className={'login-button'}
            >
              <Typography
                style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                variant="subtitle1"
              >
                conferma password
              </Typography>
            </Button>
          </form>
        </>
      ) : (
        <>
          <Snackbar className="svg-alert" open={!!error}>
            <Alert
              style={{
                flex: 'unset',
                alignSelf: 'center',
                fontSize: 'small',
              }}
              severity="error"
              variant="filled"
              onClose={() => setError(false)}
            >
              <div dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          </Snackbar>
          <div style={{ padding: '20px' }}>
            <Typography variant="h5">
              In questa sezione <br /> <b>inserisci la password</b>
            </Typography>
            <br />
            <Typography variant="subtitle2">
              Prosegui inserendo la nuova password <br /> da associare al tuo
              profilo.
            </Typography>
          </div>
          <form
            id="new-password-form"
            style={{
              padding: '20px',
              width: 'initial',
              marginTop: 'unset',
              paddingTop: 'unset',
            }}
            onSubmit={e => {
              e.preventDefault();
              if (e.key === 'Enter') {
                e.target.blur();
              }
              setLoading(true);
              changePassword(
                Client.CHANGE_PASSWORD_DEFAULT_OPTIONS(
                  store.projectId,
                  secretToken,
                  newPassword
                )
              )
                .then(res => {
                  setLoading(false);
                  switch (res.data.changePassword) {
                    case 'success':
                      setSuccess(true);
                      break;
                    case 'expiredToken': {
                      setError(
                        `Token scaduto, richiedi il <a href="${
                          window.location.protocol +
                          '//' +
                          CalculateUrl(
                            'salesonline',
                            'myhome',
                            '/login?changePassword&'
                          )
                        }">cambio password</a>`
                      );
                      break;
                    }
                    case 'wrongPassword':
                    case 'invalidToken': {
                      setError('I dati inseriti non sono validi');
                      break;
                    }
                    default: {
                      setError('Errore durante la chiamata');
                      break;
                    }
                  }
                })
                .catch(() => {
                  setError('Errore durante la chiamata');
                  setLoading(false);
                });
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                marginTop: 40,
                textAlign: 'center',
                color: '#481635',
                fontWeight: 500,
                height: 'unset',
              }}
            >
              NUOVA PASSWORD
            </Typography>
            <div className="password-min-characters">
              Deve contenere minimo 6 caratteri
            </div>
            <label>
              <Typography style={{ color: '#707070' }} variant="body1">
                Inserisci una nuova password
              </Typography>
            </label>
            <TextField
              inputProps={{
                pattern: '^.{6,}$',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/icon/Icon ionic-md-key.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      style={{ maxHeight: '100%' }}
                      className="visibility-icon-button"
                    >
                      {showNewPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="new-password"
              variant="outlined"
              placeholder="inserisci una nuova password"
              type={showNewPassword ? 'text' : 'password'}
              className="password-input"
              value={newPassword}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              onChange={e => {
                setNewPassword(e.target.value);
                if (e.target.value !== confirmNewPassword) {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity(
                      'Le due nuove password devono combaciare'
                    );
                } else {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity('');
                }
              }}
            />
            <label>
              <Typography style={{ color: '#707070' }} variant="body1">
                Conferma la nuova password
              </Typography>
            </label>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/icon/Icon ionic-md-key.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      style={{ maxHeight: '100%' }}
                      className="visibility-icon-button"
                    >
                      {showConfirmPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="confirm-new-password"
              variant="outlined"
              placeholder="conferma nuova password"
              type={showConfirmPassword ? 'text' : 'password'}
              className="password-input"
              value={confirmNewPassword}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              onChange={e => {
                setConfirmNewPassword(e.target.value);
                if (newPassword !== e.target.value) {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity(
                      'Le due nuove password devono combaciare'
                    );
                } else {
                  document
                    .getElementById('confirm-new-password')
                    .setCustomValidity('');
                }
              }}
            />
            <Button
              style={{ marginTop: 10 }}
              type="submit"
              className={'login-button'}
            >
              <Typography
                style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                variant="subtitle1"
              >
                conferma password
              </Typography>
            </Button>
          </form>
        </>
      )}
    </Card>
  );
});

const CambiaPassword = observer(props => {
  const store = useStore();
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  return (
    <Grid container justify="center" alignItems="center">
      {!matchesPhone && (
        <div
          className="login-background-container"
          style={{
            backgroundImage: `url('${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/login_background.jpg')`,
          }}
        />
      )}
      <CambiaPasswordContainer matchesPhone={matchesPhone} {...props} />
    </Grid>
  );
});

export default CambiaPassword;
