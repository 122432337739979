import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'store/storeUtils';
import Client from 'client/Client';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import psl from 'psl';

let timeoutRefreshToken = null;

const TuningCountdown = ({ threshold }) => {
  const store = useStore();
  const [refreshToken, newTokenInfo] = useMutation(Client.REFRESH_TOKEN);

  const [refreshed, setRefreshed] = useState(false);

  let domain = 'localhost';
  if (window.location.hostname !== 'localhost') {
    const pslUrl = psl.parse(window.location.hostname);
    domain = pslUrl.domain;
  }

  const expiresIn = Cookies.get('expiresIn');

  const startCountDown = expiresIn => {
    const targetTime = +new Date(expiresIn) - +threshold;
    let timeout = targetTime - Date.now();
    const maxTimeoutValue = 2147483647;
    timeout = timeout > maxTimeoutValue ? maxTimeoutValue : timeout;
    if (!refreshed) {
      clearTimeout(timeoutRefreshToken);
      timeoutRefreshToken = setTimeout(() => {
        getNewToken();
      }, timeout);
      setRefreshed(!refreshed);
    }
  };

  const getNewToken = () => {
    refreshToken(
      Client.REFRESH_TOKEN_DEFAULT_OPTIONS(
        Cookies.get('refreshToken'),
        store.loggedUser.email
      )
    ).then(res => {
      const newInfo = res.data.refreshToken;
      const refreshTokenInfo = keys => {
        keys.map(key => {
          const tempKey = key === 'accessToken' ? 'jwt' : key;
          Cookies.remove(tempKey, { domain: domain });
          Cookies.set(tempKey, newInfo[key], { domain, expires: 1 });
        });
      };
      refreshTokenInfo(['accessToken', 'refreshToken', 'expiresIn']);
      setRefreshed(false);
    });
  };

  useEffect(() => {
    startCountDown(expiresIn);
  }, [refreshed]);

  return <></>;
};

/** prop types */
TuningCountdown.propTypes = {
  threshold: PropTypes.string,
};
TuningCountdown.defaultProps = {
  threshold: null,
};

export default TuningCountdown;
