import colors from './colors.scss';
import variables from './variables.scss';

export const CrmMUITheme = {
  typography: {
    fontFamily: variables.DEFAULT_FONT,
    fontWeight: variables.LIGHT,
  },
  overrides: {
    MuiDialog: {
      root: {
        textAlign: 'center',
      },
      paperWidthXs: {
        height: 350,
      },
      paper: {
        overflowY: 'unset',
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 15,
        paddingBottom: 'unset',
        overflowY: 'hidden',
      },
    },
    MuiDialogActions: {
      root: {
        borderBottom: '5px solid #F5942E',
        paddingTop: 'unset',
        paddingBottom: 15,
      },
    },
    MuiGrid: {
      root: {
        justifyContent: 'center',
        display: 'block',
        '&.tecma-elements': {
          display: 'flex',
          justifyContent: 'flex-start',
        },
        '&.desktop-elements': {
          display: 'flex',
        },
        '&.plan-grid-select': {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
        },
        '&.typology-grid': {
          display: 'flex',
        },
      },
    },
    MuiSnackbar: {
      anchorOriginBottomCenter: {
        '&.svg-alert': {
          bottom: '50%!important',
        },
      },
    },
    MuiAlert: {
      root: {
        margin: '10px 20px',
        padding: '5px',
        justifyContent: 'center',
      },
      filledError: {
        backgroundColor: '#FFE4E4',
        color: '#FF0000',
        borderBottom: '4px solid #FF0000',
        fontWeight: 400,
        fontSize: 12,
        width: 440,
        height: 30,
        marginBottom: 50,
      },
      filledSuccess: {
        backgroundColor: '#F0F5EF',
        color: '#518143',
        borderBottom: '4px solid #518143',
        fontWeight: 'bold',
      },
      standardInfo: {
        right: 0,
        paddingInline: 5,
        paddingBlock: 'unset',
        position: 'absolute',
        background: '#FFFFFF !important',
        borderBottom: '4px solid #481635 !important',
        borderRadius: '5px 5px 5px 5px',
        minWidth: 'max-content',
      },
    },
    MuiCard: {
      root: {
        height: 'fit-content',
      },
    },
    MuiCardHeader: {
      root: {
        alignItems: 'unset',
      },
      title: {
        paddingTop: 4,
        fontWeight: 600,
        fontSize: 12,
        color: '#1C1C1C',
      },
      avatar: {
        marginBottom: 'auto',
      },
    },
    MuiCardActionArea: {
      root: {
        textAlign: 'center',
      },
    },
    MuiCardActions: {
      root: {
        padding: '15px',
        flexDirection: 'column',
        backgroundColor: 'white',
        '&.summary-submit-buttom': {
          alignItems: 'flex-end',
        },
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: 'white',
        '&:last-child': {
          paddingBottom: '0px',
        },
      },
    },
    MuiPaper: {
      root: {
        flex: 'auto',
        color: colors.DEFAULT_FONT_COLOR,
        borderRadius: 'none',
        zIndex: '0',
        '&.mobile-shadow': {
          backgroundColor: 'rgba(0,0,0,0) !important',
        },
        '&.success-alert': {
          width: '100%',
          margin: 'unset',
          height: 30,
          marginTop: 5,
          marginBottom: 30,
          placeSelf: 'center',
        },
        '&.card-wrapper > .MuiCardActions-root > .button-typology:disabled': {
          color: 'gray!important',
          background: 'whitesmoke!important',
        },
        '&.card-wrapper:hover > .MuiCardActions-root > .button-typology:not(:disabled)':
          {
            opacity: 0.5,
          },
        '&.card-wrapper > .MuiCardActions-root': {
          paddingTop: 'unset',
        },
      },
      rounded: {
        borderRadius: 'none',
      },
      elevation1: {
        boxShadow: 'none',
      },
      elevation2: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
      elevation8: {
        boxShadow: 'none',
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: 'unset!important',
      },
      alternativeLabel: {
        '&.mobile': {
          flex: 'unset',
        },
      },
    },
    MuiStepLabel: {
      active: {
        color: '#481635!important',
        fontSize: '9px',
      },
      label: {
        color: '#9F9E9D',
        fontSize: '9px',
        marginTop: '4px!important',
      },
    },
    MuiStepper: {
      root: {
        padding: 'unset',
        paddingTop: 5,
      },
      horizontal: {
        display: 'flex',
      },
      alternativeLabel: {
        alignItems: 'unset',
      },
    },
    MuiStepButton: {
      root: {
        zIndex: '1',
        '&.Mui-disabled .MuiStepIcon-text': { fill: '#9F9E9D' },
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        left: '-50%',
        width: '100%',
        zIndex: '0',
      },
      active: {
        '& $line': {
          borderColor: '#F5942E',
        },
      },
      completed: {
        '& $line': {
          borderColor: '#F5942E',
        },
      },
      line: {
        borderColor: 'lightgray',
      },
    },
    MuiStepIcon: {
      root: {
        color: 'white!important',
        backgroundColor: 'lightgray',
        borderRadius: 13,
        padding: 1,
      },
      text: {
        fill: 'white',
        fontWeight: 'bold',
      },
      active: {
        color: '#F5942E!important',
        backgroundColor: '#F5942E!important',
      },
      completed: {
        color: '#FEF4EC!important',
        backgroundColor: '#F5942E',
        borderRadius: 13,
      },
    },
    MuiDivider: {
      root: {
        '&.divider-button': {
          width: 1,
          backgroundColor: '#A55705',
        },
        '&.divider-button.selected-appartment': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'unset!important',
      },
      root: {
        borderRadius: 'unset',
        color: 'orange!important',
        '&:hover': {
          '&.appartment-button.selected-appartment': {
            opacity: 'unset',
          },
          '&.login-button': {
            background: '#FBDAB9!important',
          },
          '&.button-plan-next': {
            background: '#D96F00',
          },
          '&.icona-modifica-riepilogo': {
            backgroundColor: 'unset',
          },
          color: 'white!important',
        },
        '&.icona-modifica-riepilogo': {
          minWidth: 'unset',
        },
        '&.appartment-button.selected-appartment': {
          background: '#D96F00',
          color: '#FFFFFF!important',
        },
        '&.button-plan-next': {
          marginTop: 8,
          background: '#F5942E',
          border: '2px solid #816F7A',
          width: '100%',
          height: 30,
        },
        '&.salta-la-coda-button': {
          background: '#F5942E',
          border: '2px solid #816F7A',
          width: 220,
          height: 45,
          marginInline: 15,
          marginTop: 15,
        },
        '&.summary-buttom': {
          color: 'white!important',
          backgroundColor: '#4c8c40!important',
        },
        '&.login-button': {
          color: 'white!important',
        },
        '&.login-button:not(:disabled)': {
          color: 'white!important',
          backgroundColor: '#f0a960!important',
        },
        '&.appartment-button': {
          color: '#A55705!important',
          backgroundColor: '#FEC386',
          paddingRight: '10px',
        },
        '&.header-button': {
          color: '#481635!important',
          padding: '17px 20px',
        },
      },
      outlined: {
        '&.button-popup': {
          border: '1px solid #F5942E',
          textTransform: 'none',
          width: 180,
          height: 30,
          padding: 'unset',
        },
        '&.button-popup-no': {
          border: '1px solid #F5942E',
          color: 'white!important',
          background: '#F5942E',
          marginBottom: 15,
          textTransform: 'none',
          width: 215,
          height: 35,
          padding: 'unset',
        },
        '&.button-popup-no:hover': {
          border: '1px solid #F5942E',
          color: 'white',
          background: 'orange',
        },
        '&.button-popup:hover': {
          border: '1px solid #F5942E',
          color: 'orange!important',
          background: 'white',
        },
        '&.button-typology:not(:disabled)': {
          color: '#FFFFFF!important',
          background: '#F5942E',
        },
        '&.button-typology:disabled': {
          color: 'gray!important',
        },
        '&.login-button': {
          border: '2px solid #f5942e!important',
        },
        '&.appartment-button': {
          border: 'unset',
          marginRight: '15px',
          marginBottom: '15px',
          width: '145px',
          height: '34px',
          fontFamily: variables.LABEL_FONT,
          fontSize: 13,
          fontWeight: 600,
        },
        '&:hover': {
          opacity: 0.5,
        },
      },
    },
    MuiDrawer: {
      root: {
        '&.planDetailsDrawer': {
          width: '400px',
          '&>*': { maxHeight: 'calc(100% - 80px)', overflowX: 'hidden' },
        },
      },
      paper: {
        borderRight: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '3',
      },
      paperAnchorDockedLeft: {
        borderRight: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '3',
      },
      paperAnchorRight: {
        borderLeft: 'none!important',
        backgroundColor: '#F8F6F4',
        zIndex: '4',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 'none',
        paddingBottom: '25px',
      },
    },
    MuiListItemText: {
      root: {
        textAlign: 'center',
        paddingLeft: '70px',
      },
      primary: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#1C1C1C',
      },
    },
    MuiListItem: {
      gutters: {
        paddingRight: '50px!important',
      },
      root: {
        height: 60,
        width: '100%!important',
        marginTop: -1,
        position: 'relative',
        borderBottom: '1px solid #CACBD3',
        padding: '0px !important',
        '&:hover': {
          backgroundColor: '#CACBD3!important',
        },
        '&$selected': {
          borderBottom: '1px solid #f5f5fc',
          borderTop: '1px solid #f5f5fc',
          zIndex: '2',
          backgroundColor: colors.BACKGROUND_PAGE,
          '&:hover': {
            borderBottom: '1px solid #CACBD3',
            borderTop: '1px solid #CACBD3',
          },
          '& div.navbar-image-size': {
            borderLeft: '2px solid #6BC1B4 !important',
            paddingLeft: '6px!important',
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        width: '100px',
        borderRadius: '0px',
      },
      list: {
        padding: '0px!important',
        width: '100%!important',
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: '5px!important',
        width: '100%',
        height: '24px',
        backgroundColor: '#E8E9EB',
        fontSize: '12px',
        border: '0px',
        '&$selected': {
          backgroundColor: '#1E201F',
          color: 'white',
          border: '0px',
          width: '100%',
        },
        '&:hover': {
          backgroundColor: '#CACBD3!important',
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        backgroundColor: '#E8E9EB',
        border: 'none',
        borderRadius: '0px',
      },
    },
    MuiToggleButton: {
      root: {
        width: '20px!important',
        border: 'none',
        marginRight: '5px',
        color: '#1E201F',
        fontSize: '18px',
        height: '18px',
        borderRadius: '0px',
        fontWeight: '600',
        '&$selected': {
          backgroundColor: '#E8E9EB',
          textDecoration: 'underline',
          color: '#1E201F',
          '&:hover': {
            backgroundColor: '#E8E9EB',
            textDecoration: 'underline',
          },
        },
        '&:hover': {
          backgroundColor: '#E8E9EB',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottomWidth: '0px!important',
        borderTopWidth: '0px!important',
        fontSize: '14px',
      },
      body: {
        fontWeight: '600',
      },
      head: {
        textTransform: 'uppercase',
        fontWeight: '700',
        backgroundColor: '#E8E9EB!important',
        fontSize: '16px',
        whiteSpace: 'nowrap',
      },
      footer: {
        width: 'fit-content!important',
      },
    },
    MuiTableRow: {
      footer: {
        backgroundColor: 'rgba(232, 233, 235, 0.38)',
      },
    },
    MuiTablePagination: {
      toolbar: {
        display: 'flex',
        flexFlow: 'row-reverse',
        backgroundColor: 'transparent!important',
        flexWrap: 'wrap',
        paddingTop: '10px!important',
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: 'rgba(232, 233, 235, 0.38)',
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        flexWrap: 'wrap',
      },
      gutters: {
        paddingLeft: '0px!important',
        paddingBottom: 10,
        paddingRight: '0px!important',
        flexWrap: 'wrap',
      },
    },
    MuiSelect: {
      root: {
        border: '1px solid #E8E9EB!important',
        padding: '8px 25px 8px 5px!important',
        color: '#1E201F',
      },
    },
    MuiTypography: {
      caption: {
        color: '#000000',
        fontSize: 15,
      },
      h3: {
        fontSize: 45,
        fontWeight: 'bold',
        color: '#FFFFFF',
      },
      h4: {
        fontSize: 30,
      },
      h5: {
        fontSize: 25,
      },
      h2: {
        fontSize: 17,
        color: '#FFFFFF',
        paddingBlock: 10,
      },
      subtitle1: {
        fontFamily: variables.SECONDARY_FONT,
        fontSize: 18,
        color: '#705466',
      },
      subtitle2: {
        fontSize: 12,
        color: '#705466',
      },
      body1: {
        fontSize: 10,
      },
      body2: {
        fontWeight: 500,
      },
      colorTextSecondary: {
        color: 'none',
      },
    },
    MuiIconButton: {
      root: {
        color: 'black',
        borderRadius: 'unset',
        height: 100,
        '&.carousel-button': {
          padding: '0px!important',
        },
        '&.close-icon': {
          height: 40,
        },
        '&:hover': {
          color: 'black',
          backgroundColor: 'none',
        },
        '&:focus': {
          '&.info': {
            background: 'white!important',
          },
        },
      },
      label: {
        display: 'unset',
        flexDirection: 'column',
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
      },
    },
    MuiInput: {
      input: {
        fontSize: '18px',
        color: '#000000',
        marginLeft: '10px',
      },
      underline: {
        '&:before': {
          borderBottom: '2px solid #CACBD3',
        },
        '&:after': {
          borderBottom: '2px solid #4c8c40',
        },
        '&&&&:hover:before': {
          borderBottom: '2px solid #000000',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: 'white!important',
      },
      input: {
        fontSize: '18px',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#f5942e!important',
        '&$checked': {
          color: '#f5942e!important',
        },
        '&.signin-checkbox:hover .MuiSvgIcon-root': {
          fill: 'antiquewhite',
          border: '3px solid #F0A960',
          backgroundColor: 'antiquewhite',
        },
        '&.signin-checkbox .MuiSvgIcon-root': {
          height: 15,
          width: 15,
          fill: '#FBDAB9',
          border: '3px solid #F5942E',
          backgroundColor: '#FBDAB9',
        },
        '&.Mui-checked.signin-checkbox .MuiSvgIcon-root': {
          fill: '#FFFFFF',
          width: 21,
          height: 21,
          background: '#F5942E',
          border: 'unset',
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '10px 14px',
        marginLeft: 12,
      },
      root: {
        borderRadius: 'unset',
      },
      notchedOutline: {
        borderColor: 'F5F5F5',
      },
      adornedStart: {
        paddingLeft: 24,
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'flex-start',
      },
    },
    MuiTableSortLabel: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      icon: {
        height: '25px',
        width: '25px',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'F5F5F5',
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: '2px',
        fontSize: '14px',
      },
    },
  },
  palette: {
    primary: {
      main: colors.COLOR_PRIMARY,
    },
    secondary: {
      main: colors.COLOR_SECONDARY,
    },
  },
};
