import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactSVG from 'components/common/SvgLoader.jsx';
import ScrollArea from 'react-scrollbar';
import { Alert } from '@material-ui/lab';
import { useStore } from 'store/storeUtils';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 'max-content',
  },
  button: {
    display: 'flex',
    marginTop: 15,
  },
  section1: {
    paddingBottom: 88,
  },
  section3: {
    marginBottom: 10,
  },
}));

const BoxSelect = observer(props => {
  const {
    matchesPhone,
    soldBoxes,
    boxes,
    boxDisponibili,
    selectedBox,
    handleClick,
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(soldBoxes > 0);

  const handleClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const classes = useStyles();
  const store = useStore();

  const svgPostoAuto = `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/svg/posto_auto.svg`;

  return (
    <Grid item xs={12} className="plan-grid-select container-with-drawer">
      {!matchesPhone ? (
        <>
          <div className="prospect-select">
            <div className={classes.root}>
              <div className={classes.section1}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="h4">
                      <b>3</b>&nbsp; <thin style={{ fontWeight: 300 }}>|</thin>
                      &thinsp; Aggiungi un <b>posto auto</b>
                    </Typography>
                    <Typography style={{ marginTop: 12 }} variant="subtitle1">
                      <b>Il posto auto non è incluso.</b> Scegli un posto auto
                      se vuoi aggiungerlo alla tua configurazione
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.section2}>
                <Typography
                  style={{
                    marginBottom: 10,
                  }}
                  variant="subtitle2"
                >
                  POSTI AUTO DISPONIBILI ALLOCATI AL PIANO -1
                </Typography>
                <Divider style={{ marginRight: 45 }} />
                <ScrollArea className="scroll-orizzontale-posti-auto">
                  <div className={classes.button}>
                    {boxDisponibili.map((item, index) => (
                      <>
                        <Button
                          style={{
                            minWidth: 'fit-content',
                          }}
                          className={
                            'appartment-button' +
                            (selectedBox && selectedBox.id === item.id
                              ? ' selected-appartment'
                              : '')
                          }
                          onClick={() => handleClick(item)}
                          variant="outlined"
                          key={index}
                        >
                          <b>
                            {item.id === '0' ? (
                              'NO POSTO AUTO'
                            ) : (
                              <> {item._label} </>
                            )}
                          </b>
                          {item.id !== '0' && (
                            <>
                              &emsp;
                              <Divider
                                orientation="vertical"
                                className={
                                  'divider-button' +
                                  (selectedBox && selectedBox.id === item.id
                                    ? ' selected-appartment'
                                    : '')
                                }
                                flexItem
                              />
                              &emsp;
                              <light style={{ fontWeight: 300 }}>
                                PIANO -1
                              </light>
                            </>
                          )}
                        </Button>
                      </>
                    ))}
                  </div>
                </ScrollArea>
              </div>
              <div className={classes.section3}>
                <Typography style={{ fontSize: 13 }} variant="subtitle2">
                  <img src="/icon/icon-clicca.svg" />{' '}
                  <b id="pulsa">Clicca sul posto auto</b> che preferisci e
                  visualizzalo sulla planimetria.
                </Typography>
              </div>
            </div>
          </div>
          <Snackbar
            className="svg-alert"
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              severity="error"
              variant="filled"
              iconMapping={{
                error: <img src="/icon/Icon-metro-warning.svg" />,
              }}
              style={{
                height: 50,
                width: 480,
                flex: 'unset',
                alignSelf: 'center',
                fontSize: 'small',
              }}
              action={
                <IconButton onClick={handleClose}>
                  <img src="/icon/icon-chiudi-warming.svg" />
                </IconButton>
              }
            >
              {soldBoxes > 1 ? (
                <>
                  Sono già stati venduti {soldBoxes} posti auto su{' '}
                  {boxes.length - 1} disponibili. <br />
                  In grigio vedi quelli venduti{' '}
                </>
              ) : (
                <>
                  &Egrave; già stato venduto un posto auto su {boxes.length - 1}{' '}
                  disponibili. <br />
                  In grigio vedi quello venduto{' '}
                </>
              )}
            </Alert>
          </Snackbar>
          <ReactSVG
            className="box-svg-container"
            src={svgPostoAuto}
            renumerateIRIElements={false}
            beforeInjection={actualSvg => {
              if (actualSvg) {
                boxes.forEach(box => {
                  if (box.available) {
                    let seleziona = actualSvg.querySelector(
                      `g[id="_Seleziona-${box._label.replaceAll(' ', '')}_" i]`
                    );
                    if (selectedBox && selectedBox.id === box.id) {
                      seleziona = actualSvg.querySelector(
                        `g[id="_Selezionato-${box._label.replaceAll(
                          ' ',
                          ''
                        )}_" i]`
                      );
                    }
                    if (seleziona) {
                      seleziona.style.display = 'block';
                      seleziona.style.cursor = 'pointer';
                      seleziona.addEventListener('click', () =>
                        handleClick(box)
                      );
                    }
                  } else {
                    const venduto = actualSvg.querySelector(
                      `g[id="_Venduto-${box._label.replaceAll(' ', '')}_" i]`
                    );
                    if (venduto) {
                      venduto.style.display = 'block';
                    }
                  }
                });
                actualSvg.setAttribute('preserveAspectRatio', 'xMidYMid slice');
              }
            }}
            wrapper="div"
          />
        </>
      ) : (
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              paddingLeft: 18,
              paddingTop: '5%',
              backgroundColor: 'white',
              position: 'relative',
              zIndex: '1',
            }}
          >
            <Typography style={{ color: '#481635' }} variant="caption">
              <b>3</b> | Aggiungi un <b>posto auto</b>
            </Typography>
            <Typography
              style={{ color: '#705466', fontSize: 10 }}
              variant="subtitle1"
            >
              <b>Il posto auto non è incluso.</b> Scegli un posto auto se vuoi
              aggiungerlo alla tua configurazione
            </Typography>
            <ScrollArea className="scroll-orizzontale-posti-auto">
              <div className={classes.button}>
                {boxDisponibili.map((item, index) => (
                  <>
                    <Button
                      style={{
                        minWidth: 120,
                        height: 25,
                        marginBlock: 30,
                        fontSize: 10,
                      }}
                      className={
                        'appartment-button' +
                        (selectedBox && selectedBox.id === item.id
                          ? ' selected-appartment'
                          : '')
                      }
                      onClick={() => handleClick(item)}
                      variant="outlined"
                      key={index}
                    >
                      {item.id === '0' ? 'NO POSTO AUTO' : <> {item._label} </>}

                      {item.id !== '0' && (
                        <>
                          &emsp;
                          <Divider
                            orientation="vertical"
                            className={
                              'divider-button' +
                              (selectedBox && selectedBox.id === item.id
                                ? ' selected-appartment'
                                : '')
                            }
                            flexItem
                          />
                          &emsp;
                          <light style={{ fontWeight: 300 }}>PIANO -1</light>
                        </>
                      )}
                    </Button>
                  </>
                ))}
              </div>
            </ScrollArea>
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              severity="error"
              variant="filled"
              iconMapping={{
                error: <img src="/icon/Icon-metro-warning.svg" />,
              }}
              style={{
                height: 50,
                width: 480,
                flex: 'unset',
                alignSelf: 'center',
                fontSize: 8,
              }}
              action={
                <IconButton onClick={handleClose}>
                  <img src="/icon/icon-chiudi-warming.svg" />
                </IconButton>
              }
            >
              {soldBoxes > 1 ? (
                <>
                  Sono già stati venduti {soldBoxes} posti auto su{' '}
                  {boxes.length - 1} disponibili. <br />
                  In grigio vedi quelli venduti{' '}
                </>
              ) : (
                <>
                  &Egrave; già stato venduto un posto auto su {boxes.length - 1}{' '}
                  disponibili. <br />
                  In grigio vedi quello venduto{' '}
                </>
              )}
            </Alert>
          </Snackbar>
          <ReactSVG
            className="mobile-box-svg-container"
            src={svgPostoAuto}
            renumerateIRIElements={false}
            beforeInjection={actualSvg => {
              if (actualSvg) {
                boxes.forEach(box => {
                  if (box.available) {
                    let seleziona = actualSvg.querySelector(
                      `g[id="_Seleziona-${box._label.replaceAll(' ', '')}_" i]`
                    );
                    if (selectedBox && selectedBox.id === box.id) {
                      seleziona = actualSvg.querySelector(
                        `g[id="_Selezionato-${box._label.replaceAll(
                          ' ',
                          ''
                        )}_" i]`
                      );
                    }
                    if (seleziona) {
                      seleziona.style.display = 'block';
                      seleziona.style.cursor = 'pointer';
                      seleziona.addEventListener('click', () =>
                        handleClick(box)
                      );
                    }
                  } else {
                    const venduto = actualSvg.querySelector(
                      `g[id="_Venduto-${box._label.replaceAll(' ', '')}_" i]`
                    );
                    if (venduto) {
                      venduto.style.display = 'block';
                    }
                  }
                });
                actualSvg.setAttribute('preserveAspectRatio', 'xMidYMid slice');
              }
            }}
            wrapper="div"
          />
        </div>
      )}
    </Grid>
  );
});

export default BoxSelect;
