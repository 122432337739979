import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/common/Header';
import TuningSuspense from 'components/common/TuningSuspense';
import Navbar from 'components/common/TuningNavbar';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import useHotjar from 'react-use-hotjar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

/**
 * Template generico della pagina
 * Contiene un Header, il contenuto della pagina circondato da un suspense
 * @component
 * @see Header
 * @see TuningSuspense
 */
const PageTemplate = observer(props => {
  const {
    BodyComponent,
    title,
    goBack,
    setLanguage,
    navigation,
    location,
    icons,
    step,
    ...rest
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const store = useStore();
  const { openDrawer } = store;

  const { stateChange, readyState } = useHotjar();
  const myCustomLogger = console.info;
  React.useEffect(() => {
    if (readyState) stateChange(window.location.pathname, myCustomLogger);
  }, [readyState]);

  return (
    <div>
      <Header
        title={title}
        goBack={goBack}
        setLanguage={setLanguage}
        navigation={navigation}
        step={step}
        location={location}
      />
      {/*<Navbar setLanguage={setLanguage} />*/}

      <div className={'body-wrapper'}>
        {/*<div className='page-title'>{title}</div>*/}
        <TuningSuspense>
          <BodyComponent
            title={title}
            navigation={navigation}
            icons={icons}
            location={location}
            {...rest}
          />
        </TuningSuspense>
      </div>
    </div>
  );
});

PageTemplate.propTypes = {
  /** BodyComponent: required content */
  BodyComponent: PropTypes.elementType.isRequired,
  /** title: string. Header prop */
  title: PropTypes.string,
  /** goBack: boolean. Header prop */
  goBack: PropTypes.bool,

  setLanguage: PropTypes.func,
};

PageTemplate.defaultProps = {
  title: '',
  goBack: false,
};

export default PageTemplate;
