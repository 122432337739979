import {
  percentage,
  percent,
  roundToNextHundred,
  roundToFirstDecimal,
} from './utils';

export const getPaymentPrice = (
  payment,
  payments,
  paymentIndex,
  totalPrice
) => {
  if (payment.name === 'Saldo a Rogito') {
    return payment.price;
  }

  let paymentPrice = 0;

  if (payment.type && payment.type === 'fixedPrice') {
    paymentPrice = roundToNextHundred(payment.price);
  } else if (payment.type && payment.type === 'cumulativePercentage') {
    let paymentPercentage = getPaymentPercentage(
      payment,
      payments,
      paymentIndex,
      totalPrice
    );

    paymentPrice = roundToNextHundred(
      parseFloat(percentage(paymentPercentage, totalPrice))
    );
  } else if (payment.priority && payment.priority === 'price') {
    paymentPrice = roundToNextHundred(payment.price);
  } else if (payment.priority && payment.priority === 'percentage') {
    paymentPrice = roundToNextHundred(
      parseFloat(percentage(payment.percentage, totalPrice))
    );
  } else if (payment.percentage) {
    paymentPrice = roundToNextHundred(
      parseFloat(percentage(payment.percentage, totalPrice))
    );
  } else if (payment.price) {
    paymentPrice = roundToNextHundred(payment.price);
  } else {
    paymentPrice = 0;
  }

  return paymentPrice;
};

export const getPaymentPercentage = (
  payment,
  payments,
  paymentIndex,
  totalPrice
) => {
  if (payment.name === 'Saldo a Rogito') {
    return payment.percentage;
  }

  let paymentPercentage = 0;

  if (payment.type && payment.type === 'fixedPrice') {
    paymentPercentage = roundToFirstDecimal(
      parseFloat(percent(roundToNextHundred(payment.price), totalPrice))
    );
  } else if (payment.type && payment.type === 'cumulativePercentage') {
    let cumulativePercentage = payments
      .filter((_, index) => index < paymentIndex)
      .reduce((acc, payment) => {
        return acc + payment.percentage;
      }, 0);

    if (cumulativePercentage > payment.percentage) {
      paymentPercentage = roundToFirstDecimal(0.0);
    } else {
      paymentPercentage = roundToFirstDecimal(
        payment.percentage - cumulativePercentage
      );
    }
  } else if (payment.priority && payment.priority === 'price') {
    paymentPercentage = roundToFirstDecimal(percent(payment.price, totalPrice));
  } else if (payment.priority && payment.priority === 'percentage') {
    paymentPercentage = roundToFirstDecimal(payment.percentage);
  } else if (payment.percentage) {
    paymentPercentage = roundToFirstDecimal(payment.percentage);
  } else if (payment.price) {
    paymentPercentage = roundToFirstDecimal(percent(payment.price, totalPrice));
  } else {
    paymentPercentage = 0;
  }

  return paymentPercentage;
};

export const getSaldoARogito = (quote, totalPrice) => {
  return {
    name: 'Saldo a Rogito',
    price:
      totalPrice -
      quote.payments
        .filter(obj => obj.name !== 'Saldo a Rogito')
        .map((payment, index) =>
          parseFloat(
            getPaymentPrice(payment, quote.payments, index, totalPrice)
          )
        )
        .reduce((pv, cv) => pv + cv, 0),
    percentage:
      100 -
      quote.payments
        .filter(obj => obj.name !== 'Saldo a Rogito')
        .map((payment, index) =>
          parseFloat(
            getPaymentPercentage(payment, quote.payments, index, totalPrice)
          )
        )
        .reduce((pv, cv) => pv + cv, 0),
    endDate: null,
    daysForPayment: null,
  };
};

export const getPromoPrice = (price, promos) => {
  return promos && promos.length
    ? parseFloat(
        price -
          roundToNextHundred(
            percentage(
              promos
                .filter(
                  item =>
                    item.percentage !== null &&
                    item.price === null &&
                    item.class !== 'included_fixedPrice'
                )
                .map(item => item.percentage)
                .reduce((prev, next) => prev + next, 0),
              price
            )
          ) -
          promos
            .filter(
              item =>
                item.price !== null && item.class !== 'included_fixedPrice'
            )
            .map(item => item.price)
            .reduce((prev, next) => parseInt(prev) + parseInt(next), 0)
      )
    : price;
};

export default {
  getPaymentPrice,
  getPaymentPercentage,
  getSaldoARogito,
};
