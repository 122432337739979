import React from 'react';
import { useMutation } from '@apollo/client';
import Client from 'client/Client';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Loader from 'components/common/Loader';
import 'styles/login.scss';
import ReactSVG from 'components/common/SvgLoader.jsx';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Avatar,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CookiesUtils from 'utils/cookieUtils';
import { useHistory } from 'react-router';
import CalculateUrl from 'utils/CalculateUrl';
import MailIcon from '@material-ui/icons/Mail';
import ValidateEmail from 'utils/ValidateEmail';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const WaitForMail = () => {
  return (
    <>
      <div className="linea-tratteggiata-container">
        <img className="linea-tratteggiata" src="/icon/Tracciato 38219.svg" />
      </div>
      <Avatar className="cerchio-bianco">
        <img className="icona-lettera" src="/icon/Raggruppa 20651.svg" />
      </Avatar>
      <Card className="wait-for-mail-container">
        <Typography className="title-send-email" variant="h4">
          Ti abbiamo inviato un email.
        </Typography>
        <Typography className="subtitle-send-email" variant="subtitle1">
          <b>Segui la procedura</b> indicata nel testo dell&apos;email <br />
          <b> per attivare la tua area riservata</b> e procedere <br /> con il
          salvataggio della configurazione.
        </Typography>
        {/* AGGIUNGERE LINK */}
        {/* <Typography style={{ color: "#481635" }} variant="subtitle2">
          <img src="/icon/Icon simple-minutemailer.svg" alt="" />
          <a style={{ textDecoration: "none" }} href="">
            <b>APRI EMAIL</b>
          </a>
        </Typography> */}
      </Card>
    </>
  );
};

const sendEmail = (
  <Grid
    className="send-email-container"
    style={{
      backgroundColor: '#f8f6f4',
      flexWrap: 'nowrap',
      overflow: 'hidden',
    }}
    container
    justify="space-between"
  >
    <Grid
      item
      style={{ position: 'fixed', left: '0', maxWidth: '50%', height: '100%' }}
    >
      <img className="sx-background" src="/icon/Raggruppa 20657.svg" alt="" />
    </Grid>
    <Grid
      style={{ position: 'fixed', right: '0', maxWidth: '50%', height: '100%' }}
    >
      <img className="dx-background" src="/icon/Raggruppa 20656.svg" alt="" />
    </Grid>
  </Grid>
);

const mobileBackground = store => (
  <img
    className="login-mobile-svg"
    src={`${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/login_background_mobile.png`}
  />
);

const ErrorSnackbar = props => {
  const { open, onClose, message, matchesPhone } = props;

  return (
    <Snackbar
      autoHideDuration={matchesPhone ? 3000 : 5000}
      className="error-snackbar"
      open={open}
      onClose={onClose}
    >
      <Alert severity="error" variant="filled" onClose={onClose}>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: matchesPhone ? 'x-small' : 12,
          }}
        >
          <b>{message}</b>
        </Typography>
      </Alert>
    </Snackbar>
  );
};

const LoginForm = props => {
  const { setLoading, email, projectId, matchesPhone, firstName } = props;
  const [doLogin] = useMutation(Client.LOGIN);
  const [askChangePassword] = useMutation(Client.ASK_CHANGE_PASSWORD);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [retrievePasswordCalled, setRetrievePasswordCalled] =
    React.useState(false);
  const [snackbar, setSnackbar] = React.useState({});
  const { snackbarMsg, snackbarType } = snackbar;

  const loginSubmit = e => {
    e.preventDefault();
    setLoading(true);
    doLogin({
      variables: {
        input: {
          email,
          password,
          project_id: projectId,
        },
      },
    })
      .then(res => {
        const { user } = res.data.loginByProject;
        if (store.getRole(user.role) === 'client') {
          CookiesUtils.setClientId(user.client);
          store.setLoggedUser({ ...user, loading: false, logged: true });
        } else {
          setError("L'utenza non ha un cliente associato");
        }
        setLoading(false);
      })
      .catch(e => {
        if (e.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
          setLoading(false);
          setError(true);
        }
      });
  };

  const store = useStore();

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    setError(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Card elevation={6} className="login-container">
      {!matchesPhone ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/icon/Raggruppa 20832.svg" alt="" />
            <Divider
              orientation="vertical"
              flexItem
              style={{
                margin: '6px 36px',
                width: 2,
                backgroundColor: '#481635',
              }}
            />
            <Typography variant="h4">
              Ciao! <br /> <b>{firstName}</b>
            </Typography>
          </div>
          <Typography style={{ marginTop: 28 }} variant="subtitle1">
            Prosegui inserendo la tua password per accedere alla <br /> tua area
            personale.
          </Typography>
          <form id="login-form" onSubmit={loginSubmit}>
            {/* <label style={{ color: "gray" }}>e-mail</label>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon style={{ color: 'darkgray' }} />
                  </InputAdornment>
                ),
              }}
              id="email-login"
              variant="outlined"
              placeholder="Email"
              type="email"
              className="login-input"
              value={email}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              disabled
            /> */}
            <label>
              <Typography style={{ color: '#707070' }} variant="subtitle2">
                Inserisci la password associata alla tua email
              </Typography>
            </label>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/icon/Icon ionic-md-key.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      style={{ maxHeight: '100%' }}
                      className="visibility-icon-button"
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="password-login"
              variant="outlined"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              className="login-input"
              value={password}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
            <Button
              style={{ marginBottom: 10 }}
              type="submit"
              className={'login-button'}
            >
              <Typography
                style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                variant="subtitle1"
              >
                accedi
              </Typography>
            </Button>
            <div
              variant="inherit"
              onClick={() => {
                if (!retrievePasswordCalled) {
                  setRetrievePasswordCalled(true);
                  askChangePassword(
                    Client.ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS(
                      store.projectId,
                      email
                    )
                  )
                    .then(res => {
                      if (res.data.askChangePassword.error) {
                        switch (res.data.askChangePassword.error) {
                          case 'noClientEmail': {
                            setSnackbar({
                              snackbarType: 'error',
                              snackbarMsg:
                                'Nessuna utenza attivata con questa email',
                            });
                            break;
                          }
                          default: {
                            setSnackbar({
                              snackbarType: 'error',
                              snackbarMsg: 'Errore durante la chiamata',
                            });
                            break;
                          }
                        }
                      } else {
                        setSnackbar({
                          snackbarType: 'success',
                          snackbarMsg: 'Inviata mail per il cambio password',
                        });
                      }
                    })
                    .catch(() => {
                      setSnackbar({
                        snackbarType: 'error',
                        snackbarMsg: 'Errore durante la chiamata',
                      });
                    });
                }
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: !retrievePasswordCalled ? '#F5942E' : 'gray',
                }}
              >
                <span
                  style={{
                    cursor: !retrievePasswordCalled ? 'pointer' : 'not-allowed',
                  }}
                >
                  Hai dimenticato la password?
                </span>
              </Typography>
            </div>
            <Snackbar
              autoHideDuration={matchesPhone ? 3000 : 5000}
              open={snackbarMsg}
              onClose={() => setSnackbar({})}
              style={{ width: 'fit-content' }}
            >
              <Alert
                severity={snackbarType}
                variant="filled"
                className={`${snackbarType}-alert`}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: matchesPhone ? 'x-small' : 12,
                  }}
                >
                  <b>{snackbarMsg}</b>
                </Typography>
              </Alert>
            </Snackbar>
          </form>
        </>
      ) : (
        <>
          <div style={{ padding: '20px' }}>
            <Typography variant="h5" component="h2">
              Ciao <br /> <b>{firstName}!</b>
            </Typography>
            <br />
            <Typography variant="subtitle2" component="h2">
              Prosegui inserendo la tua <br /> password per accedere alla tua{' '}
              <br /> area personale.
            </Typography>
          </div>
          <form
            id="login-form"
            style={{
              padding: '20px',
              width: 'initial',
              marginTop: 'unset',
              paddingTop: 'unset',
            }}
            onSubmit={loginSubmit}
          >
            {/* <label style={{ color: "gray", fontSize: "x-small" }}>e-mail</label>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon fontSize="small" style={{ color: 'darkgray' }} />
                  </InputAdornment>
                ),
              }}
              id="email-login"
              variant="outlined"
              placeholder="e-mail"
              type="email"
              className="login-input"
              value={email}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              disabled
            /> */}
            <label>
              <Typography style={{ color: '#707070' }} variant="body1">
                Inserisci la password associata alla tua email
              </Typography>
            </label>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/icon/Icon ionic-md-key.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      style={{ maxHeight: '100%' }}
                      className="visibility-icon-button"
                    >
                      {showPassword ? (
                        <Visibility fontSize="xs" />
                      ) : (
                        <VisibilityOff fontSize="xs" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="password-login"
              variant="outlined"
              placeholder="password"
              type={showPassword ? 'text' : 'password'}
              className="login-input"
              value={password}
              form="login"
              InputLabelProps={{ shrink: true }}
              required
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
            <Button
              style={{ marginBottom: 10 }}
              type="submit"
              className={'login-button'}
            >
              <Typography
                style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                variant="subtitle1"
              >
                accedi
              </Typography>
            </Button>
            <div
              variant="inherit"
              onClick={() => {
                if (!retrievePasswordCalled) {
                  setRetrievePasswordCalled(true);
                  askChangePassword(
                    Client.ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS(
                      store.projectId,
                      email
                    )
                  )
                    .then(res => {
                      if (res.data.askChangePassword.error) {
                        switch (res.data.askChangePassword.error) {
                          case 'noClientEmail': {
                            setSnackbar({
                              snackbarType: 'error',
                              snackbarMsg:
                                'Nessuna utenza attivata con questa email',
                            });
                            break;
                          }
                          default: {
                            setSnackbar({
                              snackbarType: 'error',
                              snackbarMsg: 'Errore durante la chiamata',
                            });
                            break;
                          }
                        }
                      } else {
                        setSnackbar({
                          snackbarType: 'success',
                          snackbarMsg: 'Inviata mail per il cambio password',
                        });
                      }
                    })
                    .catch(() => {
                      setSnackbar({
                        snackbarType: 'error',
                        snackbarMsg: 'Errore durante la chiamata',
                      });
                    });
                }
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: !retrievePasswordCalled ? '#F5942E' : 'gray',
                }}
              >
                <span
                  style={{
                    cursor: !retrievePasswordCalled ? 'pointer' : 'not-allowed',
                  }}
                >
                  Hai dimenticato la password?
                </span>
              </Typography>
            </div>
            <Snackbar
              autoHideDuration={matchesPhone ? 3000 : 5000}
              open={snackbarMsg}
              onClose={() => setSnackbar({})}
              style={{ width: 'fit-content' }}
            >
              <Alert
                severity={snackbarType}
                variant="filled"
                className={`${snackbarType}-alert`}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: matchesPhone ? 'x-small' : 12,
                  }}
                >
                  <b>{snackbarMsg}</b>
                </Typography>
              </Alert>
            </Snackbar>
          </form>
          {mobileBackground(store)}
        </>
      )}
      <ErrorSnackbar
        matchesPhone={matchesPhone}
        open={error}
        onClose={handleSnackbarClose}
        message={'La password inserita non è corretta'}
      />
    </Card>
  );
};

const RegisterForm = observer(props => {
  const { matchesPhone, projectId, setLoading, setStep } = props;
  const [createClientAndUser] = useMutation(Client.CREATE_CLIENT_AND_USER);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [tel, setTel] = React.useState('');
  const [email, setEmail] = React.useState(props.email);

  const [trattamento, setTrattamento] = React.useState(false);
  const [profilazione, setProfilazione] = React.useState(false);
  const [marketing, setMarketing] = React.useState(false);

  const [snackbarError, setSnackbarError] = React.useState(null);

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    setSnackbarError(null);
  };

  const signinSubmit = e => {
    e.preventDefault();
    setLoading(true);
    createClientAndUser(
      Client.CREATE_CLIENT_AND_USER_DEFAULT_OPTIONS(
        projectId,
        email,
        firstName,
        lastName,
        tel,
        trattamento,
        profilazione,
        marketing
      )
    )
      .then(res => {
        setLoading(false);
        if (res.data.createClientAndUser === 'success') {
          setStep('waitForMail');
        } else {
          setSnackbarError(res.data.createClientAndUser);
        }
      })
      .catch(err => {
        setLoading(false);
        setSnackbarError('Unknown error');
      });
  };

  return (
    <Card elevation={6} className="signin-container">
      {!matchesPhone ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/icon/Raggruppa 20682.svg" alt="" />
            <Divider
              orientation="vertical"
              flexItem
              style={{
                margin: '6px 36px',
                width: 2,
                backgroundColor: '#481635',
              }}
            />
            <Typography variant="h4">
              Registrati <br />
              <b>e attiva la tua area riservata</b>
            </Typography>
          </div>
          <form id="signin-form" onSubmit={signinSubmit}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    Nome*
                  </Typography>
                </label>
                <TextField
                  style={{
                    marginRight: 25,
                    paddingBlock: 6,
                  }}
                  id="firstname-signin"
                  variant="outlined"
                  placeholder="nome"
                  type="text"
                  className="signin-input"
                  value={firstName}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/icon utente.svg" alt="" />{' '}
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                />
              </Grid>
              <Grid style={{ paddingLeft: 25 }} item xs={12} md={6}>
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    Cognome*
                  </Typography>
                </label>
                <TextField
                  style={{ paddingBlock: 6 }}
                  id="lastname-signin"
                  variant="outlined"
                  placeholder="cognome"
                  type="text"
                  className="signin-input"
                  value={lastName}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/icon utente.svg" alt="" />{' '}
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} className="grid-padding-top">
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    E-mail*
                  </Typography>
                </label>
                <TextField
                  style={{
                    marginRight: 25,
                    paddingBlock: 4,
                  }}
                  id="email-signin"
                  variant="outlined"
                  placeholder="e-mail"
                  type="email"
                  className="signin-input"
                  defaultInput
                  // disabled
                  value={email}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon material-email.svg" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                style={{ paddingLeft: 25 }}
                item
                xs={12}
                md={6}
                className="grid-padding-top"
              >
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    Telefono*
                  </Typography>
                </label>
                <TextField
                  style={{ paddingBlock: 4 }}
                  id="tel-signin"
                  variant="outlined"
                  placeholder="telefono"
                  type="tel"
                  className="signin-input"
                  value={tel}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon material-phone-iphone.svg" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setTel(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  style={{ marginTop: 30 }}
                  control={
                    <Checkbox
                      required
                      id="privacy-signin"
                      variant="outlined"
                      type="checkbox"
                      className="signin-checkbox"
                      value={trattamento}
                      form="signin"
                      onChange={e => {
                        setTrattamento(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: 14, color: '#707070' }}
                    >
                      Ho letto e compreso{' '}
                      <a
                        style={{ fontStyle: 'italic', color: '#707070' }}
                        href="https://theunits.it/privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        l&apos;informativa privacy
                      </a>{' '}
                      resa ai sensi degli artt. <br /> 13-14 del Reg. UE
                      2016/679 (GDPR). (*)
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ marginTop: 15 }}
                  control={
                    <Checkbox
                      id="trattamento-signin"
                      variant="outlined"
                      type="checkbox"
                      className="signin-checkbox"
                      value={profilazione}
                      form="signin"
                      onChange={e => {
                        setProfilazione(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: 14, color: '#707070' }}
                    >
                      Acconsento alla registrazione dei miei dati per ricevere
                      un <br /> servizio personalizzato sulle mie reali esigenze
                      d'acquisto <br /> (informativa privacy p.to 3c)
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ marginTop: 15 }}
                  control={
                    <Checkbox
                      id="profilazione-signin"
                      variant="outlined"
                      type="checkbox"
                      className="signin-checkbox"
                      value={marketing}
                      form="signin"
                      onChange={e => {
                        setMarketing(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: 14, color: '#707070' }}
                    >
                      Acconsento a ricevere comunicazioni occasionali per
                      restare <br /> sempre aggiornato sulle promozioni e
                      attività dell&apos;iniziativa e <br /> del Gruppo
                      (informativa privacy p.to 3d)
                    </Typography>
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ marginTop: 30, paddingLeft: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: 14, color: '#707070' }}
                >
                  Nota: TENIAMO ALLA TUA PRIVACY. PER QUESTO MOTIVO TI <br />
                  CONFERMIAMO CHE I TUOI DATI PERSONALI NON VERRANNO PER <br />
                  NESSUN MOTIVO TRASMESSI A TERZE PARTI, salvo nei casi previsti{' '}
                  <br />
                  dall&apos;informativa. Potrai, inoltre, cancellarti dalla
                  nostra anagrafica in <br /> qualsiasi momento, così come
                  meglio specificato nell&apos;informativa privacy.
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              style={{ fontSize: 14, color: '#707070', paddingTop: 10 }}
            >
              (*) Campi obbligatori
            </Typography>
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ width: 450, height: 45 }}
                type="submit"
                className={'login-button'}
              >
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: 12, color: '#FFFFFF', fontWeight: 600 }}
                >
                  registrati
                </Typography>
              </Button>
            </div>
          </form>
        </>
      ) : (
        <>
          <div style={{ paddingBottom: '20px' }}>
            <Typography style={{ fontSize: 'initial' }} variant="h5">
              Registrati <br />
              <b>e attiva la tua area riservata</b>
            </Typography>
          </div>
          <form id="signin-form" onSubmit={signinSubmit}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <label>
                  <Typography style={{ color: '#707070' }} variant="body1">
                    Nome*
                  </Typography>
                </label>
                <TextField
                  id="firstname-signin"
                  variant="outlined"
                  placeholder="nome"
                  type="text"
                  className="signin-input"
                  value={firstName}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/icon utente.svg" alt="" />{' '}
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: '10px' }}>
                <label>
                  <Typography style={{ color: '#707070' }} variant="body1">
                    Cognome*
                  </Typography>
                </label>
                <TextField
                  id="lastname-signin"
                  variant="outlined"
                  placeholder="cognome"
                  type="text"
                  className="signin-input"
                  value={lastName}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/icon utente.svg" alt="" />{' '}
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} className="grid-padding-top">
                <label>
                  <Typography style={{ color: '#707070' }} variant="body1">
                    E-mail*
                  </Typography>
                </label>
                <TextField
                  id="email-signin"
                  variant="outlined"
                  placeholder="e-mail"
                  type="email"
                  className="signin-input"
                  defaultInput
                  //disabled
                  value={email}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon material-email.svg" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} className="grid-padding-top">
                <label>
                  <Typography style={{ color: '#707070' }} variant="body1">
                    Telefono*
                  </Typography>
                </label>
                <TextField
                  id="tel-signin"
                  variant="outlined"
                  placeholder="telefono"
                  type="tel"
                  className="signin-input"
                  value={tel}
                  form="signin"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon material-phone-iphone.svg" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => {
                    setTel(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ marginTop: 30 }}
                  control={
                    <Checkbox
                      required
                      id="privacy-signin"
                      variant="outlined"
                      type="checkbox"
                      className="signin-checkbox"
                      value={trattamento}
                      form="signin"
                      onChange={e => {
                        setTrattamento(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: 10, color: '#707070' }}
                    >
                      Ho letto e compreso{' '}
                      <a
                        style={{
                          fontStyle: 'italic',
                          color: '#707070',
                          textAlign: 'justify',
                        }}
                        href="https://theunits.it/privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        l&apos;informativa privacy
                      </a>{' '}
                      resa ai sensi degli artt. 13-14 del Reg. UE 2016/679
                      (GDPR). (*)
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ marginTop: 15 }}
                  control={
                    <Checkbox
                      id="trattamento-signin"
                      variant="outlined"
                      type="checkbox"
                      className="signin-checkbox"
                      value={profilazione}
                      form="signin"
                      onChange={e => {
                        setProfilazione(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: 10,
                        color: '#707070',
                        textAlign: 'justify',
                      }}
                    >
                      Acconsento alla registrazione dei miei dati per ricevere
                      un servizio personalizzato sulle mie reali esigenze
                      d'acquisto (informativa privacy p.to 3c)
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ marginTop: 15 }}
                  control={
                    <Checkbox
                      id="profilazione-signin"
                      variant="outlined"
                      type="checkbox"
                      className="signin-checkbox"
                      value={marketing}
                      form="signin"
                      onChange={e => {
                        setMarketing(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: 10,
                        color: '#707070',
                        textAlign: 'justify',
                      }}
                    >
                      Acconsento a ricevere comunicazioni occasionali per
                      restare sempre aggiornato sulle promozioni e attività
                      dell&apos;iniziativa e del Gruppo (informativa privacy
                      p.to 3d)
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  style={{
                    marginTop: 15,
                    fontSize: 9,
                    color: '#707070',
                    textAlign: 'justify',
                  }}
                >
                  Nota: TENIAMO ALLA TUA PRIVACY. PER QUESTO MOTIVO TI
                  CONFERMIAMO CHE I TUOI DATI PERSONALI NON VERRANNO PER NESSUN
                  MOTIVO TRASMESSI A TERZE PARTI, salvo nei casi previsti
                  dall&apos;informativa. Potrai, inoltre, cancellarti dalla
                  nostra anagrafica in qualsiasi momento, così come meglio
                  specificato nell&apos;informativa privacy.
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              style={{ fontSize: 10, color: '#707070', paddingTop: 10 }}
            >
              (*) Campi obbligatori
            </Typography>
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ width: '100%', height: '40px' }}
                type="submit"
                className={'login-button'}
              >
                registrati
              </Button>
            </div>
          </form>
        </>
      )}
      <ErrorSnackbar
        matchesPhone={matchesPhone}
        open={!!snackbarError}
        onClose={handleSnackbarClose}
        message={snackbarError}
      />
    </Card>
  );
});

const LoginContainer = observer(props => {
  const { step, setStep, matchesPhone } = props;
  const store = useStore();
  const [firstName, setFirstName] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [checkClientInfo] = useMutation(Client.CHECK_CLIENT_INFO);

  const [email, setEmail] = React.useState('');
  const [snackbarError, setSnackbarError] = React.useState(null);

  const history = useHistory();

  if (store.getRole() === 'client') {
    if (store.summaryLocation) {
      history.push(store.summaryLocation);
      store.setSummaryLocation(undefined);
      store.setAutoConfirm(true);
    } else {
      window.location.replace(
        window.location.protocol + '//' + CalculateUrl('salesonline', 'myhome')
      );
    }
    return <Loader z loading />;
  }

  if (store.step || store.step === 0) {
    store.setStep(null);
  }

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    setSnackbarError(null);
  };

  const loginSubmit = e => {
    e.preventDefault();
    if (!ValidateEmail(email)) {
      setSnackbarError("L'email inserita non è valida");
      return;
    }
    setLoading(true);
    checkClientInfo(
      Client.CHECK_CLIENT_INFO_DEFAULT_OPTIONS(store.projectId, email)
    ).then(res => {
      let data = res.data.checkClientInfo;
      switch (data.status) {
        case 'userAlreadyRegistered': {
          setFirstName(data.firstName);
          setStep('userAlreadyRegistered');
          break;
        }
        case 'createClientAndUser': {
          setStep('createClientAndUser');
          break;
        }
        case 'waitForMail':
        case 'createdUser': {
          setStep('waitForMail');
          break;
        }
        case 'hasUserButNoClient': {
          setSnackbarError("L'utenza non ha un cliente associato");
          break;
        }
        default: {
          setSnackbarError('Errore non previsto, contattare il servizio');
          break;
        }
      }
      setLoading(false);
    });
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Loader z loading={loading} />
      {(step === 'waitForMail' || step === 'createdUser') && <WaitForMail />}
      {step === 'createClientAndUser' && (
        <RegisterForm
          email={email}
          matchesPhone={matchesPhone}
          projectId={store.projectId}
          setLoading={setLoading}
          setStep={setStep}
        />
      )}
      {step === 'userAlreadyRegistered' && (
        <LoginForm
          matchesPhone={matchesPhone}
          email={email}
          projectId={store.projectId}
          setLoading={setLoading}
          setStep={setStep}
          firstName={firstName}
        />
      )}
      {step === '' && (
        <Card elevation={6} className="login-container">
          {!matchesPhone ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src="/icon/Raggruppa 20831.svg" alt="" />
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    margin: '6px 36px',
                    width: 2,
                    backgroundColor: '#481635',
                  }}
                />
                {store.summaryLocation ? (
                  <Typography variant="h4">
                    <b>Salva</b> questa <b>configurazione</b>
                  </Typography>
                ) : (
                  <Typography variant="h4">
                    Configura e <b>prenota</b> <br /> la tua casa <b>online.</b>
                  </Typography>
                )}
              </div>
              {store.summaryLocation ? (
                <Typography style={{ marginTop: 28 }} variant="subtitle1">
                  Potrai valutarla successivamente per decidere se prenotare la
                  tua casa online. <br /> Un modo nuovo, facile e sicuro.
                </Typography>
              ) : (
                <Typography style={{ marginTop: 28 }} variant="subtitle1">
                  Un modo nuovo, facile e sicuro per acquistare casa.
                </Typography>
              )}
              <form id="login-form" onSubmit={loginSubmit}>
                <label>
                  <Typography style={{ color: '#707070' }} variant="subtitle2">
                    E-mail*
                  </Typography>
                </label>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icon/Icon material-email.svg" />
                      </InputAdornment>
                    ),
                  }}
                  id="email-login"
                  variant="outlined"
                  placeholder="e-mail"
                  type="email"
                  className="login-input"
                  value={email}
                  form="login"
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={e => {
                    setEmail(e.target.value);
                    setSnackbarError(null);
                  }}
                  error={!!snackbarError}
                />
                <Button type="submit" className={'login-button'}>
                  <Typography
                    style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                    variant="subtitle1"
                  >
                    accedi
                  </Typography>
                </Button>
              </form>
            </>
          ) : (
            <>
              {store.summaryLocation ? (
                <div style={{ padding: 20 }}>
                  <Typography variant="h5">
                    <b>Salva</b> questa <b>configurazione</b>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Potrai valutarla successivamente per decidere se prenotare
                    la tua casa online. <br /> Un modo nuovo, facile e sicuro.
                    <br />
                  </Typography>
                </div>
              ) : (
                <div style={{ padding: 20 }}>
                  <Typography variant="h5">
                    Configura e <b>prenota</b>
                    <br /> la tua casa <b>online.</b>
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Un modo nuovo, facile e sicuro <br /> per acquistare casa.
                    <br />
                  </Typography>
                </div>
              )}
              <form
                id="login-form"
                style={{
                  padding: 20,
                  width: 'initial',
                  marginTop: 'unset',
                  paddingTop: 'unset',
                }}
                onSubmit={loginSubmit}
              >
                <label>
                  <Typography style={{ color: '#707070' }} variant="body1">
                    E-mail*
                  </Typography>
                </label>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon
                          fontSize="small"
                          style={{ color: 'darkgray' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  id="email-login"
                  variant="outlined"
                  placeholder="e-mail"
                  type="email"
                  className="login-input"
                  value={email}
                  form="login"
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={e => {
                    setEmail(e.target.value);
                    setSnackbarError(null);
                  }}
                  error={!!snackbarError}
                />
                <Button type="submit" className={'login-button'}>
                  <Typography
                    style={{ fontSize: 12, fontWeight: 600, color: '#FFFFFF' }}
                    variant="subtitle1"
                  >
                    accedi
                  </Typography>
                </Button>
              </form>
              {mobileBackground(store)}
            </>
          )}
          <ErrorSnackbar
            matchesPhone={matchesPhone}
            open={!!snackbarError}
            onClose={handleSnackbarClose}
            message={snackbarError}
          />
        </Card>
      )}
    </Grid>
  );
});

/**
 * Pagina di login
 * @component
 */
const Login = observer(props => {
  const store = useStore();
  const theme = useTheme();
  const [step, setStep] = React.useState('');
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  return (
    <>
      {/* mostra l'immagine di background quando non è in mobile e non è lo step waitForMail */}
      {!matchesPhone && step !== 'waitForMail' && step !== 'createdUser' && (
        <div
          className="login-background-container"
          style={{
            backgroundImage: `url('${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/login_background.jpg')`,
          }}
        />
      )}

      {/* se lo step è waitForMail */}
      {step === 'waitForMail' && sendEmail}
      <LoginContainer
        step={step}
        setStep={setStep}
        matchesPhone={matchesPhone}
        {...props}
      />
    </>
  );
});

export default Login;
