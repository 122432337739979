import {
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import 'styles/login.scss';
import 'styles/card.scss';
import Card from '../common/Card';
import Button from '../common/Button';
import { useStore } from 'store/storeUtils.js';
import { Alert } from '@material-ui/lab';
import appartmentTypes from '../../constants/AppartmentTypes';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import Loader from 'components/common/Loader';
import { getPromoPrice } from 'utils/utilsPayments';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    placeContent: 'space-between',
    padding: 10,
    marginInline: 16,
    marginBlock: 4,
    minHeight: 115,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  cover: {
    position: 'relative',
    width: 150,
    [theme.breakpoints.up('600')]: {
      height: 365,
      width: 350,
      marginLeft: 25,
      marginRight: 25,
      marginTop: 25,
    },
  },
  coverLogoOverlay: {
    position: 'absolute',
    left: '11.75px',
    top: '11px',
    width: '28px',
    height: '30px',
    [theme.breakpoints.down('600')]: {
      top: '7.58px',
      left: '12.57px',
      width: '19px',
      height: '20px',
    },
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const Typology = observer(() => {
  const store = useStore();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  //logica stepper
  if (store.step !== 0) {
    store.setStep(0);
  }

  const noTFilter = arr => arr.filter(a => !a.name.endsWith('T'));

  //logica per Alert
  const soldAppartments =
    noTFilter(store.allAppartments).length -
    noTFilter(store.availableAppartments).length;

  //pagina successiva -> plan
  const handleClick = tipo => {
    history.push(window.location.pathname + encodeURI(tipo));
    store.setStep(store.step + 1);
  };

  const allAppartmentsByType = type => {
    return store.allAppartments.filter(app => {
      return type(app);
    });
  };

  //per disattivare una card di filtraggio
  const availableAppartmentsByType = type =>
    allAppartmentsByType(type).filter(app => app.available);

  const getStartPrice = (appartments, defaultStartPrice) =>
    appartments.length === 0
      ? defaultStartPrice
      : Math.min.apply(
          null,
          appartments.map(appartment =>
            getPromoPrice(appartment.price, appartment.standardQuote.promos)
          )
        );

  const [snackbarOpen, setSnackbarOpen] = useState(soldAppartments > 0);

  const handleClose = (e, reason) => {
    if (reason === 'clickway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (!store.allAppartments.length) {
    return <Loader z loading />;
  }

  return (
    <>
      <Grid container className="grid-container-full-height">
        <Grid item xs={12} className="typology-grid">
          {!matchesPhone ? (
            <div
              style={{
                paddingTop: '20px',
                paddingLeft: '80px',
              }}
            >
              <Typography variant="h4">
                <b>1</b>&nbsp; <span style={{ fontWeight: '300' }}>|</span>
                &thinsp; Scegli la <b>Tipologia</b>
              </Typography>
              <Typography style={{ marginTop: 12 }} variant="subtitle1">
                <b>Scegli la dimensione</b> dell&apos;appartamento che fa per te
              </Typography>
            </div>
          ) : (
            <div className="title-typology-mobile">
              <Typography style={{ color: '#481635' }} variant="caption">
                <b>1</b> | Scegli la <b>Tipologia</b>
              </Typography>
              <Typography
                style={{ color: '#705466', fontSize: 10 }}
                variant="subtitle1"
              >
                <b>Scegli la dimensione</b> dell&apos;appartamento che fa per te
              </Typography>
            </div>
          )}
        </Grid>
        <Grid container>
          {matchesPhone &&
            appartmentTypes.map((appartmentType, index) => {
              const availableAppartments = availableAppartmentsByType(
                appartmentType.filter
              );
              return (
                <Card
                  key={'appartment-types-' + index}
                  elevation={6}
                  className={classes.root}
                >
                  <div className={classes.details}>
                    <CardContent>
                      <Typography
                        style={{ color: '#1C1C1C', fontSize: 12 }}
                        variant="subtitle1"
                      >
                        <b>{appartmentType.name.toUpperCase()}</b>
                      </Typography>
                      <Typography
                        style={{ color: '#1C1C1C', fontSize: 10 }}
                        variant="subtitle1"
                      >
                        in promo a partire da{' '}
                        {CurrencyFormatter.format(
                          getStartPrice(
                            availableAppartments.length > 0
                              ? availableAppartments
                              : allAppartmentsByType(appartmentType.filter),
                            appartmentType.startPrice
                          )
                        )}
                        <div className="label-affaccio">
                          Affaccio {appartmentType.affaccio}
                        </div>
                      </Typography>
                    </CardContent>
                    <div>
                      <Button
                        className="button-typology"
                        variant="outlined"
                        endIcon={
                          availableAppartments[0] && (
                            <img
                              style={{ width: 13 }}
                              src="/icon/icon-freccia card.svg"
                            />
                          )
                        }
                        disabled={!availableAppartments[0]}
                        onClick={() => handleClick(appartmentType.name)}
                      >
                        <b>
                          {!availableAppartments[0] && 'INDISPONIBILE'}
                          {availableAppartments[0] && 'SELEZIONA'}
                        </b>
                      </Button>
                    </div>
                  </div>
                  <CardMedia
                    className={classes.cover}
                    image={`${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/tipologia/${appartmentType.url}.jpg`}
                  >
                    <img
                      src="/icon/icon-logo-light.svg"
                      alt=""
                      className={classes.coverLogoOverlay}
                    />
                  </CardMedia>
                </Card>
              );
            })}
          {!matchesPhone &&
            appartmentTypes.map((appartmentType, index) => {
              const availableAppartments = availableAppartmentsByType(
                appartmentType.filter
              );
              return (
                <Grid
                  key={'types-grid-' + index}
                  style={{ maxWidth: 'none', paddingInline: '1%' }}
                  item
                  xs={12}
                  md={3}
                  className="typology-grid"
                >
                  <Card
                    style={{
                      height: 550,
                      maxWidth: 400,
                      paddingLeft: 'unset',
                      paddingRight: 'unset',
                    }}
                    elevation={6}
                  >
                    <CardActionArea
                      disabled={!availableAppartments[0]}
                      onClick={() => handleClick(appartmentType.name)}
                    >
                      <CardMedia
                        className={classes.cover}
                        image={`${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/salesonline/img/tipologia/${appartmentType.url}.jpg`}
                        title=""
                      >
                        <img
                          src="/icon/icon-logo-light.svg"
                          alt=""
                          className={classes.coverLogoOverlay}
                        />
                      </CardMedia>
                      <CardContent>
                        <Typography
                          style={{
                            fontSize: 20,
                            marginTop: 'unset',
                            color: '#000000',
                          }}
                          variant="subtitle1"
                        >
                          <b>{appartmentType.name.toUpperCase()}</b>
                        </Typography>
                        <Typography variant="subtitle2">
                          in promo a partire da{' '}
                          {CurrencyFormatter.format(
                            getStartPrice(
                              availableAppartments.length > 0
                                ? availableAppartments
                                : allAppartmentsByType(appartmentType.filter),
                              appartmentType.startPrice
                            )
                          )}
                          <div className="label-affaccio">
                            Affaccio {appartmentType.affaccio}
                          </div>
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        type="button"
                        className="button-typology"
                        variant="outlined"
                        endIcon={
                          availableAppartments[0] && (
                            <img src="/icon/icon-freccia card.svg" />
                          )
                        }
                        disabled={!availableAppartments[0]}
                        onClick={() => handleClick(appartmentType.name)}
                      >
                        <b>
                          {!availableAppartments[0] && 'INDISPONIBILE'}
                          {availableAppartments[0] && 'SELEZIONA'}
                        </b>
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
        {!matchesPhone ? (
          <Snackbar
            autoHideDuration={5000}
            open={snackbarOpen}
            onClose={handleClose}
          >
            <Alert
              iconMapping={{ error: <img src="icon/Icon-metro-warning.svg" /> }}
              severity="error"
              variant="filled"
              action={
                <IconButton onClick={handleClose}>
                  <img src="/icon/icon-chiudi-warming.svg" />
                </IconButton>
              }
            >
              {soldAppartments > 1 ? (
                <>
                  Sono già stati venduti {soldAppartments} appartamenti su{' '}
                  {noTFilter(store.allAppartments).length} disponibili
                </>
              ) : (
                <>
                  &Egrave; già stato venduto un appartamento su{' '}
                  {noTFilter(store.allAppartments).length} disponibili
                </>
              )}
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar
            autoHideDuration={3000}
            open={snackbarOpen}
            onClose={handleClose}
          >
            <Alert
              style={{ fontSize: 8 }}
              severity="error"
              variant="filled"
              onClose={handleClose}
            >
              {soldAppartments > 1 ? (
                <>
                  Sono già stati venduti {soldAppartments} appartamenti su{' '}
                  {noTFilter(store.allAppartments).length} disponibili
                </>
              ) : (
                <>
                  &Egrave; già stato venduto un appartamento su{' '}
                  {noTFilter(store.allAppartments).length} disponibili
                </>
              )}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </>
  );
});

export default Typology;
